import {Auth} from "aws-amplify";
import router from "@/router";

const actions = {

    checkForSession(){
        return new Promise(resolve => {
            Auth.currentSession().then(session => {
                resolve(session);
            }).catch(()=>{})
        }).catch(err =>{
            console.warn(err);
        })
    },
    postRequest(context, payload){
        return new Promise((resolve)=>{
            context.dispatch('checkForSession').then(session => {
                if(!session){
                    if (!window.location.href.includes("login")){
                        alert("Error retrieving security credentials in post request. Please login again.");
                        window.location.href = "/#/login";
                        context.dispatch('signout').then();
                    }
                }else{
                    //The username might have chars like ü,ä,ö that aren't readable for the server.
                    const encodedUsername = encodeURIComponent(context.rootGetters.username);

                    const jwtToken =session.getIdToken().getJwtToken();

                    const requestOptions = {
                        method: "POST",
                        headers: {"Content-Type": "application/json","Authorization":jwtToken, "username": encodedUsername},
                        body: JSON.stringify({ identifier: payload.param, payload:payload.payload})
                    };

                    fetch('https://5fm32wgp92.execute-api.eu-central-1.amazonaws.com/prod', requestOptions)
                        .then(resp => {
                            if(resp.status!==200) {
                                // if we receive a 401 response, it is likely that something with
                                // the token went wrong. So we require a re-login.
                                if (!window.location.href.includes("login") && resp.status.toString() === "401"){
                                    alert("Error retrieving security credentials in post request. Please login again.");
                                    window.location.href = "/#/login";
                                }
                            } else {
                                resolve(resp.json());
                            }
                        }).catch((err) => {
                        console.log(err)
                    });
                }
            })

        });
    },
    getRequest(context,param){
        return new Promise((resolve)=>{
            context.dispatch('checkForSession').then(session => {
                if(!session){
                    alert("Error retrieving security credentials in get request. Please login again.");
                    window.location.href = "/#/login";
                    context.dispatch('signout').then();
                }else{
                    //The username might have chars like ü,ä,ö that aren't readable for the server.
                    const encodedUsername = encodeURIComponent(context.rootGetters.username);

                    const jwtToken =session.getIdToken().getJwtToken();

                    const requestOptions = {
                        method: "GET",
                        headers: {
                            "Authorization": jwtToken,
                            "username": encodedUsername
                        },
                    };

                    fetch('https://5fm32wgp92.execute-api.eu-central-1.amazonaws.com/prod' + "?param=" + param, requestOptions)
                        .then(resp => {
                            if(resp.status!==200) {
                                // if we receive a 401 response, it is likely that something with
                                // the token went wrong. So we require a re-login.
                                if (!window.location.href.includes("/login") && resp.status.toString() === "401"){
                                    alert("Error retrieving security credentials in get request. Please login again.");
                                    window.location.href = "/#/login";
                                    context.dispatch('signout').then();
                                }
                            } else {
                                resolve(resp.json());
                            }
                        }).catch((err) => {
                            if(param === "hasSalesLicense"){
                                Auth.signOut().then();
                                context.dispatch('signout').then();
                                router.push({
                                    path: '/login',
                                    name: 'Login',
                                }).then();
                            }
                        console.log(err);
                        window.location.href = "/#/login";
                        context.dispatch('signout').then();
                    })
                }
            })

        });
    }

}

const requestModule = {
    actions,
}

export default requestModule;