const awsconfig = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:46e1108b-b89c-45e5-bd29-647085046ea4",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_HcmhH1Ht0",
    "aws_user_pools_web_client_id": "22tdt6m6ud7uuntjh8qfg9si0c",
    "Storage": {
        "AWSS3": {
            "bucket": 'storage-' , //REQUIRED -  Amazon S3 bucket name
            "region": 'eu-central-1', //OPTIONAL -  Amazon service region
        }
    },
    cookieStorage: {
        domain: window.location.hostname.includes("localhost") ? window.location.hostname : '.4service.io',
        path: "/",
        expires: 365,
        sameSite: "strict",
        secure: window.location.hostname !== "localhost",
    },
};

export default awsconfig;