<template>
  <MaterialTable :data-key="'ReportId'"
                 :table-id="'companyReports'"
                 :style="{'height': windowHeight}"
                 :data-prop="reports"
                 :columns="columns"
                 :has-paginator="true"
                 :rows-count="10"
                 :has-global-filter="true"
                 :filter-prop="filter"
                 :filter-options="filterOptions"
                 :sortable="true"
                 :has-removable-sort="true"
                 :key="tableKey"
                 :selection-mode="'single'"
                 :resizable-columns="true"
                 @row-selected="goToVisitReport"/>

  <Popup :header="this.$t('report')"
         :visible="openVisitReport"
         styling="width:100%"
         :show-menu-button="true"
         :menu-options="visitReportMenuOptions"
         :position="'top'"
         @close-popup="closePopup">
    <VisitReport ref="visitReport"
                 :report-id="reportId"
                 :project-id="projectId"
                 :existing-report-id="true"
                 :is-read-only="true"
                 :report-type="reportType" />
  </Popup>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import VisitReport from "@/components/reports/visit-report/VisitReport";
import Popup from "@/components/global-components/popup/Popup";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "CompanyMaterialTableReportTab",
  components: {Popup, VisitReport, MaterialTable},
  data(){
    return{
      openVisitReport:false,
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'ProjectTitle', header: this.$t('lead') + '/ ' + this.$t('project') + ' ' + this.$t('title') },
        { field: 'ReportType', header: this.$t('reportType') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Type', header: this.$t('type') },
        { field: 'timestamp', header: this.$t('creationDate') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ProjectTitle: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ReportType: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Creator: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        timestamp: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'ProjectTitle', 'ReportType', 'Creator', 'Type'],
      reportId:'',
      reportType:'',
      tableKey:0,
      projectId:'',
      visitReportMenuOptions:[],
      windowHeight: '',
    }
  },

  props:{
    dataWithCorrespondence:Array,
  },

  computed:{
    reports(){
      return this.setReports();
    },
  },

  methods:{
    /**
     * Sets the reportsArray depending on our params (Visit or Phone reports)
     * @returns {*[]}
     */
    setReports(){
      const src = [];
      if(this.dataWithCorrespondence && this.dataWithCorrespondence.length > 0){
        for(let object of this.dataWithCorrespondence){
          for(let correspondence of object.Correspondence){
            if(correspondence.Type && correspondence.Type === 'Besuchsbericht'){
              let obj = {};

              obj["Title"] = correspondence.Files[0].name.split('.')[0];
              obj["ProjectTitle"] = object["Title"];
              obj["Type"] = this.$t(object["Type"].toLowerCase());
              obj["Creator"] = correspondence["Author"];
              obj["timestamp"] = new Date(Number(correspondence["Unix"]));
              obj["ReportId"] = correspondence.Files[0].path.split('/')[4].split('.')[0];
              obj["ReportType"] = this.$t('visitReport');
              obj["ReportTypeString"] = 'besuchsberichtSales';
              obj["ProjectId"] = object["Id"];

              src.push(obj);
            } else if(correspondence.Type && correspondence.Type === 'Telefonbericht'){
              let obj = {};

              obj["Title"] = correspondence.Files[0].name.split('.')[0];
              obj["ProjectTitle"] = object["Title"];
              obj["Type"] = this.$t(object["Type"].toLowerCase());
              obj["Creator"] = correspondence["Author"];
              obj["timestamp"] = new Date(Number(correspondence["Unix"]));
              obj["ReportId"] = correspondence.Files[0].path.split('/')[4].split('.')[0];
              obj["ReportType"] = this.$t('phoneReport');
              obj["ReportTypeString"] = 'telefonberichtSales';
              obj["ProjectId"] = object["Id"];

              src.push(obj);
            }
          }
        }
      }

      return src;
    },
    /**
     * we set our reportType, report- & projectId,
     * before creating the popup menuOptions and opening the report.
     * @param file
     */
    goToVisitReport(file){
      this.reportId = file.ReportId;
      this.projectId = file.ProjectId;
      this.reportType = file.ReportTypeString;
      this.createVisitReportMenuOptions();
      this.openVisitReport = true;
    },

    /**
     * Creates the menuOptions for the report popup.
     */
    createVisitReportMenuOptions(){
      const optionsMenu = []
      optionsMenu.push(
          { label: this.$t('pdfExport'), icon: 'pi pi-fw pi-check',
            command: () => {this.$refs.visitReport.pdfExport();}
          },
      )

      this.visitReportMenuOptions = optionsMenu;
    },

    /**
     * Resets the report & projectId and closing the popup.
     */
    closePopup(){
      this.openVisitReport = false;
      this.reportId = "";
      this.projectId = "";
    },
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>