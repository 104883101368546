import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import i18n from "@/i18n";
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import VueSweetalert2 from "vue-sweetalert2";
import 'sweetalert2/dist/sweetalert2.min.css';
import {Auth} from "aws-amplify";
import awsconfig from "@/aws-exports";
import Tooltip from 'primevue/tooltip';
import { registerLicense } from '@syncfusion/ej2-base';
import { DocumentEditorPlugin } from '@syncfusion/ej2-vue-documenteditor';

// styling

import 'primeflex/primeflex.css';

import '@sweetalert2/theme-dark';

//core
import "primevue/resources/primevue.min.css";
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

Auth.configure(awsconfig);

registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF5cWWNCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWH9fc3VVQ2VdUEB1WUo=');

createApp(App).directive('tooltip', Tooltip).use(DocumentEditorPlugin).use(VueSweetalert2).use(ToastService).use(ConfirmationService).use(i18n).use(store).use(router).use(PrimeVue).mount('#app')
