<template>
  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('title')"
      :input="title">
      <InputText
        class="w-full"
        v-model="title"
        :class="v$.title.$error ? 'p-invalid' : ''"
        :placeholder="this.$t('placeholder.title')" />
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('probability')"
      :input="probability">
      <Dropdown
        class="w-full"
        v-model="probability"
        :options="probabilityOptions"
        :class="v$.probability.$error ? 'p-invalid' : ''"
        :placeholder="this.$t('placeholder.probability')" />
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('offerDate')"
      :input="offerDate">
      <Calendar
        class="w-full"
        :modelValue="new Date(offerDate)"
        dateFormat="dd.mm.yy"
        showIcon
        hideOnDateTimeSelect
        @update:modelValue="setOfferUnix($event)"/>
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('deliveryDate')"
      :input="deliveryDate">
      <Calendar
        class="w-full"
        :modelValue="new Date(deliveryDate)"
        dateFormat="dd.mm.yy"
        showIcon
        hideOnDateTimeSelect
        @update:modelValue="setDeliveryUnix($event)"/>
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="'Equipment'"
      :input="equipment?.length > 0">
      <MultiSelect
        class="w-full"
        v-model="equipment"
        :options="equipments"
        optionLabel="Name"
        filter
        :placeholder="this.$t('placeholder.equipment')"
        autoFilterFocus/>
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('placeholder.visibleTo')"
      :input="visibleTo">
      <Dropdown
        class="w-full"
        v-model="visibleTo"
        :options="visibleToOptions"
        :class="v$.visibleTo.$error ? 'p-invalid' : ''"
        :placeholder="this.$t('placeholder.visibleTo')"/>
    </CustomLabel>
  </div>

  <div
    v-if="visibleTo === 'Members'">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="$t('members')"
      :input="members">
      <MultiSelect
        class="w-full"
        v-model="members"
        :options="project.Members"
        optionLabel="Name"
        filter
        :placeholder="this.$t('placeholder.selectMembers')"
        autoFilterFocus/>
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden" 
      :label="this.$t('budget')"
      :input="saleValue">
      <InputNumber
        class="w-full"
        v-model="saleValue"
        :placeholder="this.$t('placeholder.budget')" />
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('offerValue')"
      :input="offerValue">
      <InputNumber
        class="w-full"
        v-model="offerValue"
        :placeholder="this.$t('placeholder.offerValue')" />
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('bonusOption')"
      :input="bonusOption">
      <InputNumber
        class="w-full"
        v-model="bonusOption"
        :placeholder="this.$t('placeholder.bonusOption')" />
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('offerNumber')"
      :input="offerNumber">
      <InputText
        class="w-full"
        v-model="offerNumber"
        :placeholder="this.$t('placeholder.offerNumber')" />
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      v-if="offerType === 'Nachtrag'"
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('orderNumber')"
      :input="orderNumber">
      <InputText
        class="w-full"
        v-model="orderNumber"
        :placeholder="this.$t('placeholder.orderNumber')" />
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="$t('supplier')"
      :input="supplier">
      <Dropdown
        class="w-full"
        v-model="supplier"
        :options="supplierOptions"
        optionLabel="Name"
        @update:modelValue="setOfferSupplier"
        :placeholder="$t('placeholder.supplier')" />
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('offerType')"
      :input="offerType">
      <Dropdown
        class="w-full"
        v-model="offerType"
        :options="offerTypeOptions"
        :placeholder="this.$t('placeholder.offerType')"/>
    </CustomLabel>
  </div>

  <div
    class="mb-2 mt-2">
    <FileUpload
      ref="fileUpload"
      name="demo[]"
      :customUpload="true"
      @uploader="uploadFiles"
      :multiple="true"
      accept=".jpg,.jpeg,.png,.eml,.msg,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx"
      :maxFileSize="52000000">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </div>

  <div
    v-if="files.length > 0">
    <AttachmentMaterialTable
      :attachments="files"
      :show-delete-button="true"
      @delete-attachment="deleteFile" />
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import MultiSelect from "primevue/multiselect";
import FileUpload from "primevue/fileupload";
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import AttachmentMaterialTable from "@/components/global-components/attachment-material-table/AttachmentMaterialTable";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import MSGReader from '@kenjiuno/msgreader';
import sanitizeFileName from "../../../../../mixins/sanitize-file-name/sanitize-file-name";

export default {
  name: "AddOffer",
  components:{CustomLabel, AttachmentMaterialTable, InputText, Dropdown, Calendar, InputNumber, MultiSelect, FileUpload},
  mixins:[uuidv4Generator,S3FileManager, sanitizeFileName],
  emits: ['closePopup'],
  props:{
    project:Object,
  },
  data(){
    return {
      files:[],
      bonusOption:null,
      company:this.project.Company,
      deliveryDate:new Date().getTime(),
      equipment:null,
      id:this.createUuidv4(),
      members:[],
      offerDate:new Date().getTime(),
      offerNumber:'',
      offerType:'',
      offerValue:null,
      probability:null,
      saleType:"Standard",
      saleValue:null,
      title:"",
      visibleTo:"All",
      emailContent:null,
      orderNumber: '',
      supplier: null,
      supplierOptions: [],
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      company:{required},
      deliveryDate:{required},
      offerDate:{required},
      probability:{required},
      title:{required},
      visibleTo:{required},
    }
  },

  computed:{
    equipments(){
      const equipments = this.$store.getters.equipments;
      const changedEquipmentData = [];
      for(let equipment of equipments){
        if(equipment.CompanyId === this.project.Company.Id){
          changedEquipmentData.push({Name:equipment.EquipmentName,Id:equipment.Id});
        }
      }
      return changedEquipmentData;
    },

    currentUserData(){
      let currentUser = {};
      const responsibles = this.$store.getters.responsibles;
      for(let responsible of responsibles){
        if(responsible.User === this.$store.getters.username){
          currentUser = responsible;
          break;
        }
      }

      return currentUser
    },

    visibleToOptions(){
      return ['Self','Members','All'];
    },

    probabilityOptions(){
      return ['25','50','75','100'];
    },

    offerTypeOptions(){
      return ['Hauptauftrag', 'Nachtrag', 'Werkzeug', 'Service'];
    }
  },

  methods:{
    setDeliveryUnix(event){
      this.deliveryDate = event.getTime();
    },

    setOfferUnix(event){
      this.offerDate = event.getTime();
    },

    setOfferSupplier(event) {
      this.supplier = event;
    },

    uploadFiles(event){
      for (let file of event.files){
        let fileName = this.getFileName(file.name);
        const fileType = this.getFileType(file.name);
        this.$store.commit('setLoading',true);
        let filePath = 'sales/leadsAndProjects/' + this.project.Id + '/offers/' + this.id + '/';
        // Check if it's an email file
        if (file.type.includes('message')) {
          // Wait for email content extraction asynchronously
          this.getEmailText(file).then(() => {
            fileName = ' ' + `${this.$t('date')}: ${this.emailContent.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
              
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
        }else if (file.name.endsWith('.msg')) {
          this.readMsgFile(file).then((emailData) => {
            fileName = ' ' + `${this.$t('date')}: ${emailData.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
         }else{
          fileName = this.sanitizeFileName(fileName);
          filePath = filePath + fileName.trim();
          this.s3UploadFile(file, filePath).then(() => {
            this.files.push({
              Name:fileName,
              Type:fileType,
              LastModified:new Date(file.lastModified).toLocaleString(),
              Size:(file.size / (1024*1024)).toFixed(2) + " MB",
              "Key": filePath,
            });

            this.$store.commit('setLoading',false);
          }).catch((err) => {
            console.log(err);
            this.$store.commit('setLoading',false);
          });
        }
      }
    },

    getEmailText(file){
      return new Promise((resolve) => {
        const reader = new FileReader();
        
        // Read the file as text or binary, depending on your email file format (.eml or .msg)
        reader.onload = (e) => {
          const emailContent = e.target.result;
          this.emailContent = this.parseEmailContent(emailContent);
          resolve();
        };
        
        // Read as text (suitable for .eml files)
        reader.readAsText(file);
      });
    },

    async readMsgFile(file) {
      return new Promise((resolve) => {
        const fileReader = new FileReader();

        // When the file is loaded, use msg-parser to parse it
        fileReader.onload = async (e) => {
          const arrayBuffer = e.target.result;

          try {
            const msgReader = new MSGReader(arrayBuffer);
            const fileData = msgReader.getFileData();

            const emailData = {
            date: fileData.creationTime.replace(/,/g, "") || 'Unknown',
          };

          resolve(emailData)

          } catch (err) {
            console.error('Error parsing .msg file:', err);
          }
        };

        // Read the file as an ArrayBuffer
        fileReader.readAsArrayBuffer(file);
      });
    },

    getEmailFromSubject(emailString){
      return emailString.match(/<([^>]+)>/)[1];
    },

    parseEmailContent(emailContent) {
      // Parsing .eml content (simple approach for text-based emails)
      const emailLines = emailContent.split("\n");

      let date = "";

      emailLines.forEach(line => {
        if (line.startsWith("Date:")) {
          date = line.replace("Date:", "").replace(/,/g, "").replace(/\s\+.*/, "").trim();
        }
      });

      return {
        date
      }
    },

    deleteFile(file){
      this.$confirm.require({
        header: this.$t('swal.deleteFileHeader'),
        message: this.$t('swal.deleteFileText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteS3Object',file.Key).then(() => {
            this.files = this.files.filter(foundFile => foundFile.Key !== file.Key);
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.fileDeleted'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    checkIfFilesNotUploaded(){
      if(this.$refs.fileUpload.files.length > 0) {
        this.$confirm.require({
          header: this.$t('swal.submitWithoutUploadingHeader'),
          message: this.$t('swal.submitWithoutUploadingText'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.submitEdit();
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
          }
        });
      }else{
        this.submitEdit();
      }
    },

    submitEdit(){
      if(this.visibleTo === "Self") this.members = [
        {
          Id: this.currentUserData.User,
          Name: this.currentUserData.FriendlyName,
          type:"User"
        }
      ];

      if(this.visibleTo === "All") this.members = this.project.Members;

      this.v$.$validate();
      if(!this.v$.$error) {
        const offer = {
          BonusOption:this.bonusOption,
          Closed:'',
          Company:this.company,
          DeliveryDate:this.deliveryDate,
          Domain:this.currentUserData.Company,
          Equipment:this.equipment,
          Id:this.id,
          Members:this.members,
          OfferDate:this.offerDate,
          OfferNumber:this.offerNumber,
          OfferType:this.offerType,
          OfferValue:this.offerValue,
          OrderNumber:this.orderNumber,
          Probability:this.probability,
          ProjectId:this.project.Id,
          SaleType:this.saleType,
          SaleValue:this.saleValue,
          Status:'10',
          Supplier:this.supplier?.Name,
          SupplierId:this.supplier?.Id,
          Title:this.title,
          VisibleTo:this.visibleTo,
        };

        this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.offerCreated'), life: 3000 });

        this.$store.dispatch('addOffer',offer).then(() =>{
          this.$emit('close-popup');
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }

        switch(error.$property){
          case "company":
            errorList = errorList + ' ' + this.$t('company');
            break;
          case "deliveryDate":
            errorList = errorList + ' ' + this.$t('deliveryDate');
            break;
          case "offerDate":
            errorList = errorList + ' ' + this.$t('offerDate')
            break;
          case "probability":
            errorList = errorList + ' ' + this.$t('probability');
            break;
          case "title":
            errorList = errorList + ' ' + this.$t('title');
            break;
          case "visibleTo":
            errorList = errorList + ' ' + this.$t('placeholder.visibleTo');
            break;
        }
      }

      return errorList;
    },

    getSupplierOptions(){
      this.$store.commit('setLoading', true);
      try{
        this.$store.dispatch("getRequest","getSuppliers").then(resp => {
          this.$store.commit('setLoading', false);
          const body = JSON.parse(resp.body);
          this.supplierOptions = body.Items.map((item) => ({Name:item.Name, Id:item.Id}));

          this.supplierOptions.sort((a, b) => {
            if (a['Name'] < b['Name']) return -1;
            if (a['Name'] > b['Name']) return 1;
          });
          
          if(this.project.Supplier && this.project.SupplierId){
            this.supplier = {Name: this.project.Supplier, Id: this.project.SupplierId};
          }
        });
      }catch(err){
        console.log(err);
        this.$store.commit('setLoading', false);
      }
    },
  },
  mounted(){
    if(this.project){
      this.equipment = this.project.Equipment;
    }
    this.getSupplierOptions();
  }
}
</script>

<style scoped>

</style>