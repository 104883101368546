export default{

    computed:{
        tags(){
            return this.$store.getters.customLists;
        }
    },

    methods:{
        createFormFieldInfo(editor, reportData){
            /**
             * Start Company / Customer
             */
            try {
                // Start of meta data
                const customer = editor.getFormFieldInfo('Customer');
                if (customer) {
                    customer.defaultValue = reportData.Customer;
                    editor.setFormFieldInfo('Customer', customer);
                }
            } catch {
                console.log('error')
            }

            try {
                const customerNumber = editor.getFormFieldInfo('CustomerNumber');
                if (customerNumber) {
                    customerNumber.defaultValue = reportData.CustomerNumber;
                    editor.setFormFieldInfo('CustomerNumber', customerNumber);
                }
            } catch {
                console.log('error')
            }

            try {
                if (reportData.CompanyData) {
                    const companyName = editor.getFormFieldInfo('CompanyName');
                    if (companyName) {
                        companyName.defaultValue = reportData.CompanyData.name;
                        editor.setFormFieldInfo('CompanyName', companyName);
                    }

                    const companyNumber = editor.getFormFieldInfo('CompanyNumber');
                    if (companyNumber) {
                        companyNumber.defaultValue = reportData.CompanyData.CompanyNumber;
                        editor.setFormFieldInfo('CompanyNumber', companyNumber);
                    }

                    const companyEmail = editor.getFormFieldInfo('CompanyEmail');
                    if (companyEmail) {
                        companyEmail.defaultValue = reportData.CompanyData.email;
                        editor.setFormFieldInfo('CompanyEmail', companyEmail);
                    }

                    const companyFax = editor.getFormFieldInfo('CompanyFax');
                    if (companyFax) {
                        companyFax.defaultValue = reportData.CompanyData.fax;
                        editor.setFormFieldInfo('CompanyFax', companyFax);
                    }

                    const companyHomepage = editor.getFormFieldInfo('CompanyHomepage');
                    if (companyHomepage) {
                        companyHomepage.defaultValue = reportData.CompanyData.homepage;
                        editor.setFormFieldInfo('CompanyHomepage', companyHomepage);
                    }

                    const companyPhone = editor.getFormFieldInfo('CompanyPhone');
                    if (companyPhone) {
                        companyPhone.defaultValue = reportData.CompanyData.phone;
                        editor.setFormFieldInfo('CompanyPhone', companyPhone);
                    }

                    const addressTwo = editor.getFormFieldInfo('AddressTwo');
                    if (addressTwo) {
                        addressTwo.defaultValue = reportData.CompanyData.addressTwo;
                        editor.setFormFieldInfo('AddressTwo', addressTwo);
                    }
                }
            } catch {
                console.log('error')
            }

            try {
                const location = editor.getFormFieldInfo('Location');
                if (location) {
                    location.defaultValue = reportData.Location;
                    editor.setFormFieldInfo('Location', location);
                }
            } catch {
                console.log('error')
            }
            try {
                const postalCode = editor.getFormFieldInfo('PostalCode');
                if (postalCode) {
                    postalCode.defaultValue = reportData.PostalCode;
                    editor.setFormFieldInfo('PostalCode', postalCode);
                }
            } catch {
                console.log('error')
            }

            try {
                const street = editor.getFormFieldInfo('Street');
                if (street) {
                    street.defaultValue = reportData.Street;
                    editor.setFormFieldInfo('Street', street);
                }
            } catch {
                console.log('error')
            }

            /**
             * Start Contact
             */
            try {
                if(reportData.ContactData){
                    const contactPerson = editor.getFormFieldInfo('ContactPerson');
                    if (contactPerson) {
                        contactPerson.defaultValue = reportData.ContactData.ContactName;
                        editor.setFormFieldInfo('ContactPerson', contactPerson);
                    }

                    const contactLandline = editor.getFormFieldInfo('ContactLandline');
                    if (contactLandline) {
                        contactLandline.defaultValue = reportData.ContactData.ContactLandline;
                        editor.setFormFieldInfo('ContactLandline', contactLandline);
                    }

                    const contactMobile = editor.getFormFieldInfo('ContactMobile');
                    if (contactMobile) {
                        contactMobile.defaultValue = reportData.ContactData.ContactMobile;
                        editor.setFormFieldInfo('ContactMobile', contactMobile);
                    }

                    const contactEmail = editor.getFormFieldInfo('ContactEmail');
                    if (contactEmail) {
                        contactEmail.defaultValue = reportData.ContactData.ContactEmail;
                        editor.setFormFieldInfo('ContactEmail', contactEmail);
                    }

                    const contactDepartment = editor.getFormFieldInfo('ContactDepartment');
                    if (contactDepartment) {
                        contactDepartment.defaultValue = reportData.ContactData.ContactDepartment;
                        editor.setFormFieldInfo('ContactDepartment', contactDepartment);
                    }
                }
            } catch {
                console.log('error')
            }

            /**
             * Start Responsible
             */
            try {
                if (reportData.ResponsibleData) {
                    const responsibleEmail = editor.getFormFieldInfo('ResponsibleEmail');
                    if (responsibleEmail) {
                        responsibleEmail.defaultValue = reportData.ResponsibleData.Email;
                        editor.setFormFieldInfo('ResponsibleEmail', responsibleEmail);
                    }

                    const responsibleFirstName = editor.getFormFieldInfo('ResponsibleFirstName');
                    if (responsibleFirstName) {
                        responsibleFirstName.defaultValue = reportData.ResponsibleData.FirstName;
                        editor.setFormFieldInfo('ResponsibleFirstName', responsibleFirstName);
                    }

                    const responsibleLastName = editor.getFormFieldInfo('ResponsibleLastName');
                    if (responsibleLastName) {
                        responsibleLastName.defaultValue = reportData.ResponsibleData.LastName;
                        editor.setFormFieldInfo('ResponsibleLastName', responsibleLastName);
                    }

                    const responsibleInitials = editor.getFormFieldInfo('ResponsibleInitials');
                    if (responsibleInitials) {
                        responsibleInitials.defaultValue = reportData.ResponsibleData.FirstName.substring(0,1).toUpperCase()
                            + reportData.ResponsibleData.LastName.substring(0,1).toUpperCase();
                        editor.setFormFieldInfo('ResponsibleInitials',responsibleInitials);
                    }

                    const responsibleLandline = editor.getFormFieldInfo('ResponsibleLandline');
                    if (responsibleLandline) {
                        responsibleLandline.defaultValue = reportData.ResponsibleData.Landline;
                        editor.setFormFieldInfo('ResponsibleLandline', responsibleLandline);
                    }

                    const responsibleMobile = editor.getFormFieldInfo('ResponsibleMobile');
                    if (responsibleMobile) {
                        responsibleMobile.defaultValue = reportData.ResponsibleData.Mobile;
                        editor.setFormFieldInfo('ResponsibleMobile', responsibleMobile);
                    }

                    const responsibleStatus = editor.getFormFieldInfo('ResponsibleStatus');
                    if (responsibleStatus) {
                        responsibleStatus.defaultValue = reportData.ResponsibleData.Status;
                        editor.setFormFieldInfo('ResponsibleStatus', responsibleStatus);
                    }
                }
            } catch {
                console.log('error')
            }

            /**
             * Start Lead / Project
             */
            try {
                const projectTitle = editor.getFormFieldInfo('ProjectTitle');
                if (projectTitle) {
                    projectTitle.defaultValue = reportData.ProjectTitle;
                    editor.setFormFieldInfo('ProjectTitle', projectTitle);
                }
            } catch {
                console.log('error')
            }

            try {
                const projectDescription = editor.getFormFieldInfo('ProjectDescription');
                if (projectDescription) {
                    projectDescription.defaultValue = reportData.ProjectDescription;
                    editor.setFormFieldInfo('ProjectDescription', projectDescription);
                }
            } catch {
                console.log('error')
            }

            try {
                const projectNumber = editor.getFormFieldInfo('ProjectNumber');
                if (projectNumber) {
                    projectNumber.defaultValue = reportData.ProjectNumber;
                    editor.setFormFieldInfo('ProjectNumber', projectNumber);
                }
            } catch {
                console.log('error')
            }

            /**
             * Start Equipment
             */
            try {
                const equipmentInventoryNumbers = editor.getFormFieldInfo('EquipmentInventoryNumbers');
                if (equipmentInventoryNumbers) {
                    equipmentInventoryNumbers.defaultValue = reportData.EquipmentInventoryNumbers ?
                        reportData.EquipmentInventoryNumbers.toString() : '';
                    editor.setFormFieldInfo('EquipmentInventoryNumbers', equipmentInventoryNumbers);
                }
            } catch {
                console.log('error')
            }

            try {
                const equipmentNames = editor.getFormFieldInfo('EquipmentNames');
                if (equipmentNames) {
                    equipmentNames.defaultValue = reportData.EquipmentNames ?
                        reportData.EquipmentNames.toString() : '';
                    editor.setFormFieldInfo('EquipmentNames', equipmentNames);
                }
            } catch {
                console.log('error')
            }

            try {
                const equipmentTypes = editor.getFormFieldInfo('EquipmentTypes');
                if (equipmentTypes) {
                    equipmentTypes.defaultValue = reportData.EquipmentTypes ?
                        reportData.EquipmentTypes.toString() : '';
                    editor.setFormFieldInfo('EquipmentTypes', equipmentTypes);
                }
            } catch {
                console.log('error')
            }

            try {
                const machineLocations = editor.getFormFieldInfo('MachineLocations');
                if (machineLocations) {
                    machineLocations.defaultValue = reportData.MachineLocations ?
                        reportData.MachineLocations.toString() : '';
                    editor.setFormFieldInfo('MachineLocations', machineLocations);
                }
            } catch {
                console.log('error')
            }

            /**
             * Start Sales
             */
            try {
                if(reportData.SalesData && Object.keys(reportData.SalesData).length > 0){
                    const salesCommissionNumber = editor.getFormFieldInfo('SalesCommissionNumber');
                    if (salesCommissionNumber) {
                        salesCommissionNumber.defaultValue = reportData.SalesData.CommissionNumber;
                        editor.setFormFieldInfo('SalesCommissionNumber', salesCommissionNumber);
                    }

                    const salesCommissionValue = editor.getFormFieldInfo('SalesCommissionValue');
                    if (salesCommissionValue) {
                        salesCommissionValue.defaultValue = reportData.SalesData.CommissionValue;
                        editor.setFormFieldInfo('SalesCommissionValue', salesCommissionValue);
                    }

                    const salesCommissionEmail = editor.getFormFieldInfo('SalesContactEmail');
                    if (salesCommissionEmail) {
                        salesCommissionEmail.defaultValue = reportData.SalesData.Contact.Email;
                        editor.setFormFieldInfo('SalesContactEmail', salesCommissionEmail);
                    }

                    const salesCommissionLandline = editor.getFormFieldInfo('SalesContactLandline');
                    if (salesCommissionLandline) {
                        salesCommissionLandline.defaultValue = reportData.SalesData.Contact.Landline;
                        editor.setFormFieldInfo('SalesContactLandline', salesCommissionLandline);
                    }

                    const salesCommissionName = editor.getFormFieldInfo('SalesContactName');
                    if (salesCommissionName) {
                        salesCommissionName.defaultValue = reportData.SalesData.Contact.Name;
                        editor.setFormFieldInfo('SalesContactName', salesCommissionName);
                    }

                    const salesCommissionPhone = editor.getFormFieldInfo('SalesContactPhone');
                    if (salesCommissionPhone) {
                        salesCommissionPhone.defaultValue = reportData.SalesData.Contact.Phone;
                        editor.setFormFieldInfo('SalesContactPhone', salesCommissionPhone);
                    }

                    const salesDeliveryDate = editor.getFormFieldInfo('SalesDeliveryDate');
                    if (salesDeliveryDate) {
                        salesDeliveryDate.defaultValue = new Date(Number(reportData.SalesData.DeliveryDate)).toLocaleDateString();
                        editor.setFormFieldInfo('SalesDeliveryDate', salesDeliveryDate);
                    }

                    const salesEquipments = editor.getFormFieldInfo('SalesEquipments');
                    if (salesEquipments) {
                        const equipments = [];
                        for(let equipment of reportData.SalesData.Equipment){
                            equipments.push(equipment.Name);
                        }
                        salesEquipments.defaultValue = equipments.toString();
                        editor.setFormFieldInfo('SalesEquipments', salesEquipments);
                    }

                    const salesMembers = editor.getFormFieldInfo('SalesMembers');
                    if (salesMembers) {
                        const members = [];
                        for(let member of reportData.SalesData.Members){
                            members.push(member.Name);
                        }
                        salesMembers.defaultValue = members.toString();
                        editor.setFormFieldInfo('SalesMembers', salesMembers);
                    }

                    const salesOfferName = editor.getFormFieldInfo('SalesOfferName');
                    if (salesOfferName) {
                        salesOfferName.defaultValue = reportData.SalesData.OfferName;
                        editor.setFormFieldInfo('SalesOfferName', salesOfferName);
                    }

                    const salesOfferValue = editor.getFormFieldInfo('SalesOfferValue');
                    if (salesOfferValue) {
                        salesOfferValue.defaultValue = reportData.SalesData.OfferValue;
                        editor.setFormFieldInfo('SalesOfferValue', salesOfferValue);
                    }

                    const salesOrderNumber = editor.getFormFieldInfo('SalesOrderNumber');
                    if (salesOrderNumber) {
                        salesOrderNumber.defaultValue = reportData.SalesData.OrderNumber;
                        editor.setFormFieldInfo('SalesOrderNumber', salesOrderNumber);
                    }

                    const salesReferenceNumber = editor.getFormFieldInfo('SalesReferenceNumber');
                    if (salesReferenceNumber) {
                        salesReferenceNumber.defaultValue = reportData.SalesData.ReferenceNumber;
                        editor.setFormFieldInfo('SalesReferenceNumber', salesReferenceNumber);
                    }

                    const salesRegisteredBy = editor.getFormFieldInfo('SalesRegisteredBy');
                    if (salesRegisteredBy) {
                        salesRegisteredBy.defaultValue = reportData.SalesData.RegisteredBy;
                        editor.setFormFieldInfo('SalesRegisteredBy', salesRegisteredBy);
                    }

                    const salesResponsible = editor.getFormFieldInfo('SalesResponsible');
                    if (salesResponsible) {
                        salesResponsible.defaultValue = reportData.SalesData.Responsible.Name;
                        editor.setFormFieldInfo('SalesResponsible', salesResponsible);
                    }

                    const salesDate = editor.getFormFieldInfo('SalesDate');
                    if (salesDate) {
                        salesDate.defaultValue = new Date(Number(reportData.SalesData.SalesDate)).toLocaleDateString();
                        editor.setFormFieldInfo('SalesDate', salesDate);
                    }

                    const salesSupplier = editor.getFormFieldInfo('SalesSupplier');
                    if (salesSupplier) {
                        const suppliers = [];
                        for(let supplier of reportData.SalesData.Supplier){
                            suppliers.push(supplier.LocationName);
                        }
                        salesSupplier.defaultValue = suppliers.toString();
                        editor.setFormFieldInfo('SalesSupplier', salesSupplier);
                    }

                    const salesTitle = editor.getFormFieldInfo('SalesTitle');
                    if (salesTitle) {
                        salesTitle.defaultValue = reportData.SalesData.Title;
                        editor.setFormFieldInfo('SalesTitle', salesTitle);
                    }
                }
            } catch {
                console.log('error')
            }

            /**
             * Start Tags
             */
            try {
                if (reportData.ProjectTags && Object.keys(reportData.ProjectTags).length > 0) {
                    for(const key of Object.keys(reportData.ProjectTags)){
                        const foundTag = this.tags.find(tag => tag.Id === key);

                        try {
                            const tagValue = editor.getFormFieldInfo(foundTag.ClusterName);
                            const engTagValue = editor.getFormFieldInfo(foundTag.ClusterNameEn);

                            if (tagValue) {
                                tagValue.defaultValue = reportData.ProjectTags[foundTag.Id];
                                editor.setFormFieldInfo(foundTag.ClusterName, tagValue);
                            }

                            if (engTagValue) {
                                engTagValue.defaultValue = reportData.ProjectTags[foundTag.Id];
                                editor.setFormFieldInfo(foundTag.ClusterNameEn, engTagValue);
                            }
                        } catch {
                            console.log('error')
                        }
                    }
                }
            } catch {
                console.log('error')
            }

            /**
             * Start Footer / Dates
             */
            try {
                const date = editor.getFormFieldInfo('Date');
                if (date) {
                    date.defaultValue = new Date().toLocaleDateString();
                    editor.setFormFieldInfo('Date', date);
                }
            } catch {
                console.log('error')
            }

            try {
                const placeAndDate = editor.getFormFieldInfo('PlaceAndDate');
                if (placeAndDate) {
                    placeAndDate.defaultValue = new Date().toLocaleDateString();
                    editor.setFormFieldInfo('PlaceAndDate', placeAndDate);
                }
            } catch {
                console.log('error')
            }
        },
    }
}