<template>
  <Dropdown v-model="reportType" :options="reportTypes" class="w-full mb-2" optionLabel="ReportType"
            :class="v$.reportType.$error ? 'p-invalid' : ''" :placeholder="$t('reportType')" />
  <Dropdown v-model="project" :options="projects" optionLabel="Title" class="w-full mb-2"
            :class="v$.project.$error ? 'p-invalid' : ''" :placeholder="this.$t('placeholder.selectProject')"/>
</template>

<script>
import Dropdown from "primevue/dropdown";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators/dist";

export default {
  name: "SelectProjectDropdown",
  components:{Dropdown},
  emits:['emitDropdowns'],
  data(){
    return {
      project:null,
      reportType:null,
      reportTypes:[
        {ReportType:this.$t('visitReport'),ReportTypeString:'besuchsberichtSales'},
        {ReportType:this.$t('phoneReport'), ReportTypeString:'telefonberichtSales'},
      ],
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      project:{required},
      reportType:{required},
    }
  },

  computed:{
    projects(){
      return this.$store.getters.projects.map(project => ({Title:project.Title,Id:project.Id}));
    }
  },

  methods:{
    confirmSelectedProject(){
      this.v$.$validate();
      if(!this.v$.$error) {
        this.emitDropdowns({projectId:this.project.Id, reportType: this.reportType});
      }
    },

    emitDropdowns(props){
      this.$emit('emit-dropdowns',props);
    }
  }
}
</script>

<style scoped>

</style>