<template>
  <div class="flex justify-between gap-3 mt-3 align-items-center pb-3 border-b border-gray-300">
    <label>{{$t('language')}}</label>
    <Dropdown
      class="w-full"
      v-model="language"
      :options="languageOptions"
      :placeholder="$t('placeholder.selectLanguage')"
      @change="changeLanguage"
      />
  </div>
  <div class="flex justify-between gap-3 mt-3 align-items-center pb-3 border-b border-gray-300">
    <label>{{$t('theme')}}</label>
    <Dropdown
      class="w-full"
      v-model="currentTheme"
      :options="themeOptions"
      :placeholder="$t('placeholder.theme')"
      @change="changeTheme"
    />
  </div>
</template>

<script>
import Dropdown from 'primevue/dropdown';

export default {
  name: "SettingsComponent",
  components:{Dropdown},
  data(){
    return {
      language:this.$i18n.locale,
      languageOptions:['de','en'],
      currentTheme: this.$t('darkMode'),
      themeOptions:[this.$t('lightMode'), this.$t('darkMode')],
    }
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale === "en" ? this.setLanguage('de') : this.setLanguage('en');
    },

    setLanguage(language) {
      localStorage.setItem("lang", language);
      this.$i18n.locale = language;
      this.$toast.add({
        severity: 'info',
        summary: this.$t('confirmed'),
        detail: this.$t('swal.changingLanguage'),
        life: 3000
      });
      location.reload();
    },

    changeTheme() {
      const currentTheme = this.currentTheme === this.$t('lightMode') ? 'aura-dark-blue' : 'lara-light-blue';
      const nextTheme = this.currentTheme === this.$t('lightMode') ? 'lara-light-blue' : 'aura-dark-blue';
      this.$primevue.changeTheme(currentTheme, nextTheme, 'theme-link', () => {})
      localStorage.setItem('salesTheme', nextTheme);
    }
  },
  mounted(){
    if(localStorage.getItem('salesTheme')){
      const theme = localStorage.getItem('salesTheme');
      this.currentTheme = theme === 'lara-light-blue' ? this.$t('lightMode') : this.$t('darkMode');
      this.nextTheme = theme === 'lara-light-blue' ? this.$t('darkMode') : this.$t('lightMode');
    }
  }
}
</script>

<style scoped>

</style>