export default {
    methods:{
        arrayHasAllKeys(array, object) {
            // Step 1: Check if array length matches object keys length
            const objectKeys = Object.keys(object);
            if (array.length !== objectKeys.length) {
                return false; // Array length doesn't match object keys length
            }

            // Step 2: Check if all object keys are present in the array
            for (const key of objectKeys) {
                if (!array.includes(key)) {
                    return false; // Key not found in array
                }
            }

            return true; // All keys found in array
        },
    }
}