<template>
  <div class="col-12 lg:col-6 xl:col-2">
    <SmallCard :header="this.$t('leadCount')" :text="leadCount" :icon="'text-cyan-500 pi pi-pencil'"
               @card-clicked="goToTableTab(0)" :text-class="'font-bold'" :card-class="'cursor-pointer'"/>
  </div>
  <div class="col-12 lg:col-6 xl:col-2">
    <SmallCard :header="this.$t('projectCount')" :text="projectCount" :icon="'text-blue-500 pi pi-briefcase'"
               @card-clicked="goToTableTab(1)" :text-class="'font-bold'" :card-class="'cursor-pointer'"/>
  </div>
  <div class="col-12 lg:col-6 xl:col-2">
    <SmallCard :header="this.$t('taskCount')" :text="taskCount" :icon="'text-indigo-500 pi pi-tablet'"
               @card-clicked="goToTableTab(2)" :text-class="'font-bold'" :card-class="'cursor-pointer'"/>
  </div>
  <div class="col-12 lg:col-6 xl:col-2">
    <SmallCard :header="this.$t('offerCount')" :text="offerCount" :icon="'text-green-500 pi pi-file'"
               @card-clicked="goToTableTab(3)" :text-class="'font-bold'" :card-class="'cursor-pointer'"/>
  </div>
  <div class="col-12 lg:col-6 xl:col-2">
    <SmallCard :header="this.$t('saleCount')" :text="saleCount" :icon="'text-indigo-500 pi pi-dollar'"
               @card-clicked="goToTableTab(4)" :text-class="'font-bold'" :card-class="'cursor-pointer'"/>
  </div>
  <div class="col-12 lg:col-6 xl:col-2">
    <SmallCard :header="this.$t('correspondenceCount')" :text="correspondenceCount" :icon="'text-indigo-500 pi pi-copy'"
               @card-clicked="goToTableTab(5)" :text-class="'font-bold'" :card-class="'cursor-pointer'"/>
  </div>
</template>

<script>
import SmallCard from "@/components/global-components/card/SmallCard";
export default {
  name: "PersonDashboardCards",
  components: {SmallCard},
  emits:['goToTableTab'],
  props:{
    leadCount:Number,
    projectCount:Number,
    taskCount:Number,
    offerCount:Number,
    saleCount:Number,
    correspondenceCount:Number,
  },
  methods:{
    goToTableTab(tabIndex){
      this.$emit('go-to-table-tab', tabIndex);
    }
  }
}
</script>

<style scoped>

</style>