export default {
    methods:{
        getValFromObject(obj,key){
            let val = "";
            if (obj && obj[key]){
                val = obj[key];
            }
            return val;
        },
        getTags(tags){
            let val = "";
            for(let tag of Object.values(tags)){
                if(val !== ""){
                    val = val + ", "
                }
                val = val + tag;
            }

            return val;
        },
    }
}