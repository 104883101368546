<template>
    <div v-if="show" class="loader" id="divLoaderParent">
      <ProgressSpinner />
    </div>

</template>

<script>
import ProgressSpinner from 'primevue/progressspinner';

export default {
  name: "LoaderComponent",
  components:{ProgressSpinner},
  props:{
    show:Boolean
  }
}
</script>

<style scoped>

.loader{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}
</style>
