<template>
  <div v-if="projectId && reportId">
    <VisitReport ref="visitReport"
                 :project-id="projectId"
                 :report-id="reportId"
                 :show-task-buttons="reportType.ReportType === $t('visitReport')"
                 :members="members"
                 :company-id="companyId"
                 :report-type="reportType.ReportTypeString"
                 :is-read-only="false"
                 @visit-report-saved="emitClosePopup" />
  </div>

  <Popup :header="this.$t('placeholder.selectProject')"
         :modal="true" :visible="showSelectProjectId"
         :show-save-button="true"
         @button-clicked="selectProject"
         @close-popup="closeAddVisitReportPopup"
         styling="width:30%" >
    <SelectProjectDropdown ref="projectDropdown"
                           @emit-dropdowns="setDropdowns"/>
  </Popup>

</template>

<script>
import Popup from "@/components/global-components/popup/Popup";
import SelectProjectDropdown from "@/components/reports/visit-report/select-project-dropdown/SelectProjectDropdown";
import VisitReport from "@/components/reports/visit-report/VisitReport";
import uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";

export default {
  name: "AddVisitReport",
  components:{VisitReport, SelectProjectDropdown, Popup},
  mixins:[uuidv4Generator],
  emits:['closeAddVisitReport','closePopup'],
  data(){
    return{
      companyId:"",
      reportId:"",
      projectId:"",
      members:[],
      showSelectProjectId: true,
      reportType:"",
    }
  },

  methods:{
    setDropdowns(props){
      const projectId = props.projectId;
      this.reportType = props.reportType;
      this.reportId = this.createUuidv4();
      this.projectId = projectId;
      const projects = this.$store.getters.projects;
      const project = projects.find(project => project.Id === projectId);
      this.members = this.getProjectMembers(project);
      this.companyId = project.Company && project.Company.Id;
      this.showSelectProjectId = false;
    },

    getProjectMembers(project){
      const members = [];
      members.push(project.Responsible);
      for(let member of project.Members){
        if(member.Id !== project.Responsible.Id) members.push(member);
      }
      return members;
    },

    saveButton(){
      this.$refs.visitReport.saveButton();
    },

    setOpenAddTask(){
      this.$refs.visitReport.setOpenAddTask();
    },

    setOpenEditTask(){
      this.$refs.visitReport.setOpenEditTask();
    },

    selectProject(){
      this.$refs.projectDropdown.confirmSelectedProject();
    },

    pdfExport(){
      this.$refs.visitReport.pdfExport();
    },

    emitClosePopup(){
      this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.reportSaved'), life: 3000 });
      this.$emit('close-popup');
    },

    closeAddVisitReportPopup(){
      this.$emit('close-add-visit-report');
    },

  }
}
</script>

<style scoped>

</style>