<template>
  <MaterialTable
    :data-key="'Id'"
    :table-id="'myTasks'"
    :data-prop="tasks"
    :columns="columns"
    :has-paginator="true"
    :rows-count="10"
    :has-global-filter="true"
    :filter-prop="filter"
    :filter-options="filterOptions"
    :style="{'height': windowHeight}"
    :show-delete-button="true"
    @row-deleted="deleteTask"
    :show-close-button="true"
    @close-data="closeTask"
    :sortable="true"
    :has-removable-sort="true"
    :key="tableKey"
    :selection-mode="'single'"
    @row-selected="goToProject"
    :resizable-columns="true"
    :show-task-is-due="true"
    :close-button-tool-tip="$t('swal.closeTaskHeader')"
    :delete-button-tool-tip="$t('swal.deleteTaskHeader')"/>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "ProjectTaskTab",
  components:{MaterialTable},
  props:{
    tasks: Array,
    windowHeight: String,
  },
  data(){
    return{
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Phase.Name', header: "Phase" },
        { field: 'Created', header: this.$t('createdOn') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Phase.Name': { value: null, matchMode: FilterMatchMode.IN },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Responsible.Name','Phase.Name','Title'],
      tableKey:0,
    }
  },

  methods:{
    goToProject(task){
      const projectId = task.ProjectId;
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
    },

    /**
     * Shows an alert that asks for confirmation to close the task.
     * If we accept we show a short confirmation popup in the top-right corner and
     * change the status to 20 and set the Closed Unix.
     * If we decline we show a short reject popup in the top-right corner.
     */
    closeTask(task){
      this.$confirm.require({
        header: this.$t('swal.closeTaskHeader'),
        message: this.$t('swal.closeTaskText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          task.Status = '20';
          task.Closed = new Date().getTime();
          this.$store.dispatch('closeTask',task);
          this.editingTask = false;
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.taskClosed'), life: 3000 });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    /**
     * Shows an alert that asks for confirmation to delete the task.
     * If we accept we show a short confirmation popup in the top-right corner and
     * remove the task from the store.
     * If we decline we show a short reject popup in the top-right corner.
     */
    deleteTask(task){
      this.$confirm.require({
        header: this.$t('swal.deleteTaskHeader'),
        message: this.$t('swal.deleteTaskText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.taskDeleted'), life: 3000 });
          this.$store.dispatch('deleteTask',task);
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },
  }
}
</script>

<style scoped>

</style>