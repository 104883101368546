export function isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
}

import { Auth } from 'aws-amplify'; // AWS Amplify Auth for Cognito

// Utility function to check authentication status
export async function isAuthenticated() {
    try {
        const user = await Auth.currentAuthenticatedUser(); // Get the current user
        return !!user; // Returns true if a user is logged in
    } catch (error) {
        return false; // User is not authenticated
    }
}