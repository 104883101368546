export default {
    computed:{
        isDesktop(){
            return window.innerWidth > 600
        },

        username(){
            return this.$store.getters.shortUsername;
        }
    }
}