<template>
  <SmallCard :header="this.$t('reports')"
             :use-slot="true"
             :show-button="showSaveButton"
             :card-class="'h-full'"
             :button-icon="'pi pi-plus'"
             :button-label="this.$t('createReport')"
             @button-clicked="setSelectReport">
    <MaterialTable :data-key="'ReportName'"
                   :table-id="'reports'"
                   :style="{'height': windowHeight}"
                   :data-prop="reports"
                   :columns="columns"
                   :has-paginator="true"
                   :rows-count="10"
                   :has-global-filter="true"
                   :filter-prop="filter"
                   :sortable="true"
                   :has-removable-sort="true"
                   :show-delete-button="showSaveButton"
                   @row-deleted="deleteReport"
                   :key="tableKey"
                   :selection-mode="'single'"
                   :resizable-columns="true"
                   @row-selected="goToReport"/>
  </SmallCard>

  <Popup :header="reportType"
         :visible="openReport"
         styling="width:100%"
         :show-menu-button="true"
         :menu-options="reportMenuOptions"
         :position="'top'"
         :show-save-button="showSaveButton"
         @button-clicked="submitReportPopup"
         @close-popup="closePopup">
    <VisitReport ref="report"
                 :report-id="reportId"
                 :project-id="projectId"
                 :existing-report-id="existingReportId"
                 :is-read-only="!showSaveButton"
                 :members="members"
                 :report-type="reportTypeString"
                 :show-task-buttons="reportType === 'Besuchsbericht'"
                 :company-id="companyId"
                 @visit-report-saved="reportSaved"
                 @close-report="closePopup"/>
  </Popup>

  <Popup :header="$t('selectReportType')"
         :visible="openSelectReportType"
         styling="width:50%"
         :modal="true"
         :show-save-button="true"
         @button-clicked="submitSelectReportTypePopup"
         @close-popup="openSelectReportType = false">
    <SelectReportType ref="selectReportType"
                      @submit-select-report="goToReport" />
  </Popup>
</template>

<script>
import SmallCard from "@/components/global-components/card/SmallCard";
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import Popup from "@/components/global-components/popup/Popup";
import VisitReport from "@/components/reports/visit-report/VisitReport";
import {FilterMatchMode} from "primevue/api"
import SelectReportType from "@/components/reports/select-report-type/SelectReportType";
import uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";

export default {
  name: "ReportTable",
  components: {SelectReportType, VisitReport, MaterialTable, SmallCard, Popup},
  mixins:[uuidv4Generator],
  emits:['removeReport', 'reportSaved'],
  props:{
    reports:Array,
    showSaveButton:Boolean,
    projectId:String,
    members:Array,
    companyId:String,
  },
  data(){
    return {
      columns: [
        { field: 'VisitReport', header: this.$t('title') },
        { field: 'ReportName', header: this.$t('creator') },
        { field: 'ReportTableReportType', header: this.$t('type') },
        { field: 'LastModified', header: this.$t('lastModified') },
      ],
      existingReportId:false,
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PhoneReport: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ReportName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ReportTableReportType: { value: null, matchMode: FilterMatchMode.CONTAINS },
        LastModified: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      openReport:false,
      openSelectReportType:false,
      reportId:'',
      reportType:'',
      reportTypeString:'',
      tableKey:0,
      reportMenuOptions:[],
      windowHeight: '',
    }
  },

  methods:{
    submitSelectReportTypePopup(){
      this.$refs.selectReportType.selectReport();
    },
    setSelectReport(){
      this.openSelectReportType = true;
    },
    /**
     * we give the projectId and reportId into a new tab.
     * @param file
     */
    goToReport(file){
      const id = file && file.Key ? file.Key.split('/')[4].split('.')[0] : null;
      this.reportId = id ? id : this.createUuidv4();
      this.reportType = file.ReportTableReportType;
      this.reportTypeString = file.ReportTypeString;
      this.createReportMenuOptions();
      this.existingReportId = !!id;
      this.openReport = true;
      this.openSelectReportType = false;
    },

    createReportMenuOptions(){
      const optionsMenu = []
      if(this.reportType === 'Besuchsbericht' && this.showSaveButton){
        optionsMenu.push(
            { label: this.$t('addTask'), icon: 'pi pi-fw pi-file',
              command: () => { this.$refs.report.setOpenAddTask();}
            },
            { separator : true},
            { label: this.$t('editTask'), icon: 'pi pi-fw pi-file',
              command: () => {this.$refs.report.setOpenEditTask();}
            },
            { separator : true},
        );
      }

      optionsMenu.push(
          { label: this.$t('pdfExport'), icon: 'pi pi-fw pi-check',
            command: () => {this.$refs.report.pdfExport();}
          },
      )

      this.reportMenuOptions = optionsMenu;
    },

    submitReportPopup(){
      this.$refs.report.saveButton();
    },
    /**
     * Deletes the report & the correspondence linked with this report,
     * since we are showing all our reports in the QueryBuilder & CompanyDashboard according to the correspondence.
     * @param report
     */
    deleteReport(report){
      const id = report.Name.split('.')[0].split('_')[0];

      this.$confirm.require({
        header: this.$t('swal.deleteReportHeader'),
        message: this.$t('swal.deleteReportText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          const foundProject = this.$store.getters.projects.find(project => project.Id === this.projectId);
          if(foundProject){
            if(foundProject.Correspondence && foundProject.Correspondence.length > 0){
              foundProject.Correspondence = foundProject.Correspondence.filter(correspondence => correspondence.Id !== id);
              this.$store.dispatch('updateCorrespondence',foundProject)
            }
          }
          
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.reportDeleted'), life: 3000 });

          this.$store.dispatch('deleteS3Object',report.Key).then(() => {
            this.$emit('remove-report',report.Key);
          });

        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    reportSaved(report){
      this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.reportSaved'), life: 3000 })
      this.$emit('report-saved',report);
      this.existingReportId = false;
    },

    closePopup(){
      this.openReport = false;
      this.existingReportId = false;
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>