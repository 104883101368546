<template>
  <TabView>
    <TabPanel :header="this.$t('open') + ' (' + personOpenTasks.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'personTasks'"
                     :style="{'height': windowHeight}"
                     :data-prop="personOpenTasks"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitTaskClicked"/>
    </TabPanel>

    <TabPanel :header="this.$t('closed') + ' (' + personClosedTasks.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'personTasks'"
                     :style="{'height': windowHeight}"
                     :data-prop="personClosedTasks"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitTaskClicked"/>
    </TabPanel>
  </TabView>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "PersonMaterialTableTaskTab",
  components:{MaterialTable, TabView, TabPanel},
  emits:['taskClicked'],
  props:{
    tasks:Array,
  },
  data(){
    return{
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Phase.Name', header: "Phase" },
        { field: 'Created', header: this.$t('createdOn') },
        { field: 'ProjectId', header: this.$t('projectTitle') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        'Phase.Name': { value: null, matchMode: FilterMatchMode.IN },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ProjectId: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'Phase.Name', 'Responsible.Name',],
      tableKey:0,
      windowHeight: '',
    }
  },

  computed:{
    projects(){
      return this.$store.getters.projects;
    },

    personOpenTasks(){
      return this.setPersonTasks("10");
    },

    personClosedTasks(){
      return this.setPersonTasks("20");
    }
  },

  methods:{
    /**
     * Sets all tasks from the current person dashboard,
     * where the person is set as responsible and the task status equals the parameter.
     * @param status
     */
    setPersonTasks(status){
      const currentTasks = [];
      for(let task of this.tasks){
        if(task.Status === status){
          currentTasks.push(task);
        }
      }
      return currentTasks;
    },

    emitTaskClicked(task){
      const data = {data:task, type:'Task'};
      this.$emit('task-clicked', data);
    }
  },
   
  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>