<template>
  <TabView>
    <TabPanel :header="$t('open') + ' (' + openPersonOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'personOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="openPersonOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>

    <TabPanel :header="$t('sold') + ' (' + soldPersonOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'personOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="soldPersonOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>

    <TabPanel :header="$t('rejected') + ' (' + rejectedPersonOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'personOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="rejectedPersonOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>

    <TabPanel :header="$t('replaced') + ' (' + replacedPersonOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'personOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="replacedPersonOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>

    <TabPanel :header="$t('lost') + ' (' + lostPersonOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'personOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="lostPersonOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>

    <TabPanel :header="$t('delayed') + ' (' + delayedPersonOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'personOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="delayedPersonOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>
  </TabView>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "PersonMaterialTableOfferTab",
  components:{MaterialTable, TabView, TabPanel },
  emits:['offerClicked'],
  data(){
    return{
      columns: [
        { field: 'Title', header: this.$t('title') },
        { field: 'Members', header: this.$t('members') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'Probability', header: this.$t('probability') },
        { field: 'SaleValue', header: this.$t('budget') },
        { field: 'OfferValue', header: this.$t('offerValue') },
        { field: 'BonusOption', header: this.$t('bonusOption') },
        { field: 'OfferNumber', header: this.$t('offerNumber') },
        { field: 'OfferType', header: this.$t('offerType') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Members: { value: null, matchMode: FilterMatchMode.CONTAINS },
        DeliveryDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Probability: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SaleValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        BonusOption: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferType: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'Probability', 'SaleValue', 'OfferValue', 'BonusOption', 'OfferNumber', 'OfferType'],
      tableKey:0,
      windowHeight: '',
    }
  },
  props:{
    personId:String,
    offers:Array,
  },
  computed:{
    openPersonOffers(){
      return this.setPersonOffers("10");
    },

    soldPersonOffers(){
      return this.setPersonOffers("20");
    },

    rejectedPersonOffers(){
      return this.setPersonOffers("30");
    },

    replacedPersonOffers(){
      return this.setPersonOffers("40");
    },

    lostPersonOffers(){
      return this.setPersonOffers("50");
    },

    delayedPersonOffers(){
      return this.setPersonOffers("60");
    },
  },

  methods:{
    /**
     * Sets all offers from the current person dashboard,
     * when the offer status equals the parameter.
     * @param status
     */
    setPersonOffers(status){
      const personOffers = [];
      for(let offer of this.offers){
        if(offer.Status === status){
          personOffers.push(offer)
        }
      }
      return personOffers;
    },

    emitOfferClicked(offer){
      const data = {data:offer, type:'Offer'};
      this.$emit('offer-clicked',data);
    }
  },
   
  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>