<template>
  <MaterialTable :data-key="'Id'"
                 :table-id="'searchOffer'"
                 :style="{'height': windowHeight}"
                 @row-selected="emitSelectionChanged"
                 :data-prop="optionsList"
                 :columns="columns"
                 :has-paginator="true"
                 :rows-count="10"
                 :has-global-filter="true"
                 :filter-prop="filter"
                 :filter-options="filterOptions"
                 :sortable="true"
                 :show-toggle-column="true"
                 :has-removable-sort="true"
                 :key="tableKey"
                 :selection-mode="'single'"
                 :resizable-columns="true"/>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
export default {
  name: "OfferSearchTable",
  components: {MaterialTable},
  emits:['selectionChanged'],
  props:{
    optionsList:Array,
  },
  data(){
    return{
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Members', header: this.$t('members') },
        { field: 'OfferDate', header: this.$t('offerDate') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'Probability', header: this.$t('probability') },
        { field: 'SaleValue', header: this.$t('budget') },
        { field: 'OfferValue', header: this.$t('offerValue') },
        { field: 'BonusOption', header: this.$t('bonusOption') },
        { field: 'OfferNumber', header: this.$t('offerNumber') },
        { field: 'OfferType', header: this.$t('offerType') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Members: { value: null, matchMode: FilterMatchMode.IN },
        OfferDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        DeliveryDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Probability: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SaleValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        BonusOption: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferType: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'Probability', 'SaleValue', 'OfferValue', 'BonusOption', 'OfferNumber', 'OfferType'],
      tableKey:0,
      windowHeight: '',
    }
  },
  methods:{
    emitSelectionChanged(item){
      this.$emit('selection-changed',item);
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.6 + 'px';
  }
}
</script>

<style scoped>

</style>