export const setTranslatedSyncfusionStrings ={
    'de-DE': {
        querybuilder: {
            AddGroup: 'Gruppe hinzufügen',
            AddCondition: 'Bedingung hinzufügen',
            SelectField: 'Wählen Sie ein Feld aus',
            SelectValue: 'Wert eingeben',
            AND: 'UND',
            OR: 'ODER',
            Equal: 'Gleich',
            Contains: 'Enthält'
        },
        grid: {
            EmptyRecord: 'Keine Daten vorhanden',
            Matchs: 'Keine Treffer gefunden',
            FilterButton: 'Filtern',
            ClearButton: 'Leeren',
            StartsWith: 'Beginnt mit',
            EndsWith: 'Ended mit',
            Contains: 'Enthält',
            Equal: 'Gleich',
            EnterValue: 'Geben Sie den Wert ein',
            IsEmpty: 'Leer',
            NotStartsWith: 'Beginnt nicht mit',
            NotEndsWith: 'Ended nicht mit',
            NotContains: 'Enthält nicht',
            NotEqual: 'Ungleich',
            IsNotEmpty: 'Nicht leer',
            LessThan: 'Weniger als',
            LessThanOrEqual: 'Weniger als oder gleich',
            GreaterThan: 'Mehr als',
            GreaterThanOrEqual: 'Mehr als oder gleich',
            Between: 'Zwischen',
            CustomFilter: 'Benutzerdefinierter Filter',
            DateFilter: 'Datum Filter',
            ClearFilter: 'Filter zurücksetzen',
            SortByOldest: 'Nach ältesten sortieren',
            SortByNewest: 'Nach neusten sortieren',
            Search: 'Suchen',
            SelectAll: 'Alle auswählen',
            TextFilter: 'Text Filter',
            CancelButton: 'Abbrechen',
            SortAtoZ: "Von A nach Z sortieren",
            SortZtoA: "Von Z nach A sortieren",
            NoResult: 'Keine Einträge gefunden',
            ShowRowsWhere: 'Einträge anzeigen wo',
            CustomFilterPlaceHolder: 'Wert eingeben',
            CustomFilterDatePlaceHolder: 'Datum auswählen',
            AND: 'Und',
            OR: 'Oder',
            MatchCase: 'Stimmt überein',
            IsNull: 'Leer',
            NotNull: 'Nicht leer',
            Blanks: 'Leer'
        },
        pager: {
            currentPageInfo: '{0} von {1} Seiten',
            firstPageTooltip: 'Zur ersten Seite',
            lastPageTooltip: 'Zur letzten Seite',
            nextPageTooltip: 'Zur nächsten Seite',
            nextPagerTooltip: 'Gehen Sie zu den nächsten Pager-Elementen',
            previousPageTooltip: 'Zur vorherigen Seite',
            previousPagerTooltip: 'Gehen Sie zu den vorherigen Pager-Elementen',
            totalItemsInfo: '({0} Beiträge)'
        },
    }
}