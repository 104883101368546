<template>
  <div v-for="notification of notifications" class="flex justify-content-between mb-1 pb-1 border-bottom-1 surface-border" :key="notification">
    <div class="pb-3 mt-2 cursor-pointer" @click="goToProject(notification.projectId)">{{notification.text}}</div>
    <PrimeButton icon="pi pi-trash" severity="danger" outlined size="small" rounded @click="deleteNotification(notification)" />
  </div>
</template>

<script>
import PrimeButton from "primevue/button";

export default {
  name: "NotificationComponent",
  components:{PrimeButton},
  emits:['closePopup'],
  computed:{
    notifications(){
      return this.$store.getters.notifications;
    },
  },
  methods:{
    goToProject(projectId){
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
      this.$emit('close-popup');
    },

    deleteNotification(notification){
      this.$store.dispatch('deleteNotification',notification);
    }
  }
}
</script>

<style scoped>

</style>