import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Leads from "@/views/Leads";
import Projects from "@/views/Projects";
import ProjectDashboard from "@/views/ProjectDashboard";
import CompanyDashboard from "@/views/CompanyDashboard";
import Companies from "@/views/Companies";
import Persons from "@/views/Persons";
import PersonDashboard from "@/views/PersonDashboard";
import Login from "@/views/Login";
import ContactDashboard from "@/views/ContactDashboard";
import QueryBuilder from "@/views/QueryBuilder";
import Sales from "@/views/Sales";
import {isAuthenticated, isMobile} from "@/router/utils";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Projects
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/leads',
    name: 'Leads',
    component: Leads
  },
  {
    path:'/projects',
    name:'Projects',
    component: Projects
  },
  {
    path:'/sales',
    name:'Sales',
    component: Sales
  },
  {
    path:'/projects/dashboard/:Id',
    name:'Project Dashboard',
    component: ProjectDashboard
  },
  {
    path:'/companies',
    name:'Companies',
    component: Companies
  },
  {
    path:'/queryBuilder',
    name:'Query Builder',
    component: QueryBuilder
  },
  {
    path:'/companies/dashboard/:Id',
    name:'Company Dashboard',
    component: CompanyDashboard
  },
  {
    path:'/persons',
    name:'Persons',
    component: Persons
  },
  {
    path:'/persons/dashboard/:Id',
    name:'Person Dashboard',
    component: PersonDashboard
  },
  {
    path:'/persons/contactDashboard/:Id',
    name:'Contact Dashboard',
    component: ContactDashboard
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

// Global Navigation Guard
router.beforeEach(async (to, from, next) => {
  const isOnMobile = isMobile(); // Check if the user is on mobile
  const loggedIn = await isAuthenticated(); // Check if the user is authenticated

  const allowedMobileRoutes = ['Leads', 'Login']; // Allow homepage and login page

  // If the user is not logged in and trying to access any page, redirect to login
  if (!loggedIn && to.name !== 'Login') {
    next({ name: 'Login' });
  }
  // If the user is on mobile and trying to access a restricted route, redirect to mobile home
  else if (isOnMobile && !allowedMobileRoutes.includes(to.name)) {
    next({ name: 'Leads' });
  } else {
    next(); // Allow access to the requested page
  }
});

export default router
