<template>
  <div class="comment-container">
    <div class="messages-container">
      <div v-for="comment of reversedComments" :key="comment">
        <Message severity="success" class="m-2">
          <div class="message-container">
            <Avatar :label="comment.Author.substring(0,2).toUpperCase()" class="mt-2"/>
            <div class="message-content">
              <span>{{comment.Text}}</span>
              <div class="timestamp">{{new Date(Number(comment.Unix)).toLocaleDateString()}}, {{new Date(Number(comment.Unix)).toLocaleTimeString()}}</div>
            </div>
          </div>
        </Message>
      </div>
    </div>
    <div v-if="projectOpen && taskOpen" class="flex justify-content-between gap-2 m-2">
      <InputText class="w-full" v-model="comment.Text" @keydown.enter="submitComment" :placeholder="this.$t('placeholder.enterComment')" />
      <PrimeButton icon="pi pi-arrow-circle-right" size="large" severity="contrast" text rounded @click="submitComment" />
    </div>
  </div>
</template>

<script>
import Avatar from 'primevue/avatar';
import InputText from "primevue/inputtext";
import PrimeButton from "primevue/button";
import uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";

export default {
  name: "TaskComments",
  components:{Avatar, InputText, PrimeButton},
  mixins:[uuidv4Generator],
  emits:['addComment'],
  data(){
    return{
      comment:{Author:'',Text:'',Unix:'',Id:''}
    }
  },
  props:{
    projectOpen:Boolean,
    taskComments:Array,
    taskOpen:Boolean,
  },
  computed:{
    reversedComments(){
      return this.taskComments ? this.taskComments.slice().reverse() : [];
    },
    user(){
      return this.$store.getters.shortUsername;
    }
  },
  methods:{
    submitComment(){
      if(this.comment.Text !== ""){
        this.comment.Id = this.createUuidv4();
        this.comment.Author = this.user;
        this.comment.Unix = new Date().getTime();
        this.$emit('add-comment',this.comment);
        this.comment = {Author:'',Text:'',Unix:'',Id:''}
      }
    }
  }
}
</script>

<style scoped>

.comment-container {
  display: flex;
  flex-direction: column;
  max-height: 450px;
}

.messages-container {
  overflow-y: auto;
  max-height: 100%;
}

.message-container {
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  margin: 0 10px 0 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.message-content {
  margin: 12px 5px 0 5px;
  flex: 1;
}

.timestamp {
  font-size: 12px;
  color: #888;
  text-align: right;
  margin-top: 5px;
}
</style>