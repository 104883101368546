<template>
  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('title')"
      :input="offer.Title">
      <InputText
        class="w-full"
        :disabled="disabled"
        v-model="offer.Title"
        :class="v$.offer.Title.$error ? 'p-invalid' : ''"
        :placeholder="this.$t('placeholder.title')" />
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('probability')"
      :input="offer.Probability">
      <Dropdown
        class="w-full"
        v-model="offer.Probability"
        :options="probabilityOptions"
        :disabled="disabled"
        :class="v$.offer.Probability.$error ? 'p-invalid' : ''"
        :placeholder="this.$t('placeholder.probability')" />
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('offerDate')"
      :input="offer.OfferDate">
      <Calendar
        class="w-full"
        :disabled="disabled"
        :modelValue="new Date(Number(offer.OfferDate))"
        dateFormat="dd.mm.yy"
        showIcon
        hideOnDateTimeSelect
        @update:modelValue="setOfferUnix($event)"/>
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('deliveryDate')"
      :input="offer.DeliveryDate">
      <Calendar
        class="w-full"
        :disabled="disabled"
        :modelValue="new Date(Number(offer.DeliveryDate))"
        dateFormat="dd.mm.yy"
        showIcon
        hideOnDateTimeSelect
        @update:modelValue="setDeliveryUnix($event)"/>
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="'Equipment'"
      :input="offer.Equipment?.length > 0">
      <MultiSelect
        class="w-full"
        :disabled="disabled"
        v-model="offer.Equipment"
        :options="equipments"
        optionLabel="Name"
        filter
        :placeholder="this.$t('placeholder.equipment')"
        autoFilterFocus/>
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('placeholder.visibleTo')"
      :input="offer.VisibleTo">
      <Dropdown
        class="w-full"
        :disabled="disabled"
        v-model="offer.VisibleTo"
        :options="visibleToOptions"
        :class="v$.offer.VisibleTo.$error ? 'p-invalid' : ''"
        :placeholder="this.$t('placeholder.visibleTo')"/>
    </CustomLabel>
  </div>

  <div
    v-if="offer.VisibleTo === 'Members'">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('placeholder.visibleTo')"
      :input="offer.VisibleTo">
      <MultiSelect
        class="w-full"
        :disabled="disabled"
        v-model="offer.Members"
        :options="members"
        optionLabel="Name"
        filter
        :placeholder="this.$t('placeholder.selectMembers')"
        autoFilterFocus/>
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel 
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('budget')"
      :input="offer.SaleValue">
      <InputNumber
        class="w-full"
        :disabled="disabled"
        v-model="offer.SaleValue"
        :placeholder="this.$t('placeholder.budget')" />
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('offerValue')"
      :input="offer.OfferValue">
      <InputNumber
        class="w-full"
        :disabled="disabled"
        v-model="offer.OfferValue"
        :placeholder="this.$t('placeholder.offerValue')" />
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('bonusOption')"
      :input="offer.BonusOption">
      <InputNumber
        class="w-full"
        v-model="offer.BonusOption"
        :disabled="disabled"
        :placeholder="this.$t('placeholder.bonusOption')" />
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('offerNumber')"
      :input="offer.OfferNumber">
      <InputText
        class="w-full"
        v-model="offer.OfferNumber"
        :disabled="disabled"
        :placeholder="this.$t('placeholder.offerNumber')" />
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      v-if="offer.OfferType === 'Nachtrag'"
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('orderNumber')"
      :input="offer.OrderNumber">
      <InputText
        class="w-full"
        v-model="offer.OrderNumber"
        :disabled="disabled"
        :placeholder="this.$t('placeholder.orderNumber')" />
    </CustomLabel>

    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="$t('supplier')"
      :input="offerSupplier">
      <Dropdown
        class="w-full"
        v-model="offerSupplier"
        :options="supplierOptions"
        optionLabel="Name"
        :disabled="disabled"
        @update:modelValue="setOfferSupplier"
        :placeholder="$t('placeholder.supplier')" />
    </CustomLabel>
  </div>

  <div
    class="flex justify-content-between gap-2">
    <CustomLabel
      class="pt-2 flex-1 basis-1/2 max-w-1/2 overflow-hidden"
      :label="this.$t('offerType')"
      :input="offer.OfferType">
      <Dropdown
        class="w-full"
        v-model="offer.OfferType"
        :options="offerTypeOptions"
        :disabled="disabled"
        :placeholder="this.$t('placeholder.offerType')"/>
    </CustomLabel>
  </div>

  <div
    v-if="projectOpen && !disabled"
    class="mb-2 mt-2">
    <FileUpload
      ref="fileUpload"
      name="demo[]"
      :customUpload="true"
      @uploader="uploadFiles"
      :multiple="true"
      accept=".jpg,.jpeg,.png,.eml,.msg,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx"
      :maxFileSize="52000000">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </div>

  <div
    v-if="files.length > 0">
    <AttachmentMaterialTable
      :attachments="files"
      :show-delete-button="projectOpen"
      @delete-attachment="deleteFile" />
  </div>

  <Popup
    :maximizable="false"
    :modal="true"
    :visible="closingOffer"
    :header="this.$t('swal.closeOfferText')"
    styling="width:500px"
    :show-save-button="true"
    @button-clicked="submitCloseOfferPopup"
    @close-popup="closingOffer = false">
    <CloseOffer
      ref="closeOffer"
      @close-offer="closeOffer" />
  </Popup>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import MultiSelect from "primevue/multiselect";
import FileUpload from "primevue/fileupload";
import Popup from "@/components/global-components/popup/Popup";
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import AttachmentMaterialTable from "@/components/global-components/attachment-material-table/AttachmentMaterialTable";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import CloseOffer from "@/components/projects/dashboard/offers/close-offer/CloseOffer";
import MSGReader from '@kenjiuno/msgreader';
import sanitizeFileName from "../../../../../mixins/sanitize-file-name/sanitize-file-name";

export default {
  name: "EditOffer",
  components:{
    CloseOffer,
    CustomLabel, AttachmentMaterialTable, Popup, InputText, Dropdown, Calendar, InputNumber, MultiSelect, FileUpload},
  mixins:[S3FileManager, sanitizeFileName],
  emits: ['closePopup','closeOffer'],
  props:{
    disabled:Boolean,
    offerProp:Object,
    members:Array,
    projectId:String,
    project:Object,
    projectOpen:Boolean,
  },
  data(){
    return {
      closingOffer:false,
      files:[],
      offer:JSON.parse(JSON.stringify(this.offerProp)),
      emailContent:null,
      supplierOptions:[],
      offerSupplier: null,
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      offer:{
        Company:{required},
        DeliveryDate:{required},
        OfferDate:{required},
        Probability:{required},
        Title:{required},
        VisibleTo:{required},
      }
    }
  },

  computed:{
    equipments(){
      const equipments = this.$store.getters.equipments;
      const changedEquipmentData = [];
      for(let equipment of equipments){
        changedEquipmentData.push({Name:equipment.EquipmentName,Id:equipment.Id});
      }
      return changedEquipmentData;
    },

    currentUserData(){
      let currentUser = {};
      const responsibles = this.$store.getters.responsibles;
      for(let responsible of responsibles){
        if(responsible.User === this.$store.getters.username){
          currentUser = responsible;
          break;
        }
      }

      return currentUser
    },

    visibleToOptions(){
      return ['Self','Members','All'];
    },

    probabilityOptions(){
      return ['25','50','75','100'];
    },

    offerTypeOptions(){
      return ['Hauptauftrag', 'Nachtrag', 'Werkzeug', 'Service'];
    }
  },

  methods:{
    setDeliveryUnix(event){
      this.offer.DeliveryDate = event.getTime();
    },

    setOfferUnix(event){
      this.offer.OfferDate = event.getTime();
    },

    setOfferSupplier(event) {
      this.offer.Supplier = event.Name;
      this.offer.SupplierId = event.Id;
    },

    uploadFiles(event){
      for (let file of event.files){
        let fileName = this.getFileName(file.name);
        const fileType = this.getFileType(file.name);
        this.$store.commit('setLoading',true);
        let filePath = 'sales/leadsAndProjects/' + this.projectId + '/offers/' + this.offer.Id + '/';
        // Check if it's an email file
        if (file.type.includes('message')) {
          // Wait for email content extraction asynchronously
          this.getEmailText(file).then(() => {
            fileName = ' ' + `${this.$t('date')}: ${this.emailContent.date}`  + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
              
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
        }else if (file.name.endsWith('.msg')) {
          this.readMsgFile(file).then((emailData) => {
            fileName = ' ' + `${this.$t('date')}: ${emailData.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
         }else{
          fileName = this.sanitizeFileName(fileName);
          filePath = filePath + fileName.trim();
          this.s3UploadFile(file, filePath).then(() => {
            this.files.push({
              Name:fileName,
              Type:fileType,
              LastModified:new Date(file.lastModified).toLocaleString(),
              Size:(file.size / (1024*1024)).toFixed(2) + " MB",
              "Key": filePath,
            });

            this.$store.commit('setLoading',false);
          }).catch((err) => {
            console.log(err);
            this.$store.commit('setLoading',false);
          });
        }
      }
    },

    getEmailText(file){
      return new Promise((resolve) => {
        const reader = new FileReader();
        
        // Read the file as text or binary, depending on your email file format (.eml or .msg)
        reader.onload = (e) => {
          const emailContent = e.target.result;
          this.emailContent = this.parseEmailContent(emailContent);
          resolve();
        };
        
        // Read as text (suitable for .eml files)
        reader.readAsText(file);
      });
    },

    async readMsgFile(file) {
      return new Promise((resolve) => {
        const fileReader = new FileReader();

        // When the file is loaded, use msg-parser to parse it
        fileReader.onload = async (e) => {
          const arrayBuffer = e.target.result;

          try {
            const msgReader = new MSGReader(arrayBuffer);
            const fileData = msgReader.getFileData();

            const emailData = {
            date: fileData.creationTime.replace(/,/g, "") || 'Unknown',
          };

          resolve(emailData)

          } catch (err) {
            console.error('Error parsing .msg file:', err);
          }
        };

        // Read the file as an ArrayBuffer
        fileReader.readAsArrayBuffer(file);
      });
    },

    getEmailFromSubject(emailString){
      return emailString.match(/<([^>]+)>/)[1];
    },

    parseEmailContent(emailContent) {
      // Parsing .eml content (simple approach for text-based emails)
      const emailLines = emailContent.split("\n");

      let date = "";

      emailLines.forEach(line => {
        if (line.startsWith("Date:")) {
          date = line.replace("Date:", "").replace(/,/g, "").replace(/\s\+.*/, "").trim();
        }
      });

      return {
        date
      }
    },

    deleteFile(file){
      this.$confirm.require({
        header: this.$t('swal.deleteFileHeader'),
        message: this.$t('swal.deleteFileText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteS3Object',file.Key).then(() => {
            this.files = this.files.filter(foundFile => foundFile.Key !== file.Key);
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.fileDeleted'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    checkIfFilesNotUploaded(){
      if(this.$refs.fileUpload.files.length > 0) {
        this.$confirm.require({
          header: this.$t('swal.submitWithoutUploadingHeader'),
          message: this.$t('swal.submitWithoutUploadingText'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.submitEdit();
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
          }
        });
      }else{
        this.submitEdit();
      }
    },

    submitEdit(){
      if(this.offer.VisibleTo === "Self") this.offer.Members = [
        {
          Id: this.currentUserData.User,
          Name: this.currentUserData.FriendlyName,
          type:"User"
        }
      ];

      if(this.offer.VisibleTo === "All") this.offer.Members = this.members;

      this.v$.$validate();
      if(!this.v$.$error) {
        this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.offerEdited'), life: 3000 });

        this.$store.dispatch('editOffer',this.offer).then(() => {
          this.$emit('close-popup');
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }

        switch(error.$property){
          case "Company":
            errorList = errorList + ' ' + this.$t('company');
            break;
          case "DeliveryDate":
            errorList = errorList + ' ' + this.$t('deliveryDate');
            break;
          case "OfferDate":
            errorList = errorList + ' ' + this.$t('offerDate')
            break;
          case "Probability":
            errorList = errorList + ' ' + this.$t('probability');
            break;
          case "Title":
            errorList = errorList + ' ' + this.$t('title');
            break;
          case "VisibleTo":
            errorList = errorList + ' ' + this.$t('placeholder.visibleTo');
            break;
        }
      }

      return errorList;
    },

    closeOffer(status){
      this.offer.Closed = new Date().getTime();
      this.offer.Status = status;
      
      this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.offerClosed'), life: 3000 });
      
      this.$store.dispatch('closeOffer',this.offer).then(() => {
        this.closingOffer = false;
        this.$emit('close-popup');
      });
    },

    submitCloseOfferPopup(){
      this.$refs.closeOffer.emitCloseOffer();
    },

    getFiles(){
      this.getS3Objects( 'sales/leadsAndProjects/' + this.projectId + '/offers/' + this.offer.Id + '/').then(res => {
        this.files = res;
      });
    },

    getSupplierOptions(){
      this.$store.commit('setLoading', true);
      try{
        this.$store.dispatch("getRequest","getSuppliers").then(resp => {
          this.$store.commit('setLoading', false);
          const body = JSON.parse(resp.body);
          this.supplierOptions = body.Items.map((item) => ({Name:item.Name, Id:item.Id}));

          this.supplierOptions.sort((a, b) => {
            if (a['Name'] < b['Name']) return -1;
            if (a['Name'] > b['Name']) return 1;
          });
        });
      }catch(err){
        console.log(err);
        this.$store.commit('setLoading', false);
      }
    },
  },

  mounted(){
    this.getFiles();

    // Only get suppliers when we can edit them.
    if(!this.disabled) this.getSupplierOptions();

    this.offerSupplier = {Name: this.offer.Supplier, Id: this.offer.SupplierId};
  },

  watch: {
    offerProp(newVal) {
      if (newVal) {
        this.files = [];
        this.offer = newVal;
      }
    },
  }
}
</script>

<style scoped>

</style>