<template>
  <MaterialTable :data-key="'Id'"
                 :table-id="'companyEquipments'"
                 :style="{'height': windowHeight}"
                 :data-prop="companyEquipments"
                 :columns="columns"
                 :has-paginator="true"
                 :rows-count="10"
                 :has-global-filter="true"
                 :show-toggle-column="true"
                 :filter-prop="filter"
                 :filter-options="filterOptions"
                 :sortable="true"
                 :has-removable-sort="true"
                 :key="tableKey"
                 :selection-mode="'single'"
                 :resizable-columns="true"/>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
export default {
  name: "CompanyMaterialTableEquipmentTable",
  components: {MaterialTable},
  data(){
    return{
      columns:[
        { field: 'EquipmentName', header: this.$t('name') },
        { field: 'Type', header: this.$t('type') },
        { field: 'Year', header: this.$t('year') },
        { field: 'OptionalParams.companyLocation', header: this.$t('location') },
        { field: 'Manufacturer', header: this.$t('manufacturer') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        EquipmentName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Year: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'OptionalParams.companyLocation': { value: null, matchMode: FilterMatchMode.CONTAINS },
        Manufacturer: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['EquipmentName', 'OptionalParams.companyLocation', 'Type', 'Year', 'Manufacturer'],
      tableKey:0,
      windowHeight: '',
    }
  },
  props:{
    companyEquipments:Array,
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>