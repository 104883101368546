<template>
  <div class="col-12 h-full">
    <SmallCard :header="this.$t('persons') + ' & ' + this.$t('contacts')" :use-slot="true" :card-class="'h-full'">
      <div v-for="member of members" :key="member">
        <div class="flex justify-content-between align-items-center flex-column md:flex-row border-bottom-1 surface-border">
          <div class="pb-3 cursor-pointer" @click="goToUser(member)">
            <Avatar class="mr-2 mb-2 mt-2 lg:mb-0" :label="member.Name.substring(0,2).toUpperCase()" shape="circle" />
            <span>{{member.Name}} - {{member.Role}}</span>
          </div>
          <div>
            <PrimeButton icon="pi pi-external-link" text @click="goToUser(member)" />
          </div>
        </div>
      </div>
    </SmallCard>
  </div>

</template>

<script>
import Avatar from "primevue/avatar";
import PrimeButton from "primevue/button";
import SmallCard from "@/components/global-components/card/SmallCard";
export default {
  name: "ProjectDashboardPersons",
  components:{SmallCard, Avatar, PrimeButton},
  props:{
    members:Array,
  },
  methods:{
    /**
     * Goes to the user dashboard of the clicked user.
     * @param member
     */
    goToUser(member){
      this.setLastClickedPersonDashboard(member);
      if(member.type === "Contact"){
        this.$router.push({
          path:"/persons/contactDashboard/" + member.Id,
          params:member.Id,
        });
      }else{
        this.$router.push({
          path:"/persons/dashboard/" + member.Id,
          params:member.Id,
        });
      }
    },

    setLastClickedPersonDashboard(member){
      localStorage.setItem('lastClickedPersonDashboard',JSON.stringify({
        Id:member.Id,
        Name:member.Name,
        Type:member.type,
      }));
    },
  }
}
</script>

<style scoped>

</style>