<template>
  <div>
    <CustomLabel
      class="pt-2 w-full"
      :label="this.$t('type')"
      :input="selectedReportType">
      <Dropdown
        class="w-full"
        v-model="selectedReportType"
        optionLabel="ReportTableReportType"
        :class="v$.selectedReportType.$error ? 'p-invalid' : ''"
        autoFilterFocus
        :options="reportTypes"
        filter
        :placeholder="this.$t('placeholder.type')"  />
    </CustomLabel>
  </div>
</template>

<script>
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import Dropdown from "primevue/dropdown";
import useVuelidate from "@vuelidate/core/dist";
import {required} from "@vuelidate/validators/dist";

export default {
  name: "selectReportType",
  components: {CustomLabel, Dropdown},
  emits:['submitSelectReport'],
  data(){
    return{
      selectedReportType:null,
      reportTypes:[
        {ReportTableReportType:this.$t('visitReport'),ReportTypeString:'besuchsberichtSales'},
        {ReportTableReportType:this.$t('phoneReport'), ReportTypeString:'telefonberichtSales'},
      ],
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      selectedReportType: {required},
    }
  },

  methods:{
    selectReport(){
      this.$emit('submit-select-report',this.selectedReportType);
    }
  }
}
</script>

<style scoped>

</style>