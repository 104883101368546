<template>
  <div>
    <CustomLabel
      class="pt-2 w-full"
      label="Status"
      :input="projectStatus">
      <Dropdown
        class="w-full"
        v-model="projectStatus"
        :options="projectStatusOptions"
        :class="v$.projectStatus.$error ? 'p-invalid' : ''"
        optionLabel="text"
        placeholder="Status"/>
    </CustomLabel>
  </div>
</template>

<script>
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import Dropdown from "primevue/dropdown";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  name: "CloseProject",
  components:{CustomLabel, Dropdown},
  emits: ['closeProject'],
  data(){
    return {
      projectStatus:null,
    }
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  validations(){
    return {
      projectStatus: {required}
    }
  },

  computed:{
    projectStatusOptions(){
      return [
        {text:this.$t('closed'), value:'20'},
        {text:this.$t('cancelled'), value:'30'},
        {text:this.$t('rejected'), value:'40'},
        {text:this.$t('noRealization'), value:'50'},
        {text:this.$t('lost'), value:'60'},
        {text:this.$t('postponed'), value:'70'},
        {text:this.$t('stopped'), value:'80'},
        {text:this.$t('sold'), value:'90'},
      ];
    }
  },
  methods:{
    emitCloseProject(){
      this.v$.$validate();
      if(!this.v$.$error) {
        this.$emit('close-project', this.projectStatus.value);
      }
    }
  }
}
</script>

<style scoped>

</style>