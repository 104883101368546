
const state = {
    offers: [],
};

const getters = {
    offers: (state)=> state.offers,
};

const actions = {
    addOffer(context,offer){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"createOffer",
                "payload":offer,
            }).then(()=> {
                context.commit('addOffer',offer);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    editOffer(context,offer){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"editOffer",
                "payload":offer,
            }).then(()=> {
                context.commit('editOffer',offer);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    deleteOffer(context,offer){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"deleteOffer",
                "payload":offer.Id,
            }).then(()=> {
                context.commit('deleteOffer',offer);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    closeOffer(context,offer){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"closeOffer",
                "payload":offer,
            }).then(()=> {
                context.commit('editOffer',offer);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    reopenOffer(context,offer){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"reopenOffer",
                "payload":offer,
            }).then(()=> {
                context.commit('editOffer',offer);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    }
};

const mutations = {
    setOffers(state,offers){
        state.offers = offers.sort((a, b) => {
            if (a['OfferDate'] > b['OfferDate']) return -1;
            if (a['OfferDate'] < b['OfferDate']) return 1;
        });
    },

    addOffer(state,offer){
        state.offers.push(offer);
    },

    editOffer(state,offer){
        const foundOfferIndex = state.offers.findIndex(foundOffer => foundOffer.Id === offer.Id);
        state.offers[foundOfferIndex] = offer;
    },

    deleteOffer(state,offer){
        state.offers = state.offers.filter(foundOffer => foundOffer.Id !== offer.Id);
    },

    // If we already have offers, we update them when accessing a projectDashboard.
    // In case we start inside a projectDashboard, state.offers is empty and can't be updated,
    // Since we get all updated offers through the getRequest getOffers inside App.vue.
    updateOffers(state,offers){
        for(let offer of offers){
            const foundOfferIndex = state.offers.findIndex(foundOffer => foundOffer.Id === offer.Id);
            if(foundOfferIndex !== -1){
                state.offers[foundOfferIndex] = offer;
            }
        }
    }
};

const offersStore = {
    state,
    getters,
    actions,
    mutations,
}

export default offersStore;