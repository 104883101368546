<template>
  <div class="col-12 h-full">
    <SmallCard 
      :header="this.$t('projectCorrespondences')"
      :use-slot="true" :show-button="projectOpen"
      :card-class="'h-full'"
      :button-icon="'pi pi-plus'"
      :button-label="this.$t('createNewCorrespondence')"
      @button-clicked="createCorrespondence">
      <MaterialTable
        :data-key="'Id'"
        :table-id="'projectCorrespondence'"
        :style="{'height': windowHeight}"
        :data-prop="correspondences"
        :columns="columns"
        :has-paginator="true"
        :rows-count="10"
        :has-global-filter="true"
        :filter-prop="filter"
        :show-delete-button="projectOpen"
        @row-deleted="deleteCorrespondence"
        :show-toggle-column="true"
        :sortable="true"
        :has-removable-sort="true"
        :filter-options="filterOptions"
        :key="tableKey"
        :selection-mode="'single'"
        @row-selected="editCorrespondence"
        :resizable-columns="true"/>
    </SmallCard>
  </div>

  <Popup
    :header="this.$t('correspondence')"
    :modal="true"
    :visible="openCreateCorrespondence"
    styling="width:50%"
    :position="'top'"
    @close-popup="openCreateCorrespondence = false"
    :show-save-button="true"
    @button-clicked="submitCreateCorrespondencePopup">
    <CreateCorrespondence
      ref="createCorrespondence"
      :members="members"
      :project-id="project.Id"
      :company-id="project.Company && project.Company.Id"
      :supplier-id="project.SupplierId"
      @add-correspondence="addCorrespondence"/>
  </Popup>

  <Popup
    :header="this.$t('correspondence')"
    :modal="true"
    :visible="openEditCorrespondence"
    styling="width:50%"
    :position="'top'"
    @close-popup="openEditCorrespondence = false"
    :show-save-button="projectOpen"
    @button-clicked="submitEditCorrespondencePopup">
    <EditCorrespondence
      ref="editCorrespondence"
      :correspondence-prop="clickedCorrespondence"
      :members="members"
      :is-read-only="!projectOpen"
      @correspondence-edited="emitCorrespondenceEdited"
      :company-id="project.Company && project.Company.Id"
      :supplier-id="project.SupplierId"
      :project-id="project.Id"
      @close-popup="openEditCorrespondence = false"/>
      
    <ConfirmDialog id="secondConfirm" />
  </Popup>
</template>

<script>
import Popup from "@/components/global-components/popup/Popup";
import CreateCorrespondence from "@/components/correspondence/create-correspondence/CreateCorrespondence";
import SmallCard from "@/components/global-components/card/SmallCard";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import EditCorrespondence from "@/components/correspondence/edit-correspondence/EditCorrespondence";
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "ProjectCorrespondence",
  components:{EditCorrespondence, SmallCard, CreateCorrespondence, Popup, MaterialTable},
  mixins:[S3FileManager,globalComputedProperties],
  emits:['correspondenceEdited', 'correspondenceDeleted', 'removeReport'],
  data(){
    return{
      clickedCorrespondence:null,
      openCreateCorrespondence: false,
      openEditCorrespondence: false,
      columns:[
        { field: 'Author', header: this.$t('creator') },
        { field: 'Text', header: this.$t('text') },
        { field: 'stage', header: this.$t('assignment') },
        { field: 'Contact.Name', header: this.$t('contact') },
        { field: 'Files', header: this.$t('files') },
        { field: 'Unix', header: this.$t('creationDate') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Author:{value: null, matchMode: FilterMatchMode.CONTAINS},
        Text: { value: null, matchMode: FilterMatchMode.CONTAINS },
        stage: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Contact.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        Files: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Unix: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Author', 'Text', 'stage', 'Contact.Name'],
      tableKey: 0,
      windowHeight: '',
    }
  },
  props:{
    project:Object,
    projectOpen:Boolean,
    members:Array,
    correspondences:Array,
  },

  methods:{
    createCorrespondence(){
      this.openCreateCorrespondence = !this.openCreateCorrespondence
    },

    submitCreateCorrespondencePopup(){
      this.$refs.createCorrespondence.checkIfFilesNotUploaded();
    },

    submitEditCorrespondencePopup(){
      this.$refs.editCorrespondence.checkIfFilesNotUploaded();
    },

    addCorrespondence(correspondence){
      const data = {"project":this.project,"correspondence":correspondence}
      this.$store.commit('addCorrespondence',data);
      this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.correspondenceAdded'), life: 3000 }); //TODO: Quick fix since this somehow throws an error inside the then below.
      this.$store.dispatch('updateCorrespondence',this.project).then(() => {
        this.openCreateCorrespondence = false;
      });
    },

    editCorrespondence(correspondence){
      this.clickedCorrespondence = correspondence;
      this.openEditCorrespondence = true;
    },

    /**
     * Deletes the correspondence.
     * If the correspondence Type is not 'Beitrag',
     * we open a second confirmDialog.
     * @param correspondence
     */
    deleteCorrespondence(correspondence){
      this.$confirm.require({
        header: this.$t('swal.deleteCorrespondenceHeader'),
        message: this.$t('swal.deleteCorrespondenceText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          if(correspondence.Type !== "Beitrag"){
            this.$nextTick(() => {
              this.confirmReportDeletion(correspondence);
            });
          }else{
            this.emitCorrespondenceDeleted(correspondence);
          }
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    /**
     * Warns the user that the linked report will get deleted,
     * when deleting this correspondence.
     * @param correspondence
     */
    confirmReportDeletion(correspondence){
        this.$confirm.require({
          header: this.$t('swal.deleteReportWarningHeader'),
          message: this.$t('swal.deleteReportWarningText'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.deleteReport(correspondence)
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
          }
        });

    },

    /**
     * Deletes the report and fires two emits.
     * @param correspondence
     */
    deleteReport(correspondence){
      this.emitCorrespondenceDeleted(correspondence);
      this.emitReportDeleted(correspondence)
    },

    emitCorrespondenceDeleted(correspondence){
      this.$emit('correspondence-deleted', correspondence);
    },

    emitCorrespondenceEdited(correspondence){
      this.$emit('correspondence-edited',correspondence);
    },

    /**
     * Checks which report type is being deleted and
     * fires an emit which will remove the report locally.
     * @param correspondence
     */
    emitReportDeleted(correspondence){
      this.$emit('remove-report', correspondence.Files[0].path);
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>