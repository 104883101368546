
const state = {
    projects: [],
    favorisedProjects: [],
    closedProjects:null,
    closedProjectDashboard: null,
    openProjectsLastEvaluatedKey:null,
    closedProjectsLastEvaluatedKey:null,
};

const getters = {
    projects: (state)=> state.projects,
    favorisedProjects: (state) => state.favorisedProjects,
    closedProjects: (state) => state.closedProjects,
    closedProjectDashboard: (state) => state.closedProjectDashboard,
    openProjectsLastEvaluatedKey: (state) => state.openProjectsLastEvaluatedKey,
    closedProjectsLastEvaluatedKey: (state) => state.closedProjectsLastEvaluatedKey,
};

const actions = {
    convertLead(context,leadData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"convertLead",
                "payload":leadData,
            }).then(()=> {
                context.commit('deleteLead',leadData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    addProject(context,projectData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"createProject",
                "payload":projectData,
            }).then(()=> {
                context.commit('addProject',projectData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });

    },

    closeProject(context,projectData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"closeProject",
                "payload":projectData,
            }).then(()=> {
                context.commit('editProject',projectData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    editProject(context,projectData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"editProject",
                "payload":projectData,
            }).then(()=> {
                context.commit('editProject',projectData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    deleteProject(context,projectData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"deleteProject",
                "payload":projectData.Id,
            }).then(()=> {
                context.commit('deleteProject',projectData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    reopenProject(context,projectData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"reopenProject",
                "payload":projectData,
            }).then(()=> {
                context.commit('editProject',projectData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    updateCorrespondence(context,project){
        context.commit('setLoading',true);
        const data = {"Id":project.Id, "Correspondence":project.Correspondence};
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest", {
                "param": "updateCorrespondence",
                "payload": data,
            }).then(() => {
                context.commit('editProject',project);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    updateChangelog(context, project) {
        context.commit('setLoading',true);
        const updateChangelogsData = {"Changelogs":project.Changelogs, "Id":project.Id};
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"editProject",
                "payload":project,
            }).then(() => {
                context.dispatch("postRequest",{
                    "param":"updateChangelog",
                    "payload":updateChangelogsData,
                }).then(()=> {
                    context.commit('editProject',project);
                    resolve();
                }).catch((err) => {
                    console.log(err);
                    reject(err);
                }).finally(() => {
                    context.commit('setLoading', false);
                });
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    }
};

const mutations = {
    setOpenProjects(state,projects){
        state.projects = [...state.projects, ...projects].sort((a, b) => {
            if (a['Created'] < b['Created']) return -1;
            if (a['Created'] > b['Created']) return 1;
        });
    },

    // This will reset projects to at max. 200 entries.
    setLatestProjectsData(state, projects){
        state.projects = projects;
    },

    setInitialClosedProjects(state,projects){
        state.closedProjects = projects;
    },

    setClosedProjects(state,projects){
        state.closedProjects = [...state.closedProjects, ...projects];
    },

    setClosedProject(state,project){
        state.closedProjectDashboard = project;
    },

    setFavorisedProjects(state,projects){
        state.favorisedProjects = projects;
    },

    setOpenProjectsLastEvaluatedKey(state, lastEvaluatedKey){
        state.openProjectsLastEvaluatedKey = lastEvaluatedKey;
    },

    setClosedProjectsLastEvaluatedKey(state, lastEvaluatedKey){
        state.closedProjectsLastEvaluatedKey = lastEvaluatedKey;
    },

    addProject(state,projectData){
        state.projects.push(projectData);
    },

    /**
     * Edits the projectData.
     * If project isn't found, we push the project into the store.
     * @param state
     * @param projectData
     */
    editProject(state,projectData){
        const foundProject = state.projects.findIndex(project => project.Id === projectData.Id);
        if(foundProject !== -1){
            state.projects[foundProject] = projectData;
        }else{
            state.projects.push(projectData);
        }
    },

    deleteProject(state,projectData){
        state.projects = state.projects.filter(project => project.Id !== projectData.Id);
    },

    addCorrespondence(state,data){
        const foundProject = state.projects.findIndex(project => project.Id === data.project.Id);
        state.projects[foundProject].Correspondence.push(data.correspondence);
    },
};

const projectsStore = {
    state,
    getters,
    actions,
    mutations,
}

export default projectsStore;