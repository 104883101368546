<template>
  <MaterialTable
    :data-key="'timestamp'"
    :table-id="'personCorrespondences'"
    :style="{'height': windowHeight}"
    :data-prop="correspondences"
    :columns="columns"
    :has-paginator="true"
    :rows-count="10"
    :has-global-filter="true"
    :filter-prop="filter"
    :filter-options="filterOptions"
    :sortable="true"
    :has-removable-sort="true"
    @row-selected="correspondenceClicked"
    :show-delete-button="true"
    @row-deleted="deleteCorrespondence"
    :key="tableKey"
    :selection-mode="'single'"
    :show-add-button="true"
    @open-dialog="creatingCorrespondence = true"
    :resizable-columns="true"/>

  <Popup
    :maximizable="true"
    :header="this.$t('createCorrespondence')"
    :modal="true"
    :visible="creatingCorrespondence"
    :show-save-button="true"
    @button-clicked="submitAddCorrespondencePopup"
    @close-popup="creatingCorrespondence = false"
    styling="width:50%;max-height:70%" >
    <AddCorrespondence 
      ref="addCorrespondence"
      :correspondence-type="$t('contact')"
      :contact-prop="contact"
      @close-popup="creatingCorrespondence = false" />
  </Popup>

  <Popup
    :maximizable="true"
    :header="this.$t('editCorrespondence')"
    :modal="true"
    :visible="editingDashboardCorrespondence"
    :show-save-button="true"
    @button-clicked="submitEditDashboardCorrespondencePopup"
    @close-popup="editingDashboardCorrespondence = false"
    styling="width:50%;max-height:70%" >
    <EditDashboardCorrespondence 
      ref="editDashboardCorrespondence"
      :correspondence-type="correspondenceType"
      :contact-prop="contact"
      :user-prop="user"
      :correspondence-prop="selectedCorrespondence"
      @close-popup="editingDashboardCorrespondence = false" />
  </Popup>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
import AddCorrespondence from "../../../correspondence/add-correspondence/AddCorrespondence.vue";
import EditDashboardCorrespondence from "@/components/correspondence/edit-dashboard-correspondence/EditDashboardCorrespondence";
import Popup from "@/components/global-components/popup/Popup";

export default {
  name: "PersonMaterialTableCorrespondenceTab",
  components:{MaterialTable, Popup, AddCorrespondence, EditDashboardCorrespondence},
  data(){
    return{
      columns:[
        { field: 'text', header: this.$t('description') },
        { field: 'sender', header: this.$t('responsible') },
        { field: 'stage', header: this.$t('assignment') },
        { field: 'file', header: this.$t('files') },
        { field: 'timestamp', header: this.$t('creationDate') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        text: { value: null, matchMode: FilterMatchMode.CONTAINS },
        sender: { value: null, matchMode: FilterMatchMode.CONTAINS },
        stage: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['text', 'sender', 'stage'],
      tableKey:0,
      selectedCorrespondence:null,
      windowHeight: '',
      creatingCorrespondence: false,
      editingDashboardCorrespondence: false,
    }
  },
  props:{
    correspondences:Array,
    correspondenceType:String,
    contact:Object,
    user:Object,
  },

  methods:{
    correspondenceClicked(correspondence){
      this.selectedCorrespondence = correspondence;
      this.editingDashboardCorrespondence = true;
    },

    // updates and sets the correspondence-array of the entity (user/contact).
    updateCorrespondenceArray(entity, correspondence) {
      entity.Correspondence = entity.Correspondence
          .filter(foundCorrespondence => foundCorrespondence.timestamp !== correspondence.timestamp);

      entity.Correspondence = entity.Correspondence.map(obj => ({
        ...obj,
        timestamp: Number(obj.timestamp)
      }));
    },

    deleteCorrespondence(correspondence){
      this.$confirm.require({
        header: this.$t('swal.deleteCorrespondenceHeader'),
        message: this.$t('swal.deleteCorrespondenceText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          const data = {};
          if(this.correspondenceType === this.$t('person')){
            data.Person = this.user;
            this.updateCorrespondenceArray(data.Person, correspondence);
          }else if(this.correspondenceType === this.$t('contact')){
            data.Contact = this.contact;
            this.updateCorrespondenceArray(data.Contact, correspondence);
          }

          this.$store.dispatch('deleteCorrespondence',data).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.correspondenceDeleted'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    submitAddCorrespondencePopup(){
      this.$refs.addCorrespondence.checkIfFilesNotUploaded();
    },

    submitEditDashboardCorrespondencePopup(){
      this.$refs.editDashboardCorrespondence.checkIfFilesNotUploaded();
    }
  },
   
  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>