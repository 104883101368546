
const state = {
    leads: [],
    closedLeads:null,
    openLeadsLastEvaluatedKey:null,
    closedLeadsLastEvaluatedKey:null
};

const getters = {
    leads: (state)=> state.leads,
    closedLeads: (state) => state.closedLeads,
    openLeadsLastEvaluatedKey: (state) => state.openLeadsLastEvaluatedKey,
    closedLeadsLastEvaluatedKey: (state) => state.closedLeadsLastEvaluatedKey,
};

const actions = {
    addLead(context,leadData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"createLead",
                "payload":leadData,
            }).then(()=> {
                context.commit('addLead',leadData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    editLead(context,leadData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"editLead",
                "payload":leadData,
            }).then(()=> {
                context.commit('editLead',leadData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    closeLead(context,leadData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"closeLead",
                "payload":leadData,
            }).then(()=> {
                context.commit('editLead',leadData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    deleteLead(context, leadData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"deleteLead",
                "payload":leadData.Id,
            }).then(()=> {
                context.commit('deleteLead',leadData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },

    reopenLead(context,leadData){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"reopenLead",
                "payload":leadData,
            }).then(()=> {
                context.commit('editLead',leadData);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    }
};

const mutations = {
    setLeadsData(state,leads){
        state.leads = [...state.leads, ...leads].sort((a, b) => {
            if (a['Title'] < b['Title']) return -1;
            if (a['Title'] > b['Title']) return 1;
        });
    },

    // This will reset leads to at max. 200 entries.
    setLatestLeadsData(state, leads){
        state.leads = leads;
    },

    setInitialClosedLeads(state,leads){
        state.closedLeads = leads;
    },

    setClosedLeads(state,leads){
        state.closedLeads = [...state.closedLeads, ...leads];
    },

    setOpenLeadsLastEvaluatedKey(state, lastEvaluatedKey){
        state.openLeadsLastEvaluatedKey = lastEvaluatedKey;
    },

    setClosedLeadsLastEvaluatedKey(state, lastEvaluatedKey){
        state.closedLeadsLastEvaluatedKey = lastEvaluatedKey;
    },

    addLead(state,leadData){
        state.leads.push(leadData);
    },

    editLead(state,leadData){
        let foundLead = state.leads.findIndex(lead => lead.Id === leadData.Id);
        let lead = state.leads.filter(lead => lead.Id === leadData.Id)[0];
        if(lead){
            state.leads[foundLead] = leadData;
        }else{
            state.leads.push(leadData);
        }
    },

    deleteLead(state,leadData){
        state.leads = state.leads.filter(lead => lead.Id !== leadData.Id);
    },
};

const leadsStore = {
    state,
    getters,
    actions,
    mutations,
}

export default leadsStore;