<template>
  <div class="col-12">
    <TabView :activeIndex="activeIndex">
      <TabPanel :header="this.$t('leads') + ' (' + totalLeadsLength + ')'">
        <PersonMaterialTableLeadTab :leads="leads"
                                    :closed-leads="closedLeads"
                                    :show-load-more-leads="showLoadMoreLeads"
                                    :show-load-more-closed-leads="showLoadMoreClosedLeads"
                                    @lead-clicked="emitRowClicked"
                                    @load-more-data="emitLoadMoreData" />
      </TabPanel>

      <TabPanel :header="this.$t('projects') + ' (' + totalProjectsLength + ')'">
        <PersonMaterialTableProjectTab :projects="projects"
                                       :closed-projects="closedProjects"
                                       :show-load-more-projects="showLoadMoreProjects"
                                       :show-load-more-closed-projects="showLoadMoreClosedProjects"
                                       :is-user="true"
                                       @load-more-data="emitLoadMoreData" />
      </TabPanel>

      <TabPanel :header="this.$t('tasks') + ' (' + tasks.length + ')'">
        <PersonMaterialTableTaskTab :tasks="tasks"
                                    @task-clicked="emitRowClicked" />
      </TabPanel>

      <TabPanel :header="this.$t('offers') + ' (' + offers.length + ')'">
        <PersonMaterialTableOfferTab :offers="offers"
                                     @offer-clicked="emitRowClicked" />
      </TabPanel>

      <TabPanel :header="this.$t('sales') + ' (' + sales.length + ')'">
        <PersonMaterialTableSaleTab :sales="sales"
                                    :show-load-more-sales="showLoadMoreSales"
                                    @load-more-data="emitLoadMoreData"
                                    @sale-clicked="emitRowClicked" />
      </TabPanel>

      <TabPanel :header="this.$t('correspondence') + ' (' + correspondencesLength + ')'">
        <PersonMaterialTableCorrespondenceTab :correspondences="correspondences"
                                              :correspondence-type="$t('person')"
                                              :user="person"
                                              @correspondence-clicked="emitRowClicked" />
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import PersonMaterialTableLeadTab
  from "@/components/persons/person-material-table/person-material-table-lead-tab/PersonMaterialTableLeadTab";
import PersonMaterialTableProjectTab
  from "@/components/persons/person-material-table/person-material-table-project-tab/PersonMaterialTableProjectTab";
import PersonMaterialTableTaskTab
  from "@/components/persons/person-material-table/person-material-table-task-tab/PersonMaterialTableTaskTab";
import PersonMaterialTableOfferTab
  from "@/components/persons/person-material-table/person-material-table-offer-tab/PersonMaterialTableOfferTab";
import PersonMaterialTableSaleTab
  from "@/components/persons/person-material-table/person-material-table-sale-tab/PersonMaterialTableSaleTab";
import PersonMaterialTableCorrespondenceTab
  from "@/components/persons/person-material-table/person-material-table-correspondence-tab/PersonMaterialTableCorrespondenceTab";

export default {
  name: "PersonMaterialTable",
  components:{
    PersonMaterialTableSaleTab,
    PersonMaterialTableCorrespondenceTab,
    PersonMaterialTableOfferTab, PersonMaterialTableTaskTab, PersonMaterialTableProjectTab, PersonMaterialTableLeadTab, TabView, TabPanel},
  emits:['rowClicked','loadMoreData'],
  props:{
    activeIndex:Number,
    leads:Array,
    projects:Array,
    tasks:Array,
    offers:Array,
    sales:Array,
    person:Object,
    correspondences:Array,
    correspondencesLength:Number,
    closedLeads:Array,
    closedProjects:Array,
    showLoadMoreLeads:Object,
    showLoadMoreClosedLeads:Object,
    showLoadMoreProjects:Object,
    showLoadMoreClosedProjects:Object,
    showLoadMoreSales:Object,
  },
  computed:{
    totalProjectsLength(){
      return this.projects.length + this.closedProjects.length;
    },

    totalLeadsLength(){
      return this.leads.length + this.closedLeads.length;
    },
  },

  methods:{
    emitRowClicked(data){
      this.$emit('row-clicked',data);
    },

    emitLoadMoreData(param){
      this.$emit('load-more-data',param);
    }
  },
}
</script>

<style scoped>

</style>