<template>
  <TabView>
    <TabPanel :header="$t('searchCompany')">
      <div class="w-full" style="min-height:30vh">
        <SearchableSelectRow :show-table="true"
                             :search-option="$t('company')"
                             :placeholder="this.$t('placeholder.searchCompany')"
                             @selection-changed="openCompanyDashboard" />
      </div>
    </TabPanel>
    <TabPanel :header="$t('favorites') + ' (' + favoriteCompanies.length + ')'">
        <MaterialTable :data-key="'Id'"
                       :table-id="'favoriteCompanies'"
                       :style="{'height': windowHeight}"
                       :data-prop="favoriteCompanies"
                       :columns="columns"
                       :has-paginator="true"
                       :rows-count="10"
                       :has-global-filter="true"
                       :filter-prop="filter"
                       :filter-options="filterOptions"
                       :sortable="true"
                       :has-removable-sort="true"
                       :show-toggle-column="true"
                       :key="tableKey"
                       :selection-mode="'single'"
                       @row-selected="openCompanyDashboard"
                       :resizable-columns="true"/>
    </TabPanel>
  </TabView>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import SearchableSelectRow from "@/components/global-components/searchable-select-row/SearchableSelectRow";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "CompaniesComponent",
  components:{MaterialTable, SearchableSelectRow, TabView, TabPanel},
  data(){
    return{
      columns:[
        { field: 'Name', header: this.$t('name') },
        { field: 'CompanyNumber', header: this.$t('companyNumber') },
        { field: 'Address', header: this.$t('address') },
        { field: 'City', header: this.$t('location') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CompanyNumber: { value: null, matchMode: FilterMatchMode.CONTAINS},
        Address: { value: null, matchMode: FilterMatchMode.CONTAINS },
        City: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Name', 'CompanyNumber', 'Address', 'City'],
      projectId: null,
      selectedTask:null,
      tableKey:0,
      windowHeight:'',
    }
  },
  computed:{
    favoriteCompanies(){
      return JSON.parse(localStorage.getItem("favorisedCompanies")) || [];
    },
  },

  methods:{
    /**
     * Routes to the clicked company dashboard.
     * @param item
     */
    openCompanyDashboard(item){
      this.$router.push({
        path: '/companies/dashboard/:Id',
        name: 'Company Dashboard',
        params: item,
      });
    },
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.66 + 'px';
  }
}
</script>

<style scoped>
:deep(.p-tabview-panels){
  padding-left:0 !important;
  padding-right:0 !important;
}
</style>