<template>
  <div v-if="showCompleteHeader" class="flex justify-content-between">
    <div class="flex align-items-start flex-column md:flex-row">
      <div class="mr-5 mb-3 lg:mb-0" style="width: 90px;height: 90px">
        <i style="font-size: 4rem" class="text-white-500 pi pi-fw pi-briefcase"></i>
      </div>
      <div>
        <span class="text-900 font-medium text-3xl">{{project.Title}}</span>
        <div class="flex align-items-center flex-wrap text-sm">
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('status')}}</span>
            <div>{{project.Status  + ' (' + getProjectStatusText() + ')'}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">Phase</span>
            <div :class="project.Status === '10' ? 'text-blue-300 cursor-pointer' : ''" @click="emitChangePhase">{{project.Phase ? project.Phase.Name : ''}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('responsible')}}</span>
            <div>{{project.Responsible.Name}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('company')}}</span>
            <div class="text-blue-300 cursor-pointer" @click="goToCompanyDashboard">{{project.Company.Name}} ({{project.Company.CompanyNumber}})</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{ $t('supplier') }}</span>
            <div class="text-blue-300 cursor-pointer" @click="goToSupplierDashboard">{{project.Supplier ? project.Supplier : '-'}}</div>
          </div>
          <div v-if="project.Location" class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('location')}}</span>
            <div>{{project.Location.LocationName}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('contact')}}</span>
            <div class="text-blue-300 cursor-pointer" @click="goToContactDashboard">{{project.Contact?.Name ? project.Contact.Name : "-"}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('number')}}</span>
            <div>{{project.CreationNumber ? project.CreationNumber : "-"}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('budget')}}</span>
            <div>{{project.Budget ? project.Budget : "-"}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('created')}}</span>
            <div>{{new Date(Number(project.Created)).toLocaleDateString()}}</div>
          </div>
          <div v-if="project.LeadConversionDate && Number(project.LeadConversionDate) !== 0" class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('converted')}}</span>
            <div>{{new Date(Number(project.LeadConversionDate)).toLocaleDateString()}}</div>
          </div>
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('description')}}</span>
            <div class="text-blue-300 cursor-pointer" @click="showDescription">{{$t('showDescription')}}</div>
          </div>
        </div>
        <div class="flex align-items-center flex-wrap text-sm">
          <div class="mr-5 mt-3">
            <span class="font-medium text-500">{{$t('classification')}}</span>
            <div>{{project.ProjectTagContainer && project.ProjectTagContainer.ContainerName ? project.ProjectTagContainer.ContainerName : '-'}}</div>
          </div>
          <div v-for="(value, tag) in project.ProjectTags" class="mr-5 mt-3" :key="tag">

            <span class="font-medium text-500">{{getTagLabelName(tag)}}</span>
            <div style="max-width: 250px; overflow-x: auto; white-space: nowrap;">{{value ? value : '-'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="project.Status === '10'">
      <PrimeButton v-if="projectIsFavorite" icon="pi pi-star-fill" severity="contrast" plain text @click="unfavoriseProject" />
      <PrimeButton v-else icon="pi pi-star" severity="contrast" plain text @click="favoriseProject" />
      <PrimeButton icon="pi pi-ellipsis-h" severity="contrast" plain text @click="openContextMenu" aria-haspopup="true" aria-controls="overlay_menu" />
      <PrimeMenu :model="menuOptions" :popup="true" ref="menu" id="overlay_menu"/>
      <PrimeButton icon="pi pi-angle-up" severity="contrast" rounded @click="changeShowCompleteHeader(false)" />
    </div>
    <div v-else>
      <PrimeButton icon="pi pi-check" severity="contrast" :label="$t('reopen')" plain  @click="reopenProject" />
    </div>
  </div>

  <div v-else>
    <div class="flex justify-content-between flex-column md:flex-row">
      <div>
        <span class="text-900 font-medium text-3xl">{{project.Title}}</span>
      </div>
      <div>
        <div v-if="project.Status === '10'">
          <PrimeButton v-if="projectIsFavorite" icon="pi pi-star-fill" severity="contrast" plain text @click="unfavoriseProject" />
          <PrimeButton v-else icon="pi pi-star" severity="contrast" plain text @click="favoriseProject" />
          <PrimeButton icon="pi pi-ellipsis-h" severity="contrast" plain text @click="openContextMenu" aria-haspopup="true" aria-controls="overlay_menu2" />
          <PrimeMenu :model="menuOptions" :popup="true" ref="menu" id="overlay_menu2"/>
          <PrimeButton icon="pi pi-angle-down" severity="contrast" rounded @click="changeShowCompleteHeader(true)" />
        </div>
        <div v-else>
          <PrimeButton icon="pi pi-check" severity="contrast" :label="$t('reopen')" plain  @click="reopenProject" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PrimeButton from "primevue/button";
import PrimeMenu from "primevue/menu";

export default {
  name: "ProjectDashboardHeader",
  components:{PrimeButton, PrimeMenu},
  emits: ['favoriseProject','unfavoriseProject','reopenProject', 'showCards', 'showDescription'],
  props:{
    isScrollingDown:Boolean,
    project:Object,
    menuOptions:Array,
    projectIsFavorite:Boolean,
  },

  data(){
    return {
      showCompleteHeader:true,
    }
  },
  computed:{
    customLists(){
      return this.$store.getters.customLists;
    }
  },
  methods:{
    openContextMenu(event){
      this.$refs.menu.toggle(event);
    },

    favoriseProject(){
      this.$emit('favorise-project');
    },

    unfavoriseProject(){
      this.$emit('unfavorise-project');
    },

    reopenProject(){
      this.$emit('reopen-project');
    },

    changeShowCompleteHeader(trueOrFalse){
      this.showCompleteHeader = trueOrFalse;
      this.$emit('show-cards',trueOrFalse);
    },

    showDescription(){
      this.$emit('show-description');
    },

    emitChangePhase(){
      if(this.project.Status === '10') this.$emit('change-phase');
    },

    getTagLabelName(tagId){
      if(this.customLists && this.customLists.length > 0){
        return this.customLists.find((tag) => tag.Id === tagId).ClusterName;
      }
      return '-';
    },

    getProjectStatusText(){
      switch (this.project.Status){
        case '10':
          return this.$t('open');
        case '20':
          return this.$t('closed');
        case '30':
          return this.$t('cancelled');
        case '40':
          return this.$t('rejected');
        case '50':
          return this.$t('noRealization');
        case '60':
          return this.$t('lost');
        case '70':
          return this.$t('postponed');
        case '80':
          return this.$t('stopped');
      }
    },

    goToCompanyDashboard(){
      this.$router.push({
        path:"/companies/dashboard/" + this.project.Company.Id,
        params:this.project.Company.Id,
      });
    },

    goToSupplierDashboard(){
      this.$router.push({
        path:"/companies/dashboard/" + this.project.SupplierId,
        params:this.project.SupplierId,
      });
    },

    goToContactDashboard(){
      const contact = this.project.Contact;
      if(contact && contact.Id){
        this.$router.push({
          path:"/persons/contactDashboard/" + contact.Id,
          params:contact.Id,
        });
      }
    }
  },
}
</script>

<style scoped>

</style>