<template>
  <div>
    <div class="flex justify-content-between gap-2 mb-2">
      <Dropdown v-model="projectData.Phase" :options="projectPhases" optionLabel="Name" />
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";

export default {
  name: "EditPhase",
  components:{Dropdown},
  emits: ['changePhase'],
  props:{
    project:Object,
  },
  data(){
    return{
      projectData:JSON.parse(JSON.stringify(this.project))
    }
  },
  computed:{
    projectPhases(){
      return this.$store.getters.projectPhases;
    }
  },
  methods:{
    emitChangePhase(){
      this.$emit('change-phase',this.projectData);
    }
  }
}
</script>

<style scoped>

</style>