<template>
  <MaterialTable :data-key="'Id'"
                 :table-id="'companyUsers'"
                 :style="{'height': windowHeight}"
                 :data-prop="companyUsersData"
                 :columns="columns"
                 @row-selected="goToUser"
                 :has-paginator="true"
                 :rows-count="10"
                 :has-global-filter="true"
                 :show-toggle-column="true"
                 :filter-prop="filter"
                 :filter-options="filterOptions"
                 :sortable="true"
                 :has-removable-sort="true"
                 :key="tableKey"
                 :selection-mode="'single'"
                 :resizable-columns="true"/>
</template>

<script>
import {FilterMatchMode} from "primevue/api";
import MaterialTable from "@/components/global-components/material-table/MaterialTable";

export default {
  name: "CompanyMaterialTableUserTab",
  components:{MaterialTable},
  props:{
    companyUsers:Array,
  },
  data(){
    return{
      companyUsersData:JSON.parse(JSON.stringify(this.companyUsers)),
      columns:[
        { field: 'Name', header: this.$t('name') },
        { field: 'Department', header: this.$t('department') },
        { field: 'Position', header: this.$t('position') },
        { field: 'Mobile', header: this.$t('mobile') },
        { field: 'Type', header: this.$t('type') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Position: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Mobile: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Name', 'Department', 'Position', 'Mobile', 'Type'],
      tableKey:0,
      windowHeight: '',
    }
  },
  methods:{
    goToUser(person){
      if(person.Type === "Contact"){
        this.$router.push({
          path:"/persons/contactDashboard/" + person.Id,
          params:person.Id,
        });
      }else{
        this.$router.push({
          path:"/persons/dashboard/" + person.Id,
          params:person.Id,
        });
      }
    },
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>