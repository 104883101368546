<template>
  <TabView>
    <TabPanel :header="$t('searchPerson')">
      <div v-if="lastClickedPerson" class="flex justify-content-center mb-3">
        <span>{{$t('lastVisitedPerson')}}:</span>
        <span class="cursor-pointer ml-2 text-white-500"
              @click="openPersonDashboard(lastClickedPerson)">
          {{lastClickedPerson.Name}}
        </span>
      </div>
      <div class="w-full" style="min-height:30vh" >
        <SearchableSelectRow :show-table="true"
                             :search-option="$t('person')"
                             :placeholder="$t('placeholder.searchPerson')"
                             @selection-changed="openPersonDashboard" />
      </div>
    </TabPanel>

    <TabPanel :header="$t('favorites') + ' (' + favoritePersons.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'favoritePersons'"
                     :style="{'height': windowHeight}"
                     :data-prop="favoritePersons"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :filter-prop="filter"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     @row-selected="openPersonDashboard"
                     :resizable-columns="true"/>
    </TabPanel>
  </TabView>
</template>

<script>
import SearchableSelectRow from "@/components/global-components/searchable-select-row/SearchableSelectRow";
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "PersonsComponent",
  components:{SearchableSelectRow, MaterialTable, TabView, TabPanel},
  data(){
    return{
      columns:[
        { field: 'FriendlyName', header: this.$t('name') },
        { field: 'CompanyName', header: this.$t('company') },
        { field: 'Position', header: 'Position' },
        { field: 'Department', header: this.$t('department') },
        { field: 'Type', header: this.$t('type') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        FriendlyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CompanyName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Position: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      lastClickedPerson: JSON.parse(localStorage.getItem('lastClickedPersonDashboard')) || {},
      tableKey:0,
      windowHeight: '',
    }
  },
  computed:{
    favoritePersons(){
      return JSON.parse(localStorage.getItem("favorisedPersons")) || [];
    },
  },
  methods:{
    openPersonDashboard(params) {
      if(params && params.Type){
        if(params.Type === 'Contact'){
          this.$router.push({
            path: '/persons/contactDashboard/:Id',
            name: 'Contact Dashboard',
            params: params,
          });
        }else{
          this.$router.push({
            path: '/persons/dashboard/:Id',
            name: 'Person Dashboard',
            params: params,
          });
        }
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.errorWhenRoutingToUser'), life: 5000 });
      }
    },

    setLastClickedPerson(person){
      this.lastClickedPerson = person;
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.66 + 'px';
  }
}
</script>

<style scoped>
:deep(.p-tabview-panels){
  padding-left:0 !important;
  padding-right:0 !important;
}
</style>