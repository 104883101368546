<template>
  <div>
    <!-- Toast & ConfirmDialog are implemented in App to be used in the entire project,
     otherwise we would need to import it for every component it is used.
     -->
    <Toast class="sm:width:150px" />
    <Toast group="bl" position="bottom-left" />
    <ConfirmDialog />
    <div v-if="isLoggedIn" class="navbar">
      <Navbar :navbar-header="navbarHeader"/>
    </div>
    <div v-if="isLoggedIn" style="padding-left: 10px;">
      <div class="flex justify-content-between align-items-center">
        <Breadcrumb :home="home" :model="items" />
      </div>
    </div>
    <!-- remove- & add-p2-class are only needed for ProjectDashboard, where the padding would obscure the sticky header.
         Not allowed to write this comment inside keep-alive or we receive a big error. :) -->
    <div class="m-2 overflow-y-auto" :class="{'p-2': showP2Class}" style="max-height: 85vh">
      <router-view v-slot="{Component}">
        <KeepAlive include="LeadsComponent,
          ProjectsComponent,
          SearchableSelectRow">
          <component :is="Component" @remove-p2-class="showP2Class = false" @add-p2-class="showP2Class = true"/>
        </KeepAlive>
      </router-view>
    </div>
  </div>

  <LoaderComponent :show="loading" />

</template>

<script>
import Navbar from "@/components/global-components/navbar/Navbar";
import Loader from "@/components/loader/Loader";
import Toast from "primevue/toast/Toast.vue";
import ConfirmDialog from "primevue/confirmdialog/ConfirmDialog.vue";
import { usePrimeVue } from "primevue/config";
import Breadcrumb from "primevue/breadcrumb/Breadcrumb.vue";
import PrimeButton from "primevue/button/Button.vue";
import {Auth} from "aws-amplify";
import getStoreData from "@/mixins/get-store-data/getStoreData";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import {L10n, setCulture} from "@syncfusion/ej2-base";
import {setTranslatedSyncfusionStrings} from "@/locales/set-translated-syncfusion-strings";
import Uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import LoaderComponent from "@/components/loader/Loader";

export default {
  components: {LoaderComponent, Navbar, Loader, Toast, ConfirmDialog, Breadcrumb ,PrimeButton},
  mixins:[getStoreData,S3FileManager, Uuidv4Generator],
  created(){
    this.$i18n.locale = localStorage.getItem('lang') ? localStorage.getItem('lang') :"de";
  },

  data(){
    return{
      currentRoute: "/leads",
      currentRouteName : "Leads",
      home:{
        icon: 'pi pi-home',
        to: '/',
      },
      navbarHeader:"4Sales",
      showP2Class:true,
    }
  },

  //DO NOT REMOVE
  watch:{
    $route (to){
      this.currentRoute = to.path;
      if(to.name === "Project Dashboard"){
        this.currentRouteName = this.$t(to.name.toLowerCase()) + ' (' + this._getProjectDashboardById(to.params.Id) + ')';
      }else if(to.name === "Company Dashboard"){
        this.currentRouteName = this.$t(to.name.toLowerCase());
      }else if(to.name === "Person Dashboard"){
        this.currentRouteName = this.$t(to.name.toLowerCase());
      }else if(to.name === "Contact Dashboard"){
        this.currentRouteName = this.$t(to.name.toLowerCase());
      }else{
        this.currentRouteName = this.$t(to.name.toLowerCase());
      }
    },
    companyDashboard: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.currentRouteName = this.currentRouteName + ' (' + this._getCompanyDashboardById() + ')'
        }
      },
    },

    userDashboard: {
      immediate: true,
      handler(newValue) {
        if (newValue && newValue.User) {
          this.currentRouteName = this.currentRouteName + ' (' + this._getPersonDashboardById() + ')';
        } else if(newValue && newValue.ContactId){
          this.currentRouteName = this.currentRouteName + ' (' + this._getContactDashboardById() + ')';
        }
      },
    },
  },

  computed:{
    // these two are needed to set the currentRouteName for the dashboards.
    companyDashboard(){
      return this.$store.getters.companyDashboard;
    },

    userDashboard(){
      return this.$store.getters.userDashboard;
    },

    loading(){
      return this.$store.getters.loading;
    },

    isLoggedIn(){
      return this.$store.getters.username && this.$store.getters.jwtToken !== null;
    },

    projects(){
      return this.$store.getters.projects;
    },
    
    items(){
      if(this.currentRouteName){
        if(this.currentRouteName.includes(this.$t('project dashboard'))) return [{label:this.$t('projects'),to:'/projects'},{label:this.currentRouteName,to:this.currentRoute}];
        if(this.currentRouteName.includes(this.$t('company dashboard'))) return [{label:this.$t('companies'),to:'/companies'},{label:this.currentRouteName,to:this.currentRoute}];
        if(this.currentRouteName.includes(this.$t('person dashboard'))) return [{label:this.$t('persons'),to:'/persons'},{label:this.currentRouteName,to:this.currentRoute}];
        if(this.currentRouteName.includes(this.$t('contact dashboard'))) return [{label:this.$t('persons'),to:'/persons'},{label:this.currentRouteName,to:this.currentRoute}];
      }

      return[
        {label: this.currentRouteName, to:this.currentRoute}
      ]
    },

    dayNames(){
      const ger = ['Sonntag',"Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag"];
      const eng = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return this.$i18n.locale === "de" ? ger : eng;
    },

    dayNamesMin(){
      const ger = ["So","Mo","Di","Mi","Do","Fr","Sa"];
      const eng = ["Su","Mo","Tu","We","Th","Fr","Sa"];
      return this.$i18n.locale === "de" ? ger : eng;
    },

    monthNames(){
      const ger = ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"];
      const eng = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      return this.$i18n.locale === "de" ? ger : eng;
    },

    monthNamesShort(){
      const ger = ["Jan","Feb","Mär","Apr","Mai","Jun","Jul","Aug","Sep","Okt","Nov","Dez"];
      const eng = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
      return this.$i18n.locale === "de" ? ger : eng;
    }
  },

  methods:{
    _getProjectDashboardById(projectId){
      if(this.projects.length > 0){
        const foundProject = this.projects.filter(project => project.Id === projectId)[0];
        if(foundProject){
          return foundProject.Title;
        }
        return "-";
      }
      return "-";
    },

    _getCompanyDashboardById(){
      const companyDashboard = this.$store.getters.companyDashboard;
      if(companyDashboard){
        return companyDashboard.Name;
      }
      return "-";
    },

    _getPersonDashboardById(){
      const userDashboard = this.$store.getters.userDashboard;
      if(userDashboard && userDashboard.User){
        return userDashboard.FriendlyName;
      }
      return "-";
    },

    _getContactDashboardById(){
      const userDashboard = this.$store.getters.userDashboard;
      if(userDashboard && userDashboard.ContactId){
        return userDashboard.ContactName;
      }
      return "-";
    },

    /**
     * If tasks are due we set our notifications.
     * Currently, this is only local.
     */
    checkIfTasksAreDue(){
      const tasks = this.$store.getters.tasks;
      const dueTasks = tasks.filter(task => task.ExpiresAt < new Date().getTime() &&
          this.$store.getters.shortUsername === task.Responsible.Name);
      const notifications = [];
      for(let dueTask of dueTasks){
        if(!dueTask.BlockNotifications){
          notifications.push({
            id: dueTask.Id,
            text:this.$t('task') + ' "' + dueTask.Title + '" ' + this.$t('expiredSince') +
                ' ' + new Date(Number(dueTask.ExpiresAt)).toLocaleDateString(),
            projectId:dueTask.ProjectId,
          });
        }
      }
      this.$store.commit('setNotifications',notifications);
    }
  },

  mounted() {
    Auth.currentAuthenticatedUser().then(user => {
      if(user){
        this.$store.dispatch('getCredentialsFromLocalStorage');
        this.$store.dispatch('getRequest',"hasSalesLicense").then((data) => {
          if(data.body === "true"){
            this.getStoreData().then(() => {
              this.checkIfTasksAreDue()
            });
            this.configureS3WithStorage();
          }else{
            Auth.signOut();
            this.$store.dispatch('signout');
            this.$router.push({
              path:'/login',
              name: 'Login',
            });
          }
        })
      }else{
        Auth.signOut();
        this.$store.dispatch('signout');
        this.$router.push({
          path:'/login',
          name: 'Login',
        });
      }
    }).catch(err => {
      Auth.signOut();
      this.$store.dispatch('signout');
      this.$router.push({
        path:'/login',
        name: 'Login',
      });
      console.log(err)
    })

    const lang = this.$i18n.locale === 'de' ? 'de-DE' : 'en-EN';
    setCulture(lang);
    L10n.load(setTranslatedSyncfusionStrings);

    /**
     * Changing the config locales from primeVue;
     * @type {{config: PrimeVueConfiguration}}
     */
    const primeVue = usePrimeVue();
    primeVue.config.locale.startsWith = this.$t('prime.config.startsWith');
    primeVue.config.locale.endsWith = this.$t('prime.config.endsWith');
    primeVue.config.locale.contains = this.$t('prime.config.contains');
    primeVue.config.locale.notContains = this.$t('prime.config.notContains');
    primeVue.config.locale.equals = this.$t('prime.config.equals');
    primeVue.config.locale.notEquals = this.$t('prime.config.notEquals');
    primeVue.config.locale.noFilter = this.$t('prime.config.noFilter');
    primeVue.config.locale.clear = this.$t('prime.config.clear');
    primeVue.config.locale.today = this.$t('prime.config.today');
    primeVue.config.locale.accept = this.$t('prime.config.accept');
    primeVue.config.locale.reject = this.$t('prime.config.reject');
    primeVue.config.locale.upload = this.$t('prime.config.upload');
    primeVue.config.locale.choose = this.$t('prime.config.choose');
    primeVue.config.locale.cancel = this.$t('prime.config.cancel');

    primeVue.config.locale.dayNames = this.dayNames;
    primeVue.config.locale.dayNamesMin = this.dayNamesMin;
    primeVue.config.locale.monthNames = this.monthNames;
    primeVue.config.locale.monthNamesShort = this.monthNamesShort;
  }

}
</script>

<style scoped>
.navbar{
  padding-left:10px;
  padding-right:30px;
}

@media only screen and (max-width: 600px){
  .navbar{
    padding-left:0;
    padding-right:0;
  }
}
</style>

<style>

@import "../node_modules/@syncfusion/ej2-base/styles/tailwind-dark.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/tailwind-dark.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/tailwind-dark.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/tailwind-dark.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/tailwind-dark.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/tailwind-dark.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/tailwind-dark.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/tailwind-dark.css";
@import "../node_modules/@syncfusion/ej2-vue-querybuilder/styles/tailwind-dark.css";

@import "../node_modules/@syncfusion/ej2-navigations/styles/tailwind-dark.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/tailwind-dark.css";
@import "../node_modules/@syncfusion/ej2-vue-grids/styles/tailwind-dark.css";

@import "../node_modules/@syncfusion/ej2-documenteditor/styles/tailwind-dark.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  margin:0;
}

.p-menubar {
  background: transparent;
  border: 0px transparent;
}

.p-breadcrumb{
  border:none!important;
  background: transparent;
}

.navbar a {
  font-weight: bold;
}

a {
  color: var(--blue-300);
}

.p-calendar .p-datepicker-trigger {
  background-color: white;
  color: #1f2937;
  border-color: white;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #007AC2;
}

@media screen and (max-width: 820px){
  .p-toast {
    max-width: calc(100vw - 40px);
  }
}
</style>