<template>
  <div class="col-12">
    <TabView :activeIndex="activeIndex">
      <TabPanel :header="this.$t('leads') + ' (' + leads.length + ')'">
        <PersonMaterialTableLeadTab :leads="leads"
                                    :closedLeads="closedLeads"
                                    :show-load-more-leads="showLoadMoreLeads"
                                    :show-load-more-closed-leads="showLoadMoreClosedLeads"
                                    @lead-clicked="emitRowClicked"
                                    @load-more-data="emitLoadMoreData"/>
      </TabPanel>

      <TabPanel :header="this.$t('projects') + ' (' + projects.length + ')'">
        <PersonMaterialTableProjectTab :projects="projects"
                                       :closedProjects="closedProjects"
                                       :show-load-more-projects="showLoadMoreProjects"
                                       :show-load-more-closed-projects="showLoadMoreClosedProjects"
                                       :is-user="false"
                                       @load-more-data="emitLoadMoreData" />
      </TabPanel>

      <TabPanel :header="this.$t('offers') + ' (' + offers.length + ')'">
        <PersonMaterialTableOfferTab :offers="offers"
                                     @offer-clicked="emitRowClicked"/>
      </TabPanel>

      <TabPanel :header="this.$t('sales') + ' (' + sales.length + ')'">
        <PersonMaterialTableSaleTab :sales="sales"
                                    :show-load-more-sales="showLoadMoreSales"
                                    @load-more-data="emitLoadMoreData"
                                    @sale-clicked="emitRowClicked"/>
      </TabPanel>

      <TabPanel :header="this.$t('correspondence') + ' (' + contact.Correspondence.length + ')'">
        <PersonMaterialTableCorrespondenceTab :correspondences="contact.Correspondence"
                                              :contact="contact"
                                              :correspondence-type="$t('contact')"
                                              @correspondence-clicked="emitRowClicked"/>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import PersonMaterialTableLeadTab from "@/components/persons/person-material-table/person-material-table-lead-tab/PersonMaterialTableLeadTab";
import PersonMaterialTableProjectTab from "@/components/persons/person-material-table/person-material-table-project-tab/PersonMaterialTableProjectTab";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import PersonMaterialTableOfferTab
  from "@/components/persons/person-material-table/person-material-table-offer-tab/PersonMaterialTableOfferTab";
import PersonMaterialTableSaleTab
  from "@/components/persons/person-material-table/person-material-table-sale-tab/PersonMaterialTableSaleTab";
import PersonMaterialTableCorrespondenceTab
  from "@/components/persons/person-material-table/person-material-table-correspondence-tab/PersonMaterialTableCorrespondenceTab";
export default {
  name: "ContactMaterialTable",
  components: {
    PersonMaterialTableCorrespondenceTab,
    PersonMaterialTableSaleTab,
    PersonMaterialTableOfferTab, PersonMaterialTableProjectTab, PersonMaterialTableLeadTab, TabView, TabPanel},
  emits:['rowClicked', 'loadMoreData'],
  props:{
    activeIndex:Number,
    contact:Object,
    leads:Array,
    projects:Array,
    closedLeads:Array,
    closedProjects:Array,
    sales:Array,
    showLoadMoreLeads:Object,
    showLoadMoreProjects:Object,
    showLoadMoreClosedLeads:Object,
    showLoadMoreClosedProjects:Object,
    showLoadMoreSales:Object,
  },
  computed:{
    offers(){
      let offers = [];
      for(let offer of this.$store.getters.offers){
        for(let member of offer.Members){
          if(member.Id === this.contact.ContactId){
            offers.push(offer);
            break;
          }
        }
      }
      return offers;
    },
  },
  methods:{
    emitRowClicked(data){
      this.$emit('row-clicked',data);
    },

    emitLoadMoreData(param){
      this.$emit('load-more-data',param);
    }
  }
}
</script>

<style scoped>

</style>