<template>
  <div class="flex gap-2 h-full">
    <div class="flex flex-column flex-auto overflow-y-auto col-8">
      <div class="surface-section p-5" style="box-sizing:border-box;width:100%; margin-bottom:10px; position:sticky;top:0;background:#EEF2FF;z-index:10">
        <div class="flex justify-content-between gap-2">
          <div class="flex align-items-start flex-column md:flex-row">
            <div class="mr-5 mb-3 lg:mb-0" style="width: 90px;height: 90px">
              <Skeleton shape="circle" size="8rem"></Skeleton>
            </div>
            <div class="mt-2 ml-4">
              <Skeleton height="60px" width="500px" class="mb-3"></Skeleton>
              <div class="flex align-items-center flex-wrap text-sm">
                <Skeleton height="30px" width="75vw" class="mb-2"></Skeleton>
                <Skeleton height="30px" width="75vw"></Skeleton>
              </div>
            </div>
          </div>

          <div>
            <Skeleton height="8rem" width="5rem"></Skeleton>
          </div>
        </div>
      </div>

      <div class="grid">
        <Skeleton height="60px" width="100vw" class="mb-2 ml-2 mr-2"></Skeleton>
        <div class="col-12 overflow-x-auto">
          <Skeleton height="500px" width="100%"></Skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';

export default {
  name:'DashboardInitialLoader',
  components:{
    Skeleton,
  }
}
</script>

<style scoped>

</style>