import {Amplify,Storage} from "aws-amplify";

export default {
    methods:{
        configureS3WithStorage(){
            Amplify.configure({
                Auth: {
                    IdentityPoolId: 'eu-central-1:46e1108b-b89c-45e5-bd29-647085046ea4',
                    region: 'eu-central-1', // REQUIRED - Amazon Cognito Region
                    userPoolId: 'eu-central-1_HcmhH1Ht0',
                    userPoolWebClientId: '22tdt6m6ud7uuntjh8qfg9si0c'
                },
                Storage: {
                    AWSS3: {
                        bucket: 'storage-' + this.$store.getters.domainName, //REQUIRED -  Amazon S3 bucket name
                        region: 'eu-central-1', //OPTIONAL -  Amazon service region
                    }
                }
            });
        },
        s3UploadFile(file, key){
            return new Promise((resolve)=>{
                Storage.configure({
                    customPrefix: {
                        public: '',
                    },
                });
                Storage.put(key, file, {
                    contentType: file.type,
                }).then(() => {
                    resolve();
                }).catch((err)=>{
                    console.log(err);
                })
            })
        },

        async deleteFolder(folderPath){
            try {
                await this.deleteFolderRecursively(folderPath);

                console.log('Successfully deleted folder');
            } catch (error) {
                console.error('Failed to delete folder ', error);
            }
        },

        // Gets all subfolders & files to delete from the given folderPath
        async deleteFolderRecursively(folderPath) {
            const listResult = await Storage.list(folderPath);

            if (listResult.length === 0) return;

            // Collect delete promises for files
            const deletePromises = listResult
                .filter(item => !item.key.endsWith('/')) // Ensure it's a file, not a folder
                .map(item => Storage.remove(item.key));

            // Process subfolders recursively
            const subfolderPromises = listResult
                .filter(item => item.key.endsWith('/')) // Ensure it's a folder
                .map(folder => this.deleteFolderRecursively(folder.key));

            await Promise.all([...deletePromises, ...subfolderPromises]);
        },

        downloadFile(filePath){
            return new Promise((resolve)=>{
                this.$store.dispatch('getRequest',"createSignedURL&query=" + ["storage-" + this.$store.getters.domainName,filePath]).then((res)=>{
                    const body = JSON.parse(res.body)
                    resolve(body.URL);
                })
            })
        },
        getFileName(fileString){
            const arr = fileString.split('/');
            return arr[arr.length-1];
        },
        getFileType(fileEnding) {
            const arr = fileEnding.split('.');
            const file = arr[arr.length - 1];

            return file.toLowerCase();
        },

        getS3Objects(path){
            return new Promise((resolve) => {
                this.$store.dispatch('getRequest','getS3Objects&query=' + path, '/').then((res)=>{
                    if(!res.errorMessage){
                        const body = JSON.parse(res);
                        const files = [];
                        if(body.Contents) {
                            for(let content of body.Contents){
                                files.push({
                                    Key:content.Key,
                                    LastModified:new Date(content.LastModified).toLocaleString(),
                                    Name:this.getFileName(content.Key),
                                    Size:(content.Size / (1024*1024)).toFixed(2) + " MB",
                                    Type:this.getFileType(content.Key),
                                })
                            }
                        }
                        resolve(files);
                    }
                });
            })
        }
    }
}