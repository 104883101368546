<template>
  <TabView>
    <TabPanel :header="$t('open') + ' (' + openCompanyOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'companyOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="openCompanyOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>

    <TabPanel :header="$t('sold') + ' (' + soldCompanyOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'companyOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="soldCompanyOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>

    <TabPanel :header="$t('rejected') + ' (' + rejectedCompanyOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'companyOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="rejectedCompanyOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>

    <TabPanel :header="$t('replaced') + ' (' + replacedCompanyOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'companyOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="replacedCompanyOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>

    <TabPanel :header="$t('lost') + ' (' + lostCompanyOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'companyOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="lostCompanyOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>

    <TabPanel :header="$t('delayed') + ' (' + delayedCompanyOffers.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'companyOffers'"
                     :style="{'height': windowHeight}"
                     :data-prop="delayedCompanyOffers"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitOfferClicked"/>
    </TabPanel>
  </TabView>

</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "CompanyMaterialTableOfferTab",
  components:{MaterialTable, TabView, TabPanel},
  emits:['offerClicked'],
  data(){
    return{
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Members', header: this.$t('members') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'Probability', header: this.$t('probability') },
        { field: 'SaleValue', header: this.$t('budget') },
        { field: 'OfferValue', header: this.$t('offerValue') },
        { field: 'BonusOption', header: this.$t('bonusOption') },
        { field: 'OfferNumber', header: this.$t('offerNumber') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Members: { value: null, matchMode: FilterMatchMode.CONTAINS },
        DeliveryDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Probability: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SaleValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        BonusOption: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'Probability', 'SaleValue', 'OfferValue', 'BonusOption', 'OfferNumber'],
      tableKey:0,
      windowHeight: '',
    }
  },
  props:{
    companyId:String,
  },
  computed:{

    openCompanyOffers(){
      return this.setCompanyOffer("10");
    },

    soldCompanyOffers(){
      return this.setCompanyOffer("20");
    },

    rejectedCompanyOffers(){
      return this.setCompanyOffer("30");
    },

    replacedCompanyOffers(){
      return this.setCompanyOffer("40");
    },

    lostCompanyOffers(){
      return this.setCompanyOffer("50");
    },

    delayedCompanyOffers(){
      return this.setCompanyOffer("60");
    },
  },

  methods:{
    /**
     * Returns all offers where the offer has the company set as the current company dashboard.
     * @param status
     * @returns {*[]}
     */
    setCompanyOffer(status){
      const companyOffers = [];
      for(let offer of this.$store.getters.offers){
        if(offer.Status === status){
          if(offer.Company.Id === this.companyId) companyOffers.push(offer)
        }
      }

      return companyOffers
    },

    emitOfferClicked(offer){
      const data = {data:offer, type:'Offer'};
      this.$emit('offer-clicked',data);
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>