<template>
  <MaterialTable :data-key="'Id'"
                 :table-id="'person'"
                 :style="{'height': windowHeight}"
                 @row-selected="emitSelectionChanged"
                 :data-prop="optionsList"
                 :columns="personColumns"
                 :has-paginator="true"
                 :rows-count="10"
                 :has-global-filter="true"
                 :filter-prop="personFilter"
                 :sortable="true"
                 :show-toggle-column="true"
                 :has-removable-sort="true"
                 :key="'person_' + tableKey"
                 :selection-mode="'single'"
                 :resizable-columns="true"/>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
export default {
  name: "PersonSearchTable",
  components: {MaterialTable},
  emits:['selectionChanged'],
  props:{
    optionsList:Array,
  },
  data(){
    return{
      personColumns:[
        { field: 'Name', header: this.$t('name') },
        { field: 'Customer', header: this.$t('company') },
        { field: 'Department', header: this.$t('department') },
        { field: 'Position', header: this.$t('position') },
        { field: 'Type', header: this.$t('type') },
        { field: 'Retired', header: this.$t('retired') },
      ],
      personFilter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Customer: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Position: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Retired: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tableKey:0,
      windowHeight: '',
    }
  },
  methods:{
    emitSelectionChanged(item){
      this.$emit('selection-changed',item);
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.56 + 'px';
  }
}
</script>

<style scoped>

</style>