<template>
  <div class="col-12 h-full">
    <SmallCard :header="this.$t('tasks')"
               :use-slot="true"
               :show-button="projectOpen"
               :button-label="this.$t('createNewTask')"
               :button-icon="'pi pi-plus'"
               @button-clicked="createTask" :card-class="'h-full'">
      <TabView>
        <TabPanel :header="$t('open') + ' (' + openTasks.length + ')'">
          <MaterialTable :data-key="'Id'"
                         :table-id="'projectTasks'"
                         :style="{'height': windowHeight}"
                         :data-prop="openTasks"
                         :columns="columns"
                         :has-paginator="true"
                         :rows-count="10"
                         :has-global-filter="true"
                         :filter-prop="filter"
                         :filter-options="filterOptions"
                         :show-delete-button="projectOpen"
                         @row-deleted="deleteTask"
                         :show-close-button="projectOpen"
                         @close-data="closeTask"
                         :sortable="true"
                         :has-removable-sort="true"
                         :show-toggle-column="true"
                         :key="tableKey"
                         :selection-mode="'single'"
                         @row-selected="editTask"
                         :resizable-columns="true"
                         :close-button-tool-tip="$t('swal.closeTaskHeader')"
                         :delete-button-tool-tip="$t('swal.deleteTaskHeader')"/>
        </TabPanel>

        <TabPanel :header="$t('closed') + ' (' + closedTasks.length + ')'">
          <MaterialTable :data-key="'Id'"
                         :table-id="'projectTasks'"
                         :style="{'height': windowHeight}"
                         :data-prop="closedTasks"
                         :columns="columns"
                         :has-paginator="true"
                         :rows-count="10"
                         :has-global-filter="true"
                         :filter-prop="filter"
                         :filter-options="filterOptions"
                         :sortable="true"
                         :has-removable-sort="true"
                         :show-toggle-column="true"
                         :key="tableKey"
                         :selection-mode="'single'"
                         @row-selected="editTask"
                         :resizable-columns="true"/>
        </TabPanel>
      </TabView>
    </SmallCard>
  </div>

  <Popup :header="this.$t('tasks')"
         :modal="true"
         :visible="openAddTask"
         styling="width:50%"
         :position="'top'"
         :show-save-button="true"
         @button-clicked="submitAddTaskPopup"
         @close-popup="openAddTask = false">
    <AddTask ref="addTask"
             :project-id="projectId"
             :project-open="projectOpen"
             :members="members"
             @close-popup="openAddTask = false"/>
  </Popup>

  <Popup :header="this.$t('tasks')"
         :modal="true"
         :visible="openEditTask"
         styling="width:70%"
         :position="'top'"
         :show-save-button="projectOpen && taskOpen"
         @button-clicked="submitEditTaskPopup"
         @close-popup="openEditTask = false"
         :show-menu-button="projectOpen"
         :menu-options="editTaskMenuOptions"
         :show-inline-message="true"
         :inline-message="inlineMessage">
    <EditTask ref="editTask"
              :project-id="projectId"
              :task-prop="selectedTask"
              :projectOpen="projectOpen"
              :disabled="selectedTask.Status !== '10' || !projectOpen" :members="members"
              @close-popup="openEditTask = false"
              @reopen-task="reopenTask" @close-task="closeTask"/>
  </Popup>

</template>

<script>
import Popup from "@/components/global-components/popup/Popup";
import AddTask from "@/components/tasks/add-task/AddTask";
import EditTask from "@/components/tasks/edit-task/EditTask";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
import SmallCard from "@/components/global-components/card/SmallCard";
import S3FileManager from "../../../mixins/s3-file-manager/s3-file-manager";

export default {
  name: "TaskInformation",
  components:{SmallCard, MaterialTable, Popup, AddTask, EditTask, TabView, TabPanel},
  mixins:[S3FileManager],
  props:{
    clickedPhase:Object,
    members:Array,
    projectId:String,
    projectOpen:Boolean,
  },
  data(){
    return{
      columns: [
        { field: 'Title', header: this.$t('title') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Phase.Name', header: "Phase" },
        { field: 'Created', header: this.$t('createdOn') },
        { field: 'ExpiresAt', header: this.$t('expiresAt') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        'Phase.Name': { value: null, matchMode: FilterMatchMode.IN },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ExpiresAt: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'Phase.Name', 'Responsible.Name'],
      openAddTask: false,
      openEditTask:false,
      selectedTask:null,
      tableKey:0,
      editTaskMenuOptions:[],
      inlineMessage:'',
      taskOpen:false,
      windowHeight: '',
    }
  },

  computed:{
    openTasks(){
      return this.getTasksByStatus("10");
    },

    closedTasks(){
      return this.getTasksByStatus("20");
    },
  },

  methods:{
    /**
     * Returns all tasks that equal the parameter status.
     * If a phase is clicked, we filter through all tasks and return only the corresponding tasks.
     * @param status
     * @returns {*[]}
     */
    getTasksByStatus(status){
      const tasks = [];
      const foundTasks = this.$store.getters.tasks.filter(task => task.ProjectId === this.projectId);
      for(let foundTask of foundTasks){
        if(this.clickedPhase && this.clickedPhase.Number){
          if(foundTask.Phase.Number === this.clickedPhase.Number){
            if(foundTask.Status === status){
              tasks.push(foundTask);
            }
          }
        }else{
          if(foundTask.Status === status){
            tasks.push(foundTask);
          }
        }
      }
      return tasks
    },

    createTask(){
      this.openAddTask = !this.openAddTask;
    },

    editTask(task){
      this.inlineMessage = this.getDueDate(task.ExpiresAt) > 0 ?
          this.$t('expiredSince') + ' ' + this.getDueDate(task.ExpiresAt) + ' ' + this.$t('days') :
          this.$t('expiresIn') + ' ' + this.getDueDate(task.ExpiresAt) + ' ' + this.$t('days');
      this.createEditTaskMenuOptions(task);
      this.taskOpen = task.Status === '10';
      this.selectedTask = task;
      this.openEditTask = !this.openEditTask;
    },

    createEditTaskMenuOptions(task){
      const optionsMenu = []
      if(task.Status === '10'){
        if(this.$store.getters.userType.toLowerCase() === "admin"){
          optionsMenu.push(
              { label: this.$t('swal.deleteTaskHeader'), icon: 'pi pi-fw pi-trash',
                command: () => { this.deleteTask(task);}
              },
              { separator : true},
          )
        }
        optionsMenu.push(
            { label: this.$t('swal.closeTaskHeader'), icon: 'pi pi-fw pi-check',
              command: () => {this.closeTask(task);}
            },
        )
      }else{
        optionsMenu.push(
            { label: this.$t('swal.reopenTaskHeader'), icon: 'pi pi-fw pi-check',
              command: () => {this.reopenTask(task);}
            },
        )
      }

      this.editTaskMenuOptions = optionsMenu;
    },

    /**
     * Shows an alert that asks for confirmation to close the task.
     * If we accept we show a short confirmation popup in the top-right corner and
     * set Status to 20 and Closed unix and edit the task in store.
     * If we decline we show a short reject popup in the top-right corner.
     */
    closeTask(task){
      this.$confirm.require({
        header: this.$t('swal.closeTaskHeader'),
        message: this.$t('swal.closeTaskText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          task.Status = '20';
          task.Closed = new Date().getTime();
          this.$store.dispatch('closeTask',task).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.taskClosed'), life: 3000 });
            this.openEditTask = false;
            this.selectedTask = {}
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    /**
     * Shows an alert that asks for confirmation to delete the task.
     * If we accept we show a short confirmation popup in the top-right corner and
     * remove the task from the store.
     * If we decline we show a short reject popup in the top-right corner.
     */
    deleteTask(task){
      this.$confirm.require({
        header: this.$t('swal.deleteTaskHeader'),
        message: this.$t('swal.deleteTaskText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteTask',task).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.taskDeleted'), life: 3000 });
            this.deleteFolder('sales/leadsAndProjects/' + this.projectId + '/tasks/' + task.Id +'/');
            this.openEditTask = false;
            this.selectedTask = {};
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    reopenTask(task){
      this.$confirm.require({
        header: this.$t('swal.reopenTaskHeader'),
        message: this.$t('swal.reopenTaskText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          task.Status = "10";
          this.$store.dispatch('reopenTask',task).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.taskReopened'), life: 3000 });
            this.openEditTask = false;
            this.selectedTask = {};
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    /**
     * Calculates the due date of the lead.
     * @param unix
     * @returns {number}
     */
    getDueDate(unix){
      const nowUnix = new Date().getTime() / 1000;
      const timeStamp = unix / 1000;

      return (Math.round((nowUnix - timeStamp) / 60 / 60 / 24))
    },

    submitAddTaskPopup(){
      this.$refs.addTask.checkIfFilesNotUploaded();
    },

    submitEditTaskPopup(){
      this.$refs.editTask.checkIfFilesNotUploaded();
    }
  },
     
  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>