<template>
  <MaterialTable 
    :data-key="'Id'"
    :table-id="'sales'"
    :style="{'height': windowHeight}"
    @row-selected="goToProjectDashboard"
    :data-prop="openSales"
    :columns="columns"
    :has-paginator="true"
    :rows-count="10"
    :has-global-filter="true"
    :filter-prop="filter"
    :filter-options="filterOptions"
    :sortable="true"
    :show-toggle-column="true"
    :has-removable-sort="true"
    :key="tableKey"
    :selection-mode="'single'"
    :resizable-columns="true"
    :show-load-more-data="salesLastEvaluatedKey"
    @load-more-data="loadMoreSales"/>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import getStoreData from "@/mixins/get-store-data/getStoreData";

export default {
  name: "SalesComponent",
  components:{MaterialTable},
  mixins:[getStoreData, globalComputedProperties],
  data(){
    return {
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'CommissionNumber', header: this.$t('commissionNumber') },
        { field: 'CommissionValue', header: this.$t('commissionValue') },
        { field: 'Company.Name', header: this.$t('company') },
        { field: 'Contact.Name', header: this.$t('contact') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'OrderNumber', header: this.$t('orderNumber') },
        { field: 'OrderValue', header: this.$t('orderValue') },
        { field: 'ReferenceNumber', header: this.$t('referenceNumber') },
        { field: 'SalesDate', header: this.$t('salesDate') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'OfferValue', header: this.$t('offerValue') },
        { field: 'OfferName', header: this.$t('offer') },
        { field: 'Supplier', header: this.$t('supplier') },
        { field: 'Equipment', header: this.$t('equipments') },
        { field: 'CompetitionModel', header: this.$t('competitionModel') },
        { field: 'MachineModel', header: this.$t('machineModel') },
        { field: 'MachineNumber', header: this.$t('machineNumber') },
        { field: 'Discount', header: this.$t('discountPercentage') },
        { field: 'Notes', header: this.$t('notes') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CommissionNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CommissionValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Company.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Contact.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        OrderNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OrderValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ReferenceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SalesDate: { value: null, matchMode: FilterMatchMode.IN },
        DeliveryDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Equipment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CompetitionModel: { value: null, matchMode: FilterMatchMode.CONTAINS },
        MaschineModel: { value: null, matchMode: FilterMatchMode.CONTAINS },
        MaschineNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Discount: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Notes: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'CommissionNumber', 'CommissionValue', 'Company.Name', 'Responsible.Name', 'Contact.Name', 'OrderNumber', 'OrderValue',
       'ReferenceNumber', 'OfferValue', 'OfferName', 'Supplier', 'CompetitionModel', 'MaschineModel', 'MachineNumber', 'Discount', 'Notes'],
      tableKey:0,
      windowHeight:'',
    }
  },
  computed:{
    openSales(){
      return this.setSales();
    },

    salesLastEvaluatedKey(){
      return this.$store.getters.salesLastEvaluatedKey;
    }
  },
  methods: {
    setSales() {
      const sales = [];
      const allSales = this.$store.getters.sales;
      for (let sale of allSales) {
        if (sale.VisibleTo === "Members" || sale.VisibleTo === "Self") {
          for (let member of sale.Members) {
            if (member.Name === this.username) {
              sales.push(sale);
            }
          }
        } else {
          sales.push(sale);
        }
      }
      return sales;
    },

    goToProjectDashboard(event){
      this.$router.push({
        path:"/projects/dashboard/" + event.ProjectId,
        params:event.ProjectId
      });
    },

    loadMoreSales(){
      this.getMoreSales();
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.77 + 'px';
  }
}
</script>

<style scoped>

</style>