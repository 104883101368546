const state = {
    sales: [],
    salesLastEvaluatedKey:null,
};

const getters = {
    sales: (state) => state.sales,
    salesLastEvaluatedKey: (state) => state.salesLastEvaluatedKey,
};

const actions = {
    addSale(context,sale){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"createSale",
                "payload":sale,
            }).then(()=> {
                context.commit('addSale',sale);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        })
    },

    editSale(context,sale){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"editSale",
                "payload":sale,
            }).then(()=> {
                context.commit('editSale',sale);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        })
    },

    deleteSale(context,sale){
        context.commit('setLoading',true);
        return new Promise((resolve, reject) => {
            context.dispatch("postRequest",{
                "param":"deleteSale",
                "payload":sale.Id,
            }).then(()=> {
                context.commit('deleteSale',sale);
                resolve();
            }).catch((err) => {
                console.log(err);
                reject(err);
            }).finally(() => {
                context.commit('setLoading', false);
            });
        });
    },
};

const mutations = {
    setSales(state,sales){
        const changedSales = sales.map(sale => ({...sale, DeliveryDate: sale.DeliveryDate.toString().length <= 10 ?
                sale.DeliveryDate * 1000 : sale.DeliveryDate}));
        state.sales = [...state.sales, ...changedSales].sort((a, b) => {
            if (a['Title'] < b['Title']) return -1;
            if (a['Title'] > b['Title']) return 1;
        });
    },

    // This will reset sales to at max. 200. entries
    setLatestSalesData(state, sales){
        state.sales = sales;
    },

    setSalesLastEvaluatedKey(state, lastEvaluatedKey){
        state.salesLastEvaluatedKey = lastEvaluatedKey
    },

    addSale(state,sale){
        state.sales.push(sale);
    },

    editSale(state,sale){
        const foundSaleIndex = state.sales.findIndex(foundSale => foundSale.Id === sale.Id);
        state.sales[foundSaleIndex] = sale;
    },

    deleteSale(state,sale){
        state.sales = state.sales.filter(foundSale => foundSale.Id !== sale.Id);
    }
}

const salesStore = {
    state,
    getters,
    actions,
    mutations,
}

export default salesStore;