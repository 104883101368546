<template>
  <MaterialTable :data-key="'Id'"
                 :table-id="'companyLocations'"
                 :style="{'height': windowHeight}"
                 :data-prop="companyLocations"
                 :columns="columns"
                 :has-paginator="true"
                 :rows-count="10"
                 :has-global-filter="true"
                 :show-toggle-column="true"
                 :filter-prop="filter"
                 :filter-options="filterOptions"
                 :sortable="true"
                 :has-removable-sort="true"
                 :key="tableKey"
                 :selection-mode="'single'"
                 :resizable-columns="true"/>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "CompanyMaterialTableLocationTab",
  components:{MaterialTable},
  data(){
    return{
      columns:[
        { field: 'City', header: this.$t('city') },
        { field: 'CompanyNumber', header: this.$t('companyNumber') },
        { field: 'CountryCode', header: this.$t('country') },
        { field: 'Email', header: this.$t('email') },
        { field: 'LocationName', header: this.$t('location') },
        { field: 'Phone', header: this.$t('phone') },
        { field: 'PostalCode', header: this.$t('postalCode') },
        { field: 'Street', header: this.$t('street') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        City: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CompanyNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CountryCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        LocationName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        PostalCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Street: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['City', 'CompanyNumber', 'CountryCode', 'Email', 'LocationName', 'Phone', 'PostalCode', 'Street'],
      tableKey:0,
      companyLocations:JSON.parse(JSON.stringify(this.companyLocationsProp)),
      windowHeight: '',
    }
  },
  props:{
    companyLocationsProp:Array,
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>