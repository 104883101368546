<template>
  <MaterialTable :data-key="'Key'"
                 :table-id="'attachments'"
                 :data-prop="attachments"
                 :columns="columns"
                 :filter-prop="filter"
                 :has-paginator="true"
                 :rows-count="10"
                 :has-global-filter="true"
                 @row-deleted="deleteAttachment"
                 :show-delete-button="showDeleteButton"
                 :sortable="true"
                 :has-removable-sort="true"
                 :show-toggle-column="true"
                 :key="tableKey"
                 :selection-mode="'single'"
                 @row-selected="attachmentSelected"
                 :resizable-columns="true"/>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";

export default {
  name: "AttachmentMaterialTable",
  components:{MaterialTable},
  mixins:[S3FileManager],
  emits: ['deleteAttachment'],
  data(){
    return{
      columns:[
        { field: 'Type', header: this.$t('type') },
        { field: 'Name', header: this.$t('name') },
        { field: 'LastModified', header: this.$t('lastModified') },
        { field: 'Size', header: this.$t('filesize') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Type: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        LastModified: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Size: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tableKey:0,
    }
  },
  props:{
    attachments:Array,
    showDeleteButton:Boolean,
  },
  methods:{
    deleteAttachment(file){
      this.$emit('delete-attachment',file)
    },

    attachmentSelected(file){
      this.downloadFile(file.Key).then((signedUrl)=>{
        window.open(signedUrl)
      })
    }
  }
}
</script>

<style scoped>

</style>