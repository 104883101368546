<template>
  <div v-if="useSlot" class="h-full">
    <div class="surface-card shadow-2 border-round overflow-y-auto" :class="cardClass" :style="minMaxHeight ? 'min-height: 300px;max-height: 300px' : ''">
      <div class="text-xl text-900 font-medium p-4" style="box-sizing:border-box;position:sticky;top:0;">
        <div class="flex justify-content-between align-items-center gap-2 overflow-x-auto">
          <span>{{header}}</span>
          <div v-if="showSearchbar">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText :modelValue="dataSearchQuery" @update:modelValue="emitSearchQuery" :placeholder="this.$t('placeholder.search')" />
            </span>
          </div>
          <div v-if="showButton">
            <PrimeButton
              :icon="buttonIcon"
              :label="buttonLabel"
              outlined
              severity="contrast"
              size="small"
              @click="emitButtonClicked" />
          </div>
        </div>
      </div>
      <div class="xl:pl-4 xl:pr-4 xl:pb-4">
        <slot></slot>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="surface-card shadow-2 p-3 border-1 border-50 border-round" :class="cardClass" @click="emitCardClicked">
      <div class="flex justify-content-between align-items-center gap-2" :class="icon ? '' : 'mb-2'">
        <span>{{header}}</span>
        <i v-if="icon" :class="icon" style="font-size:1.5rem;"/>
      </div>
      <span :class="textClass">{{text}}</span>
    </div>
  </div>

</template>

<script>
import InputText from "primevue/inputtext";
import PrimeButton from "primevue/button";
export default {
  name: "SmallCard",
  components:{InputText, PrimeButton},
  emits: ['searchQueryChanged','buttonClicked','cardClicked'],
  props:{
    buttonIcon:String,
    buttonLabel:String,
    cardClass:String,
    header:String,
    icon:String,
    minMaxHeight:Boolean,
    showButton:Boolean,
    showSearchbar:Boolean,
    text:[String,Number],
    textClass:String,
    useSlot:Boolean,
  },
  data(){
    return{
      dataSearchQuery:null,
    }
  },

  methods:{
    emitSearchQuery(event){
      this.dataSearchQuery = event;
      this.$emit('search-query-changed',event);
    },

    emitButtonClicked(){
      this.$emit('button-clicked');
    },

    emitCardClicked(){
      this.$emit('card-clicked');
    }
  }
}
</script>

<style>
.p-button{
  position:unset !important;
}
</style>