<template>
  <div class="flex justify-content-start align-items-center gap-1 relative">
    <Avatar :label="this.avatarName" size="large" shape="circle"/>
    <div v-if="isDesktop" class="flex flex-column ml-2 align-items-start">
      <span class="text-900 font-light text-sm">{{this.username}}</span>
    </div>
  </div>
</template>

<script>

import Avatar from "primevue/avatar";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";

export default {
  name: "UserAvatar",
  components:{Avatar},
  mixins:[globalComputedProperties],
  computed:{
    avatarName(){
      return this.$store.getters.shortUsername.substring(0,2).toUpperCase();
    }
  },
}
</script>

<style scoped>

</style>