<template>
  <TabView>
    <TabPanel :header="this.$t('open') + ' (' + companyOpenTasks.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'companyTasks'"
                     :style="{'height': windowHeight}"
                     :data-prop="companyOpenTasks"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitTaskClicked"/>
    </TabPanel>

    <TabPanel :header="this.$t('closed') + ' (' + companyClosedTasks.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'companyTasks'"
                     :style="{'height': windowHeight}"
                     :data-prop="companyClosedTasks"
                     :columns="columns"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :resizable-columns="true"
                     @row-selected="emitTaskClicked"/>
    </TabPanel>
  </TabView>

</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";

export default {
  name: "CompanyMaterialTableTaskTab",
  components:{MaterialTable, TabView, TabPanel},
  emits:['taskClicked'],
  props:{
    companyId:String,
  },
  data(){
    return{
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Phase.Name', header: "Phase" },
        { field: 'Created', header: this.$t('createdOn') },
        { field: 'ProjectId', header: this.$t('projectTitle') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Phase.Name': { value: null, matchMode: FilterMatchMode.IN },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        ProjectId: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'Responsible.Name', 'Phase.Name'],
      tableKey:0,
      windowHeight: '',
    }
  },

  computed:{
    companyOpenTasks(){
      return this.getTasksByStatus("10");
    },

    companyClosedTasks(){
      return this.getTasksByStatus("20");
    },
  },

  methods:{
    emitTaskClicked(task){
      const data = {data:task, type:'Task'};
      this.$emit('task-clicked',data);
    },

    /**
     * returns tasks from all projects, where the project has the company set from the company dashboard.
     * @param status
     * @returns {*[]}
     */
    getTasksByStatus(status){
      const companyTasks = [];
      const tasks = this.$store.getters.tasks;
      for(let project of this.$store.getters.projects){
        if(project.Company.Id === this.companyId){
          for(let task of tasks){
            if(task.Status === status){
              if(task.ProjectId === project.Id) companyTasks.push(task);
            }
          }
        }
      }
      return companyTasks
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>