<template>
  <TabView>
    <TabPanel :header="$t('open') + ' (' + leads.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'companyLeads'"
                     :style="{'height': windowHeight}"
                     :data-prop="leads"
                     :columns="columns"
                     @row-selected="emitLeadClicked"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="filter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :has-reminder="true"
                     :has-attachments="true"
                     :show-load-more-data="showLoadMoreLeads"
                     @load-more-data="loadMoreData"
                     :resizable-columns="true"/>
    </TabPanel>
    <TabPanel :header="$t('closed') + ' (' + closedLeads.length + ')'">
      <MaterialTable :data-key="'Id'"
                     :table-id="'companyClosedLeads'"
                     :style="{'height': windowHeight}"
                     :data-prop="closedLeads"
                     :columns="closedColumns"
                     @row-selected="emitLeadClicked"
                     :has-paginator="true"
                     :rows-count="10"
                     :has-global-filter="true"
                     :show-toggle-column="true"
                     :filter-prop="closedFilter"
                     :filter-options="filterOptions"
                     :sortable="true"
                     :has-removable-sort="true"
                     :key="tableKey"
                     :selection-mode="'single'"
                     :has-reminder="true"
                     :has-attachments="true"
                     :show-load-more-data="showLoadMoreClosedLeads"
                     @load-more-data="loadMoreClosedData"
                     :resizable-columns="true"/>
    </TabPanel>
  </TabView>
</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import {FilterMatchMode} from "primevue/api";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
export default {
  name: "CompanyMaterialTableLeadTab",
  components:{MaterialTable, TabView, TabPanel},
  emits:['leadClicked', 'leadMoreData'],
  props:{
    leads:Array,
    closedLeads:Array,
    showLoadMoreLeads:Object,
    showLoadMoreClosedLeads:Object,
  },
  data(){
    return{
      columns:[
        { field: 'CreationNumber', header: this.$t('number') },
        { field: 'Title', header: this.$t('title') },
        { field: 'Description', header: this.$t('description') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Created', header: this.$t('createdOn') },
        { field: 'OpenSince', header: this.$t('openSince') },
        { field: 'Status', header:'Status'},
        { field: 'Prio', header: 'Prio' },
        { field: 'Location.LocationName', header: this.$t('location') },
        { field: 'Supplier', header: this.$t('supplier') }
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CreationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Creator: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OpenSince: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Status :{ value:null, matchMode: FilterMatchMode.CONTAINS},
        Prio: {value:null,matchMode: FilterMatchMode.CONTAINS},
        'Location.LocationName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        Supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      closedColumns:[
        { field: 'CreationNumber', header: this.$t('number') },
        { field: 'Title', header: this.$t('title') },
        { field: 'Description', header: this.$t('description') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'Created', header: this.$t('createdOn') },
        { field: 'Status', header: 'Status'},
        { field: 'Prio', header: 'Prio'},
        { field: 'Location.LocationName', header: this.$t('location') },
        { field: 'Supplier', header: this.$t('supplier') }
      ],
      closedFilter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CreationNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Creator: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.IN },
        Created: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Status: { value: null, matchMode: FilterMatchMode.CONTAINS},
        Prio: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Location.LocationName': { value: null, matchMode: FilterMatchMode.CONTAINS },
        Supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['CreationNumber', 'Title', 'Description', 'Creator',
       'Responsible.Name', 'Status', 'Prio', 'Supplier', 'Location.LocationName'],
      tableKey:0,
      windowHeight: '',
    }
  },
  methods:{
    emitLeadClicked(lead){
      const data = {data:lead, type:'Lead'};
      this.$emit('lead-clicked',data);
    },

    loadMoreClosedData(){
      this.$emit('load-more-data','closedProject');
    },

    loadMoreData(){
      this.$emit('load-more-data','project')
    }
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>