<template>
  <div class="flex flex-column flex-auto" v-if="project">
    <div class="p-5" style="box-sizing:border-box;width:100%; margin-bottom:10px;background-color: var(--surface-0); position:sticky;top:0;z-index:10">
      <ProjectDashboardHeader :project="project"
                              :menu-options="menuOptions"
                              :project-is-favorite="projectIsFavorite"
                              @change-phase="editingPhase = true"
                              @show-cards="setShowCards"
                              @favorise-project="favoriseProject"
                              @unfavorise-project="unfavoriseProject"
                              @reopen-project="reopenProject"
                              @show-description="setShowDescription"/>
    </div>
    <div class="flex flex-wrap">
     <!-- <DashboardCards v-if="showCards"
                      :project="project"
                      :sales-length="sales.length"
                      @change-phase="this.editingPhase = true"
                      @go-to-tab="goToTab"
                      :reportsLength="reports.length" />
    -->
      <ProjectPhaseTimeline :project="project"
                            :clickedPhase="clickedPhase"
                            @phase-clicked="setClickedPhase"
                            @reset-clicked-phase="emptyClickedPhase"/>
      <div ref="container" class="w-full">
        <TabView :activeIndex="activeIndex">
          <TabPanel :header="$t('correspondence') + ' (' + correspondences.length + ')'">
            <ProjectCorrespondence :project="project"
                                   :members="members"
                                   :correspondences="correspondences"
                                   :project-open="project.Status === '10'"
                                   @correspondence-edited="correspondenceEdited"
                                   @correspondence-deleted="correspondenceDeleted"
                                   @remove-report="removeReport"/>
          </TabPanel>
          <TabPanel :header="$t('persons') + ' (' + members.length + ')'">
            <ProjectDashboardPersons :members="members" />
          </TabPanel>
          <TabPanel :header="$t('tasks') + ' (' + tasksLength + ')'">
            <TaskInformation :project-id="projectId"
                             :members="members"
                             :clickedPhase="clickedPhase"
                             :project-open="project.Status === '10'" />
          </TabPanel>
          <TabPanel :header="$t('offers') + ' (' + offersLength + ')'">
            <Offers :project="project"
                    :project-open="project.Status === '10'" />
          </TabPanel>
          <TabPanel :header="$t('sales') + ' (' + sales.length + ')'">
            <SalesInformation :project="project"
                              :sales="sales"
                              :show-load-more-sales="salesLastEvaluatedKey"
                              @add-sale="addSale"
                              @edit-sale="editSale"
                              @delete-sale="deleteSale"
                              @load-more-data="getMoreSales"
                              :project-open="project.Status === '10'" />
          </TabPanel>
          <TabPanel :header="$t('reports') + ' (' + reports.length + ')'">
            <ReportTable :reports="reports"
                         :show-save-button="project.Status === '10'"
                         :project-id="projectId"
                         :company-id="project.Company && project.Company.Id"
                         @remove-report="removeReport"
                         @report-saved="addReport"/>
          </TabPanel>
          <TabPanel :header="$t('changelog') + ' (' + project.Changelogs.length + ')'">
            <ChangeLog :changelogs="project.Changelogs" />
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
  <div v-if="!projectDeleted && !project">
    <DashboardInitialLoader />
  </div>

  <div v-if="projectDeleted" class="result-empty">
    <span>{{$t('projectDoesntExist')}}</span>
  </div>

  <Popup :maximizable="true"
         :modal="true"
         :visible="editingProject"
         :header="this.$t('editProject')"
         styling="width:50%"
         :show-save-button="true"
         @button-clicked="submitEditProjectPopup"
         @close-popup="editingProject = false">
    <EditProject ref="editProject"
                 :project-data-prop="project"
                 @close-popup="editingProject = false" />
  </Popup>

  <Popup :maximizable="false"
         :modal="true"
         :visible="editingPhase"
         :header="this.$t('changePhase')"
         styling="width:425px"
         :show-save-button="project && project.Status === '10'"
         @button-clicked="submitEditPhasePopup"
         @close-popup="editingPhase = false">
    <EditPhase ref="editPhase"
               :project="project"
               @change-phase="changePhase" />
  </Popup>

  <Popup :maximizable="false"
         :modal="true"
         :visible="closingProject"
         :header="this.$t('swal.closeProjectText')"
         :show-save-button="true"
         @button-clicked="submitCloseProjectPopup"
         styling="width:550px"
         @close-popup="closingProject = false">
    <CloseProject ref="closeProject"
                  @close-project="closeProject" />
  </Popup>

  <Popup :maximizable="false"
         :modal="true"
         :visible="showDescription"
         :header="$t('description')"
         :show-save-button="false"
         styling="width:700px"
         @close-popup="showDescription = false">
    <TextArea v-model="project.Description"
              readonly="true"
              id="Description"
              class="w-full mt-2"
              style="height:300px"
              :placeholder="this.$t('placeholder.description')" />
  </Popup>
</template>

<script>
import ProjectPhaseTimeline from "@/components/projects/project-phase-timeline/ProjectPhaseTimeline";
import ProjectCorrespondence from "@/components/projects/project-correspondence/ProjectCorrespondence";
import ProjectDashboardHeader from "@/components/projects/dashboard/dashboard-header/ProjectDashboardHeader";
//import DashboardCards from "@/components/projects/dashboard/dashboard-cards/DashboardCards";
import TaskInformation from "@/components/tasks/task-information/TaskInformation";
import Popup from "@/components/global-components/popup/Popup";
import EditProject from "@/components/projects/edit-project/EditProject";
import ProjectDashboardPersons from "@/components/projects/dashboard/persons/ProjectDashboardPersons";
import Offers from "@/components/projects/dashboard/offers/Offers";
import ChangeLog from "@/components/projects/dashboard/change-log/ChangeLog";
import Uuidv4Generator from "@/mixins/uuidv4/Uuidv4Generator";
import EditPhase from "@/components/projects/edit-phase/EditPhase";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import SalesInformation from "@/components/sales/SalesInformation";
import CloseProject from "@/components/projects/close-project/CloseProject";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import TextArea from "primevue/textarea";
import ReportTable from "@/components/reports/report-table/ReportTable";
import DashboardInitialLoader from "../components/global-components/dashboard-initial-loader/DashboardInitialLoader";

export default {
  name: "ProjectDashboard",
  components: {
    ReportTable,
    CloseProject, SalesInformation,
    EditPhase, Offers, ProjectDashboardPersons, ChangeLog, EditProject, Popup, TaskInformation,
    ProjectDashboardHeader, ProjectCorrespondence, ProjectPhaseTimeline, TabView, TabPanel, TextArea,
    DashboardInitialLoader,
    // DashboardCards
  },
  mixins:[Uuidv4Generator,S3FileManager],
  emits:['addP2Class', 'removeP2Class'],

  created() {
    // Getting the current project id, if we reload the site inside the dashboard.
    const routerParams = this.$router.currentRoute.value.params;
    this.projectId = routerParams.Id;
  },

  data(){
    return{
      activeIndex:0,
      clickedPhase:null,
      closingProject:false,
      editingPhase:false,
      editingProject:false,
      projectDeleted:false,
      projectId:"",
      reports:[],
      showCards:true,
      showDescription:false,
      sales:[],
      salesLastEvaluatedKey:null,
    }
  },
  computed:{
    project(){
      for(let project of this.$store.getters.projects){
        if(project.Id === this.projectId) {
          return project;
        }
      }
      const closedProjectDashboard = this.$store.getters.closedProjectDashboard;
      if(closedProjectDashboard && closedProjectDashboard.Id === this.projectId) {
        return closedProjectDashboard
      }
      return null
    },

    favorisedProjects(){
      return this.$store.getters.favorisedProjects;
    },

    /**
     * having menuOptions in computed, so we can translate the strings.
     * @returns {[{icon: string, label: *, command: command},{separator: boolean},{icon: string, label: string, command: command},{separator: boolean}]}
     */
    menuOptions(){
      const optionsMenu = [];

      optionsMenu.push(
        { label: this.$t('editProject'), icon: 'pi pi-fw pi-pencil',
          command: () => { this.editProject();}
        },
        { separator : true},
        {
          label: this.$t('closeProject'), icon: 'pi pi-fw pi-check',
          command: () => {this.showClosingProjectPopup();}
        }
      )

      if(this.project.Status === '10'){
        if(this.$store.getters.userType.toLowerCase() === "admin"){
          optionsMenu.push(
            { separator : true},
            { label: this.$t('swal.deleteProjectHeader'), icon: 'pi pi-fw pi-trash',
              command: () => { this.deleteProject();}
            },
          )
        }
      }

      return optionsMenu
    },

    /**
     * Returns true if the project is favorite.
     * @returns {boolean}
     */
    projectIsFavorite(){
      if(this.favorisedProjects){
        if(this.favorisedProjects.length > 0){
          for(let favorisedProject of this.favorisedProjects){
            if(favorisedProject.Id === this.project.Id){
              return true;
            }
          }
        }
      }

      return false;
    },

    members(){
      const members = [];
      const responsible = this.project.Responsible;
      responsible['Role'] = 'Verantwortlicher';
      members.push(responsible);
      for(let member of this.project.Members){
        if(member.Id !== this.project.Responsible.Id) members.push(member);
      }
      return members;
    },

    correspondences(){
      let visibleCorrespondences = [];
      for(let correspondence of this.project.Correspondence){
        if(correspondence.VisibleTo === "Self" && correspondence.Author === this.username){
          visibleCorrespondences.push(correspondence);
        }else if(correspondence.VisibleTo === "Members"){
          for(let member of correspondence.Members){
            if(member.Name === this.username){
              visibleCorrespondences.push(correspondence);
            }
          }
        }else{
          visibleCorrespondences.push(correspondence);
        }
      }
      //If we have a phase clicked we iterate through all correspondences for this project
      // only show matching correspondences.
      if(this.clickedPhase){
        const correspondences = [];
        for(let correspondence of visibleCorrespondences){
          if(correspondence.Phase.Number === this.clickedPhase.Number){
            correspondences.push(correspondence);
          }
        }
        return correspondences.reverse();
      }
      return visibleCorrespondences.reverse();
    },

    offersLength(){
      let offers = 0;
      const allOffers = this.$store.getters.offers;
      for(let offer of allOffers){
        if(offer.ProjectId === this.project.Id){
          if(offer.VisibleTo === "Members" ||offer.VisibleTo === "Self"){
            for(let member of offer.Members){
              if(member.Name === this.username){
                offers++;
              }
            }
          }else{
            offers++;
          }
        }
      }

      return offers;
    },

    tasksLength(){
      let tasks = 0;
      const foundTasks = this.$store.getters.tasks.filter(task => task.ProjectId === this.projectId);
      for(let foundTask of foundTasks){
        if(this.clickedPhase && this.clickedPhase.Number){
          if(foundTask.Phase.Number === this.clickedPhase.Number){
              tasks++;
          }
        }else{
          tasks++;
        }
      }
      return tasks
    },
  },
  methods:{
    editProject(){
      this.editingProject = true;
    },

    closeEditProject(){
      this.editingProject = false;
    },

    submitEditProjectPopup(){
      this.$refs.editProject.submitEdit();
    },

    setShowDescription(){
      this.showDescription = true;
    },

    addSale(sale){
      this.sales.push(sale);
    },

    editSale(editedSale){
      const foundSaleIndex = this.sales.findIndex((sale) => sale.Id === editedSale.Id);
      this.sales[foundSaleIndex] = editedSale;
    },

    deleteSale(removedSale){
      this.sales = this.sales.filter((sale) => sale.Id !== removedSale.Id);
    },

    reopenProject(){
      this.$confirm.require({
        header: this.$t('swal.reopenProjectHeader'),
        message: this.$t('swal.reopenProjectText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.project.Status = "10";
          this.$store.dispatch('reopenProject',this.project).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.projectReopened'), life: 3000 });
            this.routeToProjects();
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    showClosingProjectPopup(){
      this.closingProject = true;
    },

    /**
     * Shows an alert that asks for confirmation to close the project.
     * If we accept we show a short confirmation popup in the top-right corner and
     * set the Status to 20 and set Closed to the current Time in Unix.
     * If we decline we show a short reject popup in the top-right corner.
     */
    closeProject(status){
      this.project.Status = status;
      this.project.Closed = new Date().getTime();
      this.unfavoriseProject();
      this.$store.dispatch('closeProject',this.project).then(() => {
        this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.projectClosed'), life: 3000 });
        this.closingProject = false;
      });
    },


    /**
     * Shows an alert that asks for confirmation to delete the project.
     * If we accept we show a short confirmation popup in the top-right corner and
     * remove the task from store.
     * If we decline we show a short reject popup in the top-right corner.
     */
    deleteProject(){
      this.$confirm.require({
        header: this.$t('swal.deleteProjectHeader'),
        message: this.$t('swal.deleteProjectText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteProject',this.project).then(() => {
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.projectDeleted'), life: 3000 });
            this.deleteAllTasks();
            this.deleteAllOffers();
            this.deleteAllSales();
            this.deleteFolder('sales/leadsAndProjects/' + this.projectId + '/');
            this.editingProject = false;
            this.routeToProjects();
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    deleteAllTasks(){
      for(let task of this.$store.getters.tasks){
        if(task.ProjectId === this.projectId){
          this.$store.dispatch('deleteTask',task);
        }
      }
    },

    deleteAllOffers(){
      for(let offer of this.$store.getters.offers){
        if(offer.ProjectId === this.projectId){
          this.$store.dispatch('deleteOffer',offer);
        }
      }
    },

    deleteAllSales(){
      for(let sale of this.$store.getters.sales){
        if(sale.ProjectId === this.projectId){
          this.$store.dispatch('deleteSale',sale);
        }
      }
    },

    /**
     * Favorising the project adds the projectId into the array and sets the localStorage key.
     */
    favoriseProject(){
      let favorisedProjectIds = [];
      if (localStorage.getItem("favorisedProjects")) {
        favorisedProjectIds = JSON.parse(localStorage.getItem("favorisedProjects"));
      }
      favorisedProjectIds.push(this.project.Id);

      window.localStorage.setItem("favorisedProjects", JSON.stringify(favorisedProjectIds));
      this.$store.commit('setFavorisedProjects',[...this.favorisedProjects, this.project]);
    },

    /**
     * Unfavorising the project removes the projectId from the array and sets the localStorage key.
     */
    unfavoriseProject(){
      let favorisedProjectIds = JSON.parse(localStorage.getItem("favorisedProjects"));
      if(favorisedProjectIds){
        favorisedProjectIds = favorisedProjectIds.filter(projectId => projectId !== this.project.Id);

        const favorisedProjects = this.favorisedProjects.filter(project => project.Id !== this.project.Id);

        window.localStorage.setItem("favorisedProjects", JSON.stringify(favorisedProjectIds));
        this.$store.commit('setFavorisedProjects',favorisedProjects);
      }
    },

    /**
     * Sets the current clicked phase, so we can filter and mark for said phase.
     * First we remove any toast we might display from previous filter,
     * afterwards we add a new toast bottom-left, showing the current phase we are filtering.
     * @param phase
     */
    setClickedPhase(phase){
      this.clickedPhase = phase;
      this.$toast.removeAllGroups();
      this.$toast.add({ severity: 'info', summary: "Filter", group:'bl', detail: this.$t('swal.filteredPhase') + phase.Name, life: 3000 });
    },

    /**
     * Empties the clickedPhase and removes toast, if we clicked the same phase again.
     */
    emptyClickedPhase(){
      this.clickedPhase = null;
      this.$toast.removeAllGroups();
    },

    submitEditPhasePopup(){
      this.$refs.editPhase.emitChangePhase();
    },

    submitCloseProjectPopup(){
      this.$refs.closeProject.emitCloseProject();
    },

    changePhase(project){
      this.$store.dispatch('editProject',project).then(()=>{
        this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.projectEdited'), life: 3000 });
        this.editingPhase = false;
      });
    },

    goToTab(index){
      this.activeIndex = index;
      const scrollContainer = this.$refs.container;

      scrollContainer.scrollIntoView({
        behavior: 'smooth',
        block:'start'
      });
    },

    routeToProjects(){
      this.$router.push({
        path:"/projects"
      });
    },

    /**
     * Updates the project Correspondence.
     * @param correspondence
     */
    correspondenceEdited(correspondence){
      const projectCopy = {...this.project};
      const foundCorrespondenceIndex = projectCopy.Correspondence.findIndex(foundCorrespondence => foundCorrespondence.Id === correspondence.Id);
      projectCopy.Correspondence[foundCorrespondenceIndex] = correspondence;
      this.$store.dispatch('updateCorrespondence', projectCopy).then(() => {
        this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.correspondenceEdited'), life: 3000 });
      });
    },

    /**
     * Deletes the clicked correspondence.
     * @param correspondence
     */
    correspondenceDeleted(correspondence){
      const projectCopy = {...this.project};
      projectCopy.Correspondence = projectCopy.Correspondence.filter(foundCorrespondence => foundCorrespondence.Id !== correspondence.Id);
      this.$store.dispatch('updateCorrespondence', projectCopy).then(() => {
        this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.correspondenceDeleted'), life: 3000 });
        this.deleteFolder('sales/leadsAndProjects/' + this.projectId + '/correspondences/' + correspondence.Id + '/');
      });
    },

    setShowCards(trueOrFalse){
      this.showCards = trueOrFalse;
    },

    addReport(){
      this.getVisitReports();
      this.getPhoneReports();
    },

    removeReport(reportKey){
      this.reports = this.reports.filter(currentReport => currentReport.Key !== reportKey);
    },

    /**
     * First we try to get the project from the openProjects.
     * If we receive a resp.errorMessage and the errorMessage equals 'Item' + errorType equals KeyError,
     * we get the project from the closedProjects.
     * If the closedProject can't be found, the project has been deleted or doesn't exist.
     */
    getProjectById(){
      this.$store.dispatch("getRequest","getProjectById&query=" + this.projectId).then(resp => {
        if(resp && resp.body && resp.body === 'null'){
          this.$store.dispatch("getRequest","getClosedProjectById&query=" + this.projectId).then(resp => {
            if(resp.statusCode === 500){
              this.projectDeleted = true;
            }else{
              this.$store.commit('setClosedProject',JSON.parse(resp.body));
              this.$store.commit('setShouldReloadDashboard', false);
            }
          });
        }else if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);
          this.setClickedPhase(body.project.Phase);
          this.$store.commit('editProject',body.project);
          this.$store.commit('updateOffers',body.offers);
          this.$store.commit('addTasks',body.tasks.closedTasks);
          this.$store.commit('setShouldReloadDashboard', false);
        }
      });
    },

    /**
     * First we get all objects from the path, then we add one new key, ReportName.
     * This way we can differentiate inside our MaterialTable and highlight only the clicked entry.
     */
    getVisitReports(){
      this.getS3Objects('sales/leadsAndProjects/' + this.projectId + '/visit-reports/').then(res => {
        const visitReports = res.map(obj => ({...obj, ReportName: obj.Name,
          VisitReport:obj.Key,
          ReportTypeString:'besuchsberichtSales',
          ReportTableReportType: 'Besuchsbericht'
        }));

        // Create a copy of the existing reports
        const updatedReports = [...this.reports];

        visitReports.forEach(newOption => {
          const index = updatedReports.findIndex(report => report.VisitReport === newOption.VisitReport);
          if (index === -1) {
            updatedReports.push(newOption);
          }
        });

        this.reports = updatedReports.sort((a, b) => {
          if (a['LastModified'] > b['LastModified']) return -1;
          if (a['LastModified'] < b['LastModified']) return 1;
        });
      });
    },

    getPhoneReports() {
      this.getS3Objects('sales/leadsAndProjects/' + this.projectId + '/phone-reports/').then(res => {
        const phoneReports = res.map(obj => ({...obj, ReportName: obj.Name,
          VisitReport: obj.Key,
          ReportTypeString: 'telefonberichtSales',
          ReportTableReportType: 'Telefonbericht'
        }));

        // Create a copy of the existing reports
        const updatedReports = [...this.reports];

        phoneReports.forEach(newOption => {
          const index = updatedReports.findIndex(report => report.VisitReport === newOption.VisitReport);
          if (index === -1) {
            updatedReports.push(newOption);
          }
        });

        this.reports = updatedReports.sort((a, b) => {
          if (a['LastModified'] > b['LastModified']) return -1;
          if (a['LastModified'] < b['LastModified']) return 1;
        });
      });
    },

    getSales(){
      this.$store.dispatch("getRequest","getSalesByProject&query=" + this.projectId).then(resp => {
        if(resp && resp.statusCode === 200){
          const body = JSON.parse(resp.body);

          this.sales = body.Items;

          if (body.LastEvaluatedKey && body.Items.length > 0) {
            this.salesLastEvaluatedKey = body.LastEvaluatedKey;
          }
        }
      });
    },

    getMoreSales(){
      const id = this.salesLastEvaluatedKey.Id;
      const salesDate = this.salesLastEvaluatedKey.SalesDate;
      const projectId = this.salesLastEvaluatedKey.ProjectId;
      this.$store.commit('setLoading', true);
      try{
        this.$store.dispatch("getRequest","getMoreSalesByProject&query=" + [this.projectId, id, salesDate, projectId]).then(resp => {
          if(resp && resp.statusCode === 200){
            const body = JSON.parse(resp.body);
            this.sales = [...this.sales, ...body.Items];

            if (body.LastEvaluatedKey && body.Items.length > 0) {
              this.salesLastEvaluatedKey = body.LastEvaluatedKey;
            }
          }
        });
      }catch(err){
        console.log(err);
        this.$store.commit('setLoading', false);
      }
    },

  },
  mounted(){
    //Fired to our App.Vue so our sticky header does not have a small see through gap above it.
    this.$emit('remove-p2-class');

    this.getProjectById();

    this.getVisitReports();

    this.getPhoneReports();

    this.getSales();
  },
  watch:{
    //In case we switch routes by using the search function with the magnifying glass icon/button,
    //we need to set the new projectId and get the new project data.
    $route(to){
      if(to.params.Id){
        if(this.projectId !== to.params.Id){
          this.projectId = to.params.Id;
          this.getProjectById();
        }
      }
    },

    '$store.getters.shouldReloadDashboard': {
      handler() {
        // Fetch the data again when the flag changes
        if(this.$store.getters.shouldReloadDashboard) this.getProjectById();
      },
    },
  },
  // Needed, Don't delete
  unmounted() {
    this.emptyClickedPhase();
    this.$emit('add-p2-class');
  }
}
</script>

<style scoped>
:deep(.p-tabview .p-tabview-panels){
  padding-left:0;
  padding-right:0;
}

.result-empty{
  font-size: 24px;
  text-align: center;
  margin-top: 300px;
  margin-bottom: 300px;
}
</style>