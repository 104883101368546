<template>
  <div class="col-12">
    <div
      :style="{'height': windowHeight}"
      style="overflow-y: auto"
      class="border-round p-4">
      <div class="text-xl text-900 font-medium mb-5">
        {{this.$t('changelog')}}
      </div>
      <div v-if="changelogs.length > 0">
        <div class="flex align-items-center" style="margin-left:-15px">
        <span class="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle p-2">
          <i class="pi pi-clock" />
        </span>
        </div>
        <div class="flex mb-5" v-for="changelog of changelogs" :key="changelog">
          <div class="flex flex-column align-items-center">
            <div class="h-full bg-blue-500" style="min-height:4rem;width:2px;"></div>
          </div>
          <div class="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
            <div class="mb-3">
              <span class="text-900 font-medium inline-block mr-3">
                {{changelog.Author}}
              </span>
              <span class="text-500 text-sm">
                {{new Date(Number(changelog.Unix)).toLocaleDateString()}} {{new Date(Number(changelog.Unix)).toLocaleTimeString()}}
              </span>
            </div>
            <span class="line-height-3 text-700 mb-3">
              {{changelog.Text}}
            </span>
          </div>
        </div>
      </div>
      <div v-else class="ml-4">
        <span class="font-bold">
          {{this.$t('noChanges')}}
        </span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "ChangeLog",
  data(){
    return{
      windowHeight:'',
    }
  },
  props:{
    changelogs:Array,
  },

  created(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>