<template>
  <SmallCard
    :header="this.$t('sales')"
    :use-slot="true"
    :show-button="projectOpen"
    :button-label="this.$t('createNewSale')"
    :button-icon="'pi pi-plus'"
    @button-clicked="createSale">
    <TabView>
      <TabPanel :header="$t('active') + ' (' + openSales.length + ')'">
        <MaterialTable 
          :data-key="'Id'"
          :table-id="'sales'"
          :style="{'height': windowHeight}"
          :data-prop="openSales"
          :columns="columns"
          :has-paginator="true"
          :rows-count="10"
          :has-global-filter="true"
          :filter-prop="filter"
          :show-delete-button="projectOpen"
          @row-deleted="deleteSale"
          :filter-options="filterOptions"
          :sortable="true"
          :has-removable-sort="true"
          :key="tableKey"
          :selection-mode="'single'"
          :show-toggle-column="true"
          :resizable-columns="true"
          @row-selected="editSale"
          :show-cancel-button="projectOpen"
          :close-button-tool-tip="$t('swal.cancelSaleHeader')"
          @close-data="cancelSale"
          :show-load-more-data="showLoadMoreSales"
          @load-more-data="loadMoreData"
          :delete-button-tool-tip="$t('swal.deleteSaleHeader')"/>
      </TabPanel>
      <TabPanel :header="$t('canceled') + ' (' + canceledSales.length + ')'">
        <MaterialTable 
          :data-key="'Id'"
          :table-id="'canceledSales'"
          :style="{'height': windowHeight}"
          :data-prop="canceledSales"
          :columns="columns"
          :has-paginator="true"
          :rows-count="10"
          :has-global-filter="true"
          :filter-prop="filter"
          :show-delete-button="projectOpen"
          @row-deleted="deleteSale"
          :filter-options="filterOptions"
          :sortable="true"
          :has-removable-sort="true"
          :key="tableKey"
          :selection-mode="'single'"
          :show-toggle-column="true"
          :resizable-columns="true"
          @row-selected="editSale"
          :show-load-more-data="showLoadMoreSales"
          @load-more-data="loadMoreData"
          :delete-button-tool-tip="$t('swal.deleteSaleHeader')"/>
      </TabPanel>
    </TabView>
  </SmallCard>

  <Popup :header="this.$t('sales')"
         :modal="true"
         :visible="openAddSale"
         styling="width:50%"
         :position="'top'"
         :show-save-button="true"
         @button-clicked="submitAddSalePopup"
         @close-popup="openAddSale = false">
    <AddSale ref="addSale"
             :project="project"
             @add-sale="addSale"/>
  </Popup>

  <Popup :header="this.$t('sales')"
         :modal="true"
         :visible="openEditSale"
         styling="width:50%"
         :position="'top'"
         :show-save-button="projectOpen"
         @button-clicked="submitEditSalePopup"
         @close-popup="openEditSale = false"
         :show-menu-button="true"
         :menu-options="editSaleMenuOptions">
  <EditSale ref="editSale"
            :members="project.Members"
            :project-id="project.Id"
            :companyId="project.Company && project.Company.Id"
            :sales-data-prop="selectedSale"
            :disabled="selectedSale.Status !== '10' || !projectOpen"
            @edit-sale="emitEditSale"/>
  </Popup>

</template>

<script>
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import SmallCard from "@/components/global-components/card/SmallCard";
import Popup from "@/components/global-components/popup/Popup";
import AddSale from "@/components/sales/add-sale/AddSale";
import {FilterMatchMode} from "primevue/api";
import EditSale from "@/components/sales/edit-sale/EditSale";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
export default {
  name: "SalesInformation",
  components:{EditSale, MaterialTable, SmallCard, Popup, AddSale, TabView, TabPanel,},
  mixins:[globalComputedProperties],
  emits:['loadMoreData', 'addSale', 'editSale', 'deleteSale'],
  props:{
    project:Object,
    projectOpen:Boolean,
    sales:Array,
    showLoadMoreSales:Object,
  },

  data(){
    return {
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'CommissionNumber', header: this.$t('commissionNumber') },
        { field: 'CommissionValue', header: this.$t('commissionValue') },
        { field: 'Company.Name', header: this.$t('company') },
        { field: 'Contact.Name', header: this.$t('contact') },
        { field: 'Responsible.Name', header: this.$t('responsible') },
        { field: 'OrderNumber', header: this.$t('orderNumber') },
        { field: 'OrderValue', header: this.$t('orderValue') },
        { field: 'ReferenceNumber', header: this.$t('referenceNumber') },
        { field: 'SalesDate', header: this.$t('salesDate') },
        { field: 'DeliveryDate', header: this.$t('deliveryDate') },
        { field: 'OfferValue', header: this.$t('offerValue') },
        { field: 'OfferName', header: this.$t('offer') },
        { field: 'Supplier', header: this.$t('supplier') },
        { field: 'Equipment', header: this.$t('equipments') },
        { field: 'CompetitionModel', header: this.$t('competitionModel') },
        { field: 'MachineModel', header: this.$t('machineModel') },
        { field: 'MachineNumber', header: this.$t('machineNumber') },
        { field: 'Discount', header: this.$t('discountPercentage') },
        { field: 'Notes', header: this.$t('notes') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CommissionNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CommissionValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Company.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Contact.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'Responsible.Name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        OrderNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OrderValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ReferenceNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        SalesDate: { value: null, matchMode: FilterMatchMode.IN },
        DeliveryDate: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferValue: { value: null, matchMode: FilterMatchMode.CONTAINS },
        OfferName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Supplier: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Equipment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        CompetitionModel: { value: null, matchMode: FilterMatchMode.CONTAINS },
        MaschineModel: { value: null, matchMode: FilterMatchMode.CONTAINS },
        MaschineNumber: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Discount: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Notes: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Title', 'CommissionNumber', 'CommissionValue', 'Company.Name', 'Contact.Name', 'Responsible.Name', 'OrderNumber', 'OrderValue',
       'ReferenceNumber', 'OfferValue', 'OfferName', 'Supplier', 'CompetitionModel', 'MaschineModel', 'MachineNumber', 'Discount', 'Notes'],
      openAddSale:false,
      openEditSale:false,
      selectedSale:null,
      tableKey:0,
      editSaleMenuOptions:[],
      windowHeight: '',
    }
  },

  computed:{
    openSales(){
      return this.sales.filter((sale) => sale.Status === '10');
    },

    canceledSales(){
      return this.sales.filter((sale) => sale.Status === '20');
    }
  },

  methods:{
    createSale(){
      this.openAddSale = true;
    },

    closePopup(){
      this.openAddSale = false;
      this.openEditSale = false;
      this.selectedSale = null;
    },

    addSale(sale){
      this.$emit('add-sale', sale);
      this.closePopup();
    },

    emitEditSale(sale){
      this.$emit('edit-sale', sale);
      this.closePopup();
    },

    editSale(sale){
      this.createEditSaleMenuOptions(sale);
      this.selectedSale = sale;
      this.openEditSale = true;
    },

    createEditSaleMenuOptions(sale){
      const optionsMenu = []
      if(sale.Status === '20'){
        optionsMenu.push({
          label: this.$t('swal.reopenSaleHeader'), icon: 'pi pi-fw ',
          command: () => { this.reopenSale(sale)}
        },
        {seperator: true},
      )
      }
      if(this.$store.getters.userType.toLowerCase() === "admin" && this.projectOpen){
        optionsMenu.push(
            { label: this.$t('swal.deleteSaleHeader'), icon: 'pi pi-fw pi-trash',
              command: () => { this.deleteSale(sale);}
            },
            { separator : true},
        )
      }
      optionsMenu.push(
          { label: this.$t('createPDF'), icon: 'pi pi-fw pi-sign-in',
            command: () => {this.createPDF();}
          },
      )
      if(sale.Payments && sale.Payments.length > 0){
        optionsMenu.push(
            { separator : true},
            { label: this.$t('showPayments'), icon: 'pi pi-fw pi-info-circle',
              command: () => {this.showPayments();}
            },
        )
      }

      this.editSaleMenuOptions = optionsMenu;
    },

    cancelSale(sale){
      console.log(sale)
      this.$confirm.require({
        header: this.$t('swal.cancelSaleHeader'),
        message: this.$t('swal.cancelSaleText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.saleCanceled'), life: 3000 });
          sale.Status = '20';
          
          this.$store.dispatch('editSale',sale).then(() => {
            this.emitEditSale(sale);
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    deleteSale(sale){
      this.$confirm.require({
        header: this.$t('swal.deleteSaleHeader'),
        message: this.$t('swal.deleteSaleText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.saleDeleted'), life: 3000 });
          
          this.$store.dispatch('deleteSale',sale).then(() => {
            this.emitDeleteSale(sale);
            this.closePopup();
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    reopenSale(sale){
      this.$confirm.require({
        header: this.$t('swal.reopenSaleHeader'),
        message: this.$t('swal.reopenSaleText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.saleReopened'), life: 3000 });

          sale.Status = '10';
          
          this.$store.dispatch('editSale',sale).then(() => {
            this.emitEditSale(sale);
            this.closePopup();
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    emitDeleteSale(sale){
      this.$emit('delete-sale', sale);
    },

    submitAddSalePopup(){
      this.$refs.addSale.addSale();
    },

    submitEditSalePopup(){
      this.$refs.editSale.editSale();
    },

    createPDF(){
      this.$refs.editSale.createPDF();
    },

    showPayments(){
      this.$refs.editSale.showPayments();
    },

    loadMoreData(){
      this.$emit('load-more-data','sale');
    }
  },
     
  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>