<template>
  <div>
    <span class="cursor-pointer text-sm" @click="this.changeLanguage('de')" :style="{'font-weight': this.$i18n.locale === 'de' ? 'bold' : ''}">DE</span>
    <span style="position:relative;"> - </span>
    <span class="cursor-pointer text-sm" @click="this.changeLanguage('en')" :style="{'font-weight': this.$i18n.locale === 'en' ? 'bold' : ''}">EN</span>
  </div>
</template>

<script>
export default {
  name: "SelectLanguage",
  methods:{
    changeLanguage(language){
      this.$i18n.locale = language;
      language === "en"? localStorage.setItem("lang", "en") :localStorage.setItem("lang", "de");
    }
  }
}
</script>

<style scoped>

</style>