<template>
  <div>
    <div v-if="dataType === 'Lead'" style="overflow-y:auto">
      <span class="text-900 font-medium text-3xl">{{$t('lead')}}</span>
      <EditLead :lead-data-prop="selectedData" :disabled="true" />
    </div>

    <div v-else-if="dataType === 'Task'" style="overflow-y:auto">
      <span class="text-900 font-medium text-3xl">{{$t('task')}}</span>
      <EditTask :project-id="selectedData.ProjectId"
                :task-prop="selectedData"
                :project-open="false"
                :disabled="true"
                :members="responsibles" />
    </div>

    <div v-else-if="dataType === 'Offer'" style="overflow-y:auto">
      <span class="text-900 font-medium text-3xl">{{$t('offer')}}</span>
      <EditOffer :project-id="selectedData.ProjectId"
                 :project="selectedData"
                 :members="selectedData.Members"
                 :offer-prop="selectedData"
                 :project-open="false"
                 :disabled="true"/>
    </div>

    <div v-else-if="dataType === 'Sale'" style="overflow-y:auto">
      <span class="text-900 font-medium text-3xl">{{$t('sale')}}</span>
      <EditSale :show-payments-icon="true"
                :members="selectedData.Members"
                :project-id="selectedData.ProjectId"
                :sales-data-prop="selectedData"
                :companyId="selectedData.Company && selectedData.Company.Id"
                :disabled="true"/>
    </div>

    <div v-else-if="dataType === 'Correspondence'" style="overflow-y:auto">
      <span class="text-900 font-medium text-3xl">{{$t('correspondence')}}</span>
      <ViewCorrespondence :correspondence-type="correspondenceType"
                          :company-id="companyId"
                          :user-id="userId"
                          :contact-id="contactId"
                          :correspondence-prop="selectedData" />
    </div>

    <div v-else style="overflow-y:auto">
      <span class="text-900 font-medium text-2xl">{{$t('selectSomething')}}</span>
    </div>

  </div>

</template>

<script>
import EditLead from "@/components/leads/edit-lead/EditLead";
import EditTask from "@/components/tasks/edit-task/EditTask";
import EditOffer from "@/components/projects/dashboard/offers/edit-offer/EditOffer";
import EditSale from "@/components/sales/edit-sale/EditSale";
import ViewCorrespondence from "@/components/correspondence/view-dashboard-correspondence/ViewCorrespondence";
export default {
  name: "DashboardViewer",
  components: {ViewCorrespondence, EditSale, EditOffer, EditTask, EditLead},
  props:{
    selectedData:Object,
    dataType:String,
    companyId:String,
    userId:String,
    contactId:String,
    correspondenceType:String,
  },
  computed:{
    responsibles(){
      return this.$store.getters.responsibles.map(responsible => ({Name:responsible.FriendlyName,Id:responsible.User, type: "User"}));
    }
  },
}
</script>

<style scoped>

</style>